import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from '../../network'
import { toScaled, toUnscaled } from '../../../utils/bn'
import { useAddresses } from '../../useAddress'
import {
  PerpetualMarketCore__factory,
  PerpetualMarket__factory
} from '../../../typechain'
import { useCurrentEthPrice } from '../../usePrice'
import { Product } from '../../../constants/enum'
import { getTradePrice } from '../../../wrapper'

export type PerpetualMarketCoreStatus = {
  status: PoolStatus[]
  totalValue: number
  utilizationRatio: number
}

export type PoolStatus = {
  amountLockedLiquidity: number
  positionPerpetuals: number
  entryPrice: number
  unrealizedPnL: number
  amountFundingPaidPerPosition: number
  lastTradeTime: number
}

export function usePoolStatus(assetIndex: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const total = useTotalLiquidity(assetIndex)
  const nettingInfo = useNettingInfo(assetIndex)

  return useQuery(
    ['pool_status', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')
      if (!total.isSuccess) throw new Error('total not loaded')
      if (!nettingInfo.isSuccess) throw new Error('nettingInfo not loaded')

      const perpetualMarket = PerpetualMarket__factory.connect(
        addresses.Perpetuals[assetIndex].PerpetualMarket,
        library
      )

      const contract = PerpetualMarketCore__factory.connect(
        addresses.Perpetuals[assetIndex].LPToken,
        library
      )

      const result = await Promise.all(
        [Product.FUTURE, Product.SQUARED].map(id => contract.pools(id))
      )
      const poolPositions = result.map(r => r.positionPerpetuals)

      const utilizationRatio = await contract.getUtilizationRatio()

      const status = await Promise.all(
        result.map(async (r, i) => {
          const tradePrice = await getTradePrice(
            perpetualMarket,
            i,
            poolPositions
          )
          const entryPrice = toUnscaled(r.entryPrice, 8)
          const position = toUnscaled(r.positionPerpetuals, 8)

          return {
            amountLockedLiquidity: toUnscaled(r.amountLockedLiquidity, 8),
            positionPerpetuals: position,
            entryPrice,
            unrealizedPnL: (tradePrice.tradePrice - entryPrice) * position,
            amountFundingPaidPerPosition: toUnscaled(
              r.amountFundingPaidPerPosition,
              16
            ),
            lastTradeTime: r.lastFundingPaymentTime.toNumber()
          }
        })
      )

      const poolStatus: PerpetualMarketCoreStatus = {
        status,
        totalValue:
          total.data -
          status[0].amountLockedLiquidity -
          status[1].amountLockedLiquidity +
          nettingInfo.data.totalHedgePositionValue +
          status[0].unrealizedPnL +
          status[1].unrealizedPnL,
        utilizationRatio: toUnscaled(utilizationRatio, 8)
      }

      return poolStatus
    },
    {
      enabled:
        supportedChain &&
        !!library &&
        !!addresses &&
        total.isSuccess &&
        nettingInfo.isSuccess
    }
  )
}

export function useTotalLiquidity(assetIndex: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()

  return useQuery(
    ['pool_total_liquidity', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = PerpetualMarketCore__factory.connect(
        addresses.Perpetuals[assetIndex].LPToken,
        library
      )

      const result = await contract.amountLiquidity()

      return toUnscaled(result, 8)
    },
    {
      enabled: !!library && !!addresses
    }
  )
}

export function useVaultLimit(assetIndex: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()

  return useQuery(
    ['vault_limit', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = PerpetualMarket__factory.connect(
        addresses.Perpetuals[assetIndex].PerpetualMarket,
        library
      )

      const vaultLimit0 = await contract.maxPositionsInVault(0)
      const vaultLimit1 = await contract.maxPositionsInVault(1)

      return [vaultLimit0, vaultLimit1].map(limit => toUnscaled(limit, 8))
    },
    {
      enabled: !!library && !!addresses
    }
  )
}

export function useLPTokenPrice(assetIndex: number, deltaLiquidity: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()

  return useQuery(
    ['lp_token_price', chainId, assetIndex, deltaLiquidity],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = PerpetualMarket__factory.connect(
        addresses.Perpetuals[assetIndex].PerpetualMarket,
        library
      )

      const lpTokenPrice = await contract.getLPTokenPrice(
        toScaled(deltaLiquidity, 6)
      )

      const lpTokenAmount = toUnscaled(
        toScaled(Math.abs(deltaLiquidity), 8)
          .mul('10000000000000000')
          .div(lpTokenPrice),
        8
      )

      return {
        lpTokenPrice,
        lpTokenAmount
      }
    },
    {
      enabled: !!library && !!addresses
    }
  )
}

export function useNettingInfo(assetIndex: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const ethPrice = useCurrentEthPrice()

  return useQuery(
    ['netting_info', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')
      if (!ethPrice.isSuccess) throw new Error('eth price not loaded')

      const contract = PerpetualMarketCore__factory.connect(
        addresses.Perpetuals[assetIndex].LPToken,
        library
      )

      const nettingInfo = await contract.getNettingInfo()

      const unscaledNettingInfo = {
        amountsUsdc: nettingInfo.amountsUsdc.map(amount =>
          toUnscaled(amount, 8)
        ),
        amountUnderlying: toUnscaled(nettingInfo.amountUnderlying, 8)
      }

      const totalHedgePositionValue =
        ethPrice.data * unscaledNettingInfo.amountUnderlying +
        unscaledNettingInfo.amountsUsdc[0] +
        unscaledNettingInfo.amountsUsdc[1]

      return {
        ...unscaledNettingInfo,
        totalHedgePositionValue
      }
    },
    {
      enabled: supportedChain && !!library && !!addresses && ethPrice.isSuccess
    }
  )
}
