/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import { Provider } from '@ethersproject/providers'
import type {
  IPerpetualMarketCore,
  IPerpetualMarketCoreInterface
} from '../IPerpetualMarketCore'

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      }
    ],
    name: 'addLiquidity',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'amountUsdc',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'amountUnderlying',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'futureWeight',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'isLong',
            type: 'bool'
          }
        ],
        internalType: 'struct NettingLib.CompleteParams',
        name: '_completeParams',
        type: 'tuple'
      }
    ],
    name: 'completeHedgingProcedure',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_depositor',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_depositAmount',
        type: 'uint256'
      }
    ],
    name: 'deposit',
    outputs: [
      {
        internalType: 'uint256',
        name: 'mintAmount',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'executeFundingPayment',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'int256',
        name: '_deltaLiquidityAmount',
        type: 'int256'
      }
    ],
    name: 'getLPTokenPrice',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getTokenAmountForHedging',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'amountUsdc',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'amountUnderlying',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'futureWeight',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'isLong',
            type: 'bool'
          }
        ],
        internalType: 'struct NettingLib.CompleteParams',
        name: 'completeParams',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_productId',
        type: 'uint256'
      },
      {
        internalType: 'int128',
        name: '_tradeAmount',
        type: 'int128'
      }
    ],
    name: 'getTradePrice',
    outputs: [
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      },
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      },
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      },
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      },
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'int128[2]',
        name: 'amountAssets',
        type: 'int128[2]'
      }
    ],
    name: 'getTradePriceInfo',
    outputs: [
      {
        components: [
          {
            internalType: 'uint128',
            name: 'spotPrice',
            type: 'uint128'
          },
          {
            internalType: 'int256[2]',
            name: 'tradePrices',
            type: 'int256[2]'
          },
          {
            internalType: 'int256[2]',
            name: 'fundingRates',
            type: 'int256[2]'
          },
          {
            internalType: 'int256[2]',
            name: 'amountsFundingPaidPerPosition',
            type: 'int256[2]'
          }
        ],
        internalType: 'struct IPerpetualMarketCore.TradePriceInfo',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_depositor',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_depositAmount',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: '_initialFundingRate',
        type: 'int256'
      }
    ],
    name: 'initialize',
    outputs: [
      {
        internalType: 'uint256',
        name: 'mintAmount',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'rebalance',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_productId',
        type: 'uint256'
      },
      {
        internalType: 'int128',
        name: '_tradeAmount',
        type: 'int128'
      }
    ],
    name: 'updatePoolPosition',
    outputs: [
      {
        internalType: 'uint256',
        name: 'tradePrice',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      },
      {
        internalType: 'uint256',
        name: 'protocolFee',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'updatePoolSnapshot',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_withdrawer',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_withdrawnAmount',
        type: 'uint256'
      }
    ],
    name: 'withdraw',
    outputs: [
      {
        internalType: 'uint256',
        name: 'burnAmount',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export class IPerpetualMarketCore__factory {
  static readonly abi = _abi
  static createInterface(): IPerpetualMarketCoreInterface {
    return new utils.Interface(_abi) as IPerpetualMarketCoreInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IPerpetualMarketCore {
    return new Contract(address, _abi, signerOrProvider) as IPerpetualMarketCore
  }
}
