import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from './network'
import { useAddresses } from './useAddress'
import { useQuery } from 'react-query'
import { Multicall2__factory } from '../typechain'

export function useBlockNumber() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const [blockNumber, setBlockNumber] = useState(0)

  useEffect(() => {
    function update(bn: number) {
      setBlockNumber(bn)
    }

    if (library) {
      library.on('block', update)

      return () => {
        library.off('block', update)
      }
    }
  })

  return blockNumber
}

export function useL1BlockNumber() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const blockNumber = useBlockNumber()

  const [l1BlockNumber, setL1BlockNumber] = useState(0)

  const l1BlockNumberQuery = useQuery(
    ['l1_block_number', chainId, blockNumber],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = Multicall2__factory.connect(
        addresses.Multicall2,
        library
      )
      return await contract.getL1BlockNumber()
    },
    {
      enabled: !!account && supportedChain && !!addresses
    }
  )

  useEffect(() => {
    if (l1BlockNumberQuery.isSuccess) {
      setL1BlockNumber(l1BlockNumberQuery.data.toNumber())
    }
  }, [l1BlockNumberQuery.isSuccess, l1BlockNumberQuery.data])

  return l1BlockNumber
}
