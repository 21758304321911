import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from '../../network'
import { Action, ChartRange, Product } from '../../../constants/enum'
import { HistoryItem, useVaultHistory } from '../useVaultHistory'
import { getAssetIndex } from '../../../utils/asset'
import { IndexPriceChartInfo, useIndexPrices } from './useIndexPrices'
import { FundingPayment, useFundingPayment } from '../useFundingPayment'
import { usePoolStatus } from '../pool/usePoolStatus'
import {
  calculatePositionValue,
  updateEntryPrice
} from '../../../utils/helpers/vaultHelpers'
import { getHighAndLow } from '../../../utils/helpers/chartHelper'
import { toTimeString } from '../../../utils/string'
import { toFixedNumber } from '../../../utils/number'
import { useEffect, useState } from 'react'
import { TRADE_FEE_1, TRADE_FEE_2 } from '../../../constants'

type PositionValue = {
  id: string
  value: number
  timestamp: number
  time: string
}

export type HistoricalPnLChartData = {
  values: PositionValue[]
  domainLow: number
  domainHigh: number
}

function reproduceFunding(
  timestamp: number,
  fundingFeePerPosition: number,
  fundingPayments: FundingPayment[]
) {
  // add latest funding info
  fundingPayments = [
    {
      productId: fundingPayments[0].productId,
      fundingRate: fundingPayments[0].fundingRate,
      impliedVolatility: fundingPayments[0].impliedVolatility,
      totalFundingPaidPerPosition: fundingFeePerPosition,
      fundingPaidPerPosition:
        fundingFeePerPosition - fundingPayments[0].fundingPaidPerPosition,
      poolReceived: 0,
      timestamp
    }
  ].concat(fundingPayments)

  const payments = fundingPayments.filter(
    fundingPayment => fundingPayment.timestamp <= timestamp
  )

  if (payments.length > 0) {
    return {
      fundingRate: payments[0].fundingRate,
      fundingFeePerPosition: payments[0].totalFundingPaidPerPosition
    }
  }

  return {
    fundingRate: 0,
    fundingFeePerPosition: 0
  }
}

/**
 * Reproduce vault position value at the time specified by timestamp
 * @param timestamp
 * @param history
 * @param subVaultIndex
 * @returns
 */
function reproduceVault(
  timestamp: number,
  history: HistoryItem[],
  subVaultIndex: number
) {
  const vaultStatus = {
    marginAmount: 0,
    positionPerpetuals: [0, 0],
    entryPrices: [0, 0],
    entryFundingFee: [0, 0]
  }

  history
    .filter(
      item =>
        item.timestamp <= timestamp &&
        (subVaultIndex < 0 || subVaultIndex === item.subVaultIndex)
    )
    .forEach(item => {
      if (item.action === Action.DEPOSIT) {
        vaultStatus.marginAmount += item.marginAmount
      } else if (item.action === Action.WITHDRAW) {
        vaultStatus.marginAmount -= item.marginAmount
      } else if (item.action === Action.TRADE) {
        {
          const result = updateEntryPrice(
            vaultStatus.entryPrices[item.productId],
            vaultStatus.positionPerpetuals[item.productId],
            item.tradePrice,
            item.tradeAmount
          )
          vaultStatus.entryPrices[item.productId] = result.newEntryPrice
          vaultStatus.marginAmount += result.profitValue
        }
        {
          const result = updateEntryPrice(
            vaultStatus.entryFundingFee[item.productId],
            vaultStatus.positionPerpetuals[item.productId],
            item.fundingFeePerPosition,
            item.tradeAmount
          )
          vaultStatus.entryFundingFee[item.productId] = result.newEntryPrice
          vaultStatus.marginAmount -= result.profitValue
        }

        vaultStatus.positionPerpetuals[item.productId] += item.tradeAmount
      } else if (item.action === Action.LIQUIDATE) {
        vaultStatus.marginAmount -= item.marginAmount
      }
    })

  return vaultStatus
}

function calculatePnLData(
  indexPrices: IndexPriceChartInfo,
  history: HistoryItem[],
  subVaultIndex: number,
  futureFundingPayments: FundingPayment[],
  squaredFundingPayments: FundingPayment[],
  latestFutureFundingFeePerPosition: number,
  latestSquaredFundingFeePerPosition: number
): PositionValue[] {
  let isClosed = false
  return indexPrices.prices
    .sort((a, b) => b.timestamp - a.timestamp)
    .map(price => {
      const timestamp = price.timestamp
      const vault = reproduceVault(timestamp, history, subVaultIndex)

      if (
        subVaultIndex >= 0 &&
        vault.positionPerpetuals[0] === 0 &&
        vault.positionPerpetuals[1] === 0
      ) {
        isClosed = true
      }

      if (isClosed) {
        return {
          id: String(timestamp),
          timestamp: timestamp,
          time: toTimeString(timestamp),
          value: toFixedNumber(0)
        }
      }

      const futureFundingInfo = reproduceFunding(
        timestamp,
        latestFutureFundingFeePerPosition,
        futureFundingPayments
      )
      const squaredFundingInfo = reproduceFunding(
        timestamp,
        latestSquaredFundingFeePerPosition,
        squaredFundingPayments
      )

      const futureIndexPrice = price.price
      const squaredIndexPrice = (price.price * price.price) / 10000

      const futureTradeFee =
        (vault.positionPerpetuals[0] > 0 ? -TRADE_FEE_1 : TRADE_FEE_1) *
        futureIndexPrice
      const squaredTradeFee =
        (vault.positionPerpetuals[1] > 0 ? -TRADE_FEE_2 : TRADE_FEE_2) *
        squaredIndexPrice
      const futureTradePrice =
        futureIndexPrice * (1 + futureFundingInfo.fundingRate) + futureTradeFee
      const squaredTradePrice =
        squaredIndexPrice * (1 + squaredFundingInfo.fundingRate) +
        squaredTradeFee

      const positionValue = calculatePositionValue(
        vault.positionPerpetuals,
        vault.entryPrices,
        futureTradePrice,
        squaredTradePrice,
        vault.entryFundingFee,
        futureFundingInfo.fundingFeePerPosition,
        squaredFundingInfo.fundingFeePerPosition,
        // if the mode is subVault mode, ignore margin change
        subVaultIndex >= 0 ? 0 : vault.marginAmount
      )
      return {
        id: String(timestamp),
        timestamp: timestamp,
        time: toTimeString(timestamp),
        value: toFixedNumber(positionValue)
      }
    })
    .sort((a, b) => a.timestamp - b.timestamp)
}

function getOffset(range: ChartRange) {
  switch (range) {
    case ChartRange.Hour:
      return 5
    case ChartRange.Day:
      return 50
    default:
      return 100
  }
}

export function useHistoricalPnL(
  asset: string,
  vaultId: number,
  subVaultIndex: number,
  range: ChartRange,
  limit: number
) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const assetIndex = getAssetIndex(asset)
  const futureFundingPayment = useFundingPayment(Product.FUTURE, limit)
  const squaredFundingPayment = useFundingPayment(Product.SQUARED, limit)
  const history = useVaultHistory(assetIndex, vaultId)
  const indexPrices = useIndexPrices(asset, Product.FUTURE, range, limit)
  const poolStatus = usePoolStatus(assetIndex)

  const [chartData, setChartData] = useState<HistoricalPnLChartData | null>(
    null
  )

  const historicalPnL = useQuery(
    ['historical_pnl', chainId, range, vaultId, subVaultIndex, history.length],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!indexPrices.data) throw new Error('indexPrices is not loaded')
      if (!history) throw new Error('history is not loaded')
      if (!futureFundingPayment.isSuccess)
        throw new Error('futureFundingPayment is not loaded')
      if (!squaredFundingPayment.isSuccess)
        throw new Error('squaredFundingPayment is not loaded')
      if (!poolStatus.isSuccess) throw new Error('poolStatus is not loaded')

      const pnlData = calculatePnLData(
        indexPrices.data,
        history.reverse(),
        subVaultIndex,
        futureFundingPayment.data,
        squaredFundingPayment.data,
        poolStatus.data.status[Product.FUTURE].amountFundingPaidPerPosition,
        poolStatus.data.status[Product.SQUARED].amountFundingPaidPerPosition
      )
      const highAndLow = getHighAndLow(
        pnlData.map(pnLDataItem => pnLDataItem.value),
        getOffset(range)
      )

      return {
        values: pnlData,
        domainHigh: highAndLow.domainHigh,
        domainLow:
          subVaultIndex === -1
            ? highAndLow.domainLow >= 0
              ? highAndLow.domainLow
              : 0
            : highAndLow.domainLow
      }
    },
    {
      enabled:
        supportedChain &&
        !!library &&
        indexPrices.data?.range === range &&
        !indexPrices.isLoading &&
        !!history &&
        futureFundingPayment.isSuccess &&
        squaredFundingPayment.isSuccess &&
        poolStatus.isSuccess
    }
  )

  useEffect(() => {
    if (historicalPnL.isSuccess) {
      setChartData(historicalPnL.data)
    }
  }, [historicalPnL.isSuccess, historicalPnL.data])

  return {
    isLoading: chartData === null,
    data: chartData
  } as
    | { isLoading: true; data: null }
    | { isLoading: false; data: HistoricalPnLChartData }
}
