import React from 'react'
import { useRoute } from 'wouter'
import LitePositionTradeResult from '../components/trade/LitePositionTradeResult'
import { VaultInfoLite } from '../components/vault/VaultInfo'
import { getAssetIndex } from '../utils/asset'
import HistoricalPnLChart from '../components/chart/HistoricalPnLChart'
import VaultHistoryLite from '../components/vault/VaultHistoryLite'
import vaultStore from '../store/vault'

const LiteTradeView = () => {
  const params = useRoute('/home/:asset')[1]
  const { vaultId } = vaultStore()
  const assetIndex = getAssetIndex(params?.asset)

  return (
    <div className="grid grid-cols-lite-sm md:grid-cols-lite grid-rows-lite gap-4 max-w-5xl mx-auto">
      <div className="col-span-1 row-span-1">
        <HistoricalPnLChart
          asset={params?.asset || 'eth'}
          vaultId={vaultId}
        ></HistoricalPnLChart>
      </div>
      <div className="col-span-1 row-span-3">
        <LitePositionTradeResult
          asset={assetIndex}
          vaultId={vaultId}
        ></LitePositionTradeResult>
      </div>
      <div className="col-span-1 row-span-1">
        <VaultInfoLite asset={assetIndex} vaultId={vaultId} />
      </div>
      <div className="col-span-1 row-span-1">
        <VaultHistoryLite asset={assetIndex} vaultId={vaultId} />
      </div>
    </div>
  )
}

export default LiteTradeView
