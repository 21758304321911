import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  HttpLink
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'

export const rinkebyArbitrum = {
  Predy: {
    http: new HttpLink({
      uri: 'https://api.thegraph.com/subgraphs/name/predy-dev/predy-v202-arbitrum-rinkeby'
    }),
    ws: new WebSocketLink({
      uri: 'wss://api.thegraph.com/subgraphs/name/predy-dev/predy-v202-arbitrum-rinkeby',
      options: {
        reconnect: true
      }
    })
  },
  Chainlink: {
    http: new HttpLink({
      uri: 'https://api.thegraph.com/subgraphs/name/predy-dev/chainlink-arbitrum-rinkeby'
    }),
    ws: new WebSocketLink({
      uri: 'wss://api.thegraph.com/subgraphs/name/predy-dev/chainlink-arbitrum-rinkeby',
      options: {
        reconnect: true
      }
    })
  },
  Uniswap: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3'
}

export const arbitrum = {
  Predy: {
    http: new HttpLink({
      uri: 'https://api.thegraph.com/subgraphs/name/predy-dev/predy-v202-arbitrum'
    }),
    ws: new WebSocketLink({
      uri: 'wss://api.thegraph.com/subgraphs/name/predy-dev/predy-v202-arbitrum',
      options: {
        reconnect: true
      }
    })
  },
  Chainlink: {
    http: new HttpLink({
      uri: 'https://api.thegraph.com/subgraphs/name/predy-dev/chainlink-arbitrum'
    }),
    ws: new WebSocketLink({
      uri: 'wss://api.thegraph.com/subgraphs/name/predy-dev/chainlink-arbitrum',
      options: {
        reconnect: true
      }
    })
  },
  Uniswap: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3'
}

const splitLink = (wsLink: any, httpLink: any) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )
}

const predyArbitrumRinkeby = new ApolloClient({
  link: splitLink(rinkebyArbitrum.Predy.ws, rinkebyArbitrum.Predy.http),
  cache: new InMemoryCache()
})

const predyArbitrum = new ApolloClient({
  link: splitLink(arbitrum.Predy.ws, arbitrum.Predy.http),
  cache: new InMemoryCache()
})

const chainlinkArbitrumRinkeby = new ApolloClient({
  link: splitLink(rinkebyArbitrum.Chainlink.ws, rinkebyArbitrum.Chainlink.http),
  cache: new InMemoryCache()
})

const chainlinkArbitrum = new ApolloClient({
  link: splitLink(arbitrum.Chainlink.ws, arbitrum.Chainlink.http),
  cache: new InMemoryCache()
})

const uniswapArbitrumRinkeby = new ApolloClient({
  uri: rinkebyArbitrum.Uniswap,
  cache: new InMemoryCache()
})

const uniswapArbitrum = new ApolloClient({
  uri: arbitrum.Uniswap,
  cache: new InMemoryCache()
})

export const PredyClient: {
  [key: number]: ApolloClient<NormalizedCacheObject>
} = {
  42161: predyArbitrum,
  421611: predyArbitrumRinkeby
}

export const ChainlinkClient: {
  [key: number]: ApolloClient<NormalizedCacheObject>
} = {
  42161: chainlinkArbitrum,
  421611: chainlinkArbitrumRinkeby
}

export const UniswapClient: {
  [key: number]: ApolloClient<NormalizedCacheObject>
} = {
  42161: uniswapArbitrum,
  421611: uniswapArbitrumRinkeby
}
