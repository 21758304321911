import React from 'react'
import { useRoute } from 'wouter'
import { useRanking } from '../hooks/query/ranking/ranking'
import { toPriceString } from '../utils/number'

const RankingView = () => {
  const params = useRoute('/ranking/:asset/:competition')[1]
  const ranking = useRanking(Number(params ? params.competition : 0))

  return (
    <div className="container mx-auto rounded-lg bg-secondaly p-6">
      <div className="my-6">
        <div className="flex items-center justify-center">
          <div>
            The trade competition from {ranking.start.toISOString()} to{' '}
            {ranking.end.toISOString()}
          </div>
        </div>
      </div>
      <div className="my-6 text-left">
        <div className="flex items-center justify-center">
          <table>
            <thead>
              <th className="w-36 py-2 px-2"></th>
              <th className="w-36 py-2 px-2">Address</th>
              <th className="w-36 py-2 px-2">PnL</th>
            </thead>
            <tbody>
              {ranking.data.map((item, i) => {
                return (
                  <tr key={i} className="border-b border-third">
                    <td className="w-36 py-2 px-2">{i + 1}</td>
                    <td className="w-36 py-2 px-2">{item[0]}</td>
                    <td className="w-36 py-2 px-2">
                      ${toPriceString(item[1])}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default RankingView
