import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { useAddressesAnyway } from '../../hooks/useAddress'
import { DEFAULT_DEADLINE_FROM_NOW } from '../../constants'
import { toScaled } from '../../utils/bn'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { useTradeResult } from '../../hooks/query/useTradeResult'
import { useTrade } from '../../hooks/contracts/useTrade'
import pendingStore from '../../store/pending'
import { applySlippageTorelance } from '../../utils/slippage'
import { useBlockTimestamp } from '../../hooks/useBlockTimestamp'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import { CardType, Product } from '../../constants/enum'
import iconETH from '../../assets/icons/Icon_ETH.svg'
import iconETH2 from '../../assets/icons/Icon_ETH2.svg'
import iconDelta from '../../assets/icons/Icon_Delta.svg'
import iconUni from '../../assets/icons/Icon_Uni.svg'
import iconCrab from '../../assets/icons/Icon_Crab.svg'
import iconGamma from '../../assets/icons/Icon_Gamma.svg'
import HorizontalRule from '../common/HorizontalRule'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import { MarginForm } from './forms/MarginForm'
import vaultStore from '../../store/vault'
import { getTradeType, TradeButtonArea } from './TradeButtonArea'
import { getDeltaOfSquared, getGamma, getNetDelta } from '../../utils/bs'
import { useCurrentEthPrice } from '../../hooks/usePrice'
import { useClosePosition } from '../../hooks/contracts/useClosePosition'
import { getAmount } from '../../utils'
import { UniUpdateForm } from './forms/UniUpdateForm'
import { encodeMetadata } from '../../utils/metadata'
import {
  toFundingRateString,
  toGreeksString,
  toPriceString
} from '../../utils/number'
import mixpanel from '../../mixpanel'
import { useTradePrice } from '../../hooks/query/useTradePrice'
import PendingIndicator from '../common/PendingIndicator'
import { useL1BlockNumber } from '../../hooks/blockNumber'

export function getCardTitle(card: CardType) {
  switch (card) {
    case CardType.ETH:
      return <p>ETH</p>
    case CardType.ETH2:
      return (
        <p>
          ETH<sup className="text-xs">2</sup>
        </p>
      )
    case CardType.DELTA:
      return <p>Delta</p>
    case CardType.GAMMA:
      return <p>Gamma</p>
    case CardType.UNI_HEDGE:
      return <p>UniV3 LP Hedge</p>
    case CardType.CRAB:
      return <p>Crab Strategy</p>
    case CardType.PRO:
      return <p>Pro</p>
    default:
      return <p>-</p>
  }
}

export function getCardIcon(card: CardType) {
  switch (card) {
    case CardType.ETH:
      return iconETH
    case CardType.ETH2:
      return iconETH2
    case CardType.DELTA:
      return iconDelta
    case CardType.GAMMA:
      return iconGamma
    case CardType.UNI_HEDGE:
      return iconUni
    case CardType.CRAB:
      return iconCrab
    default:
      return ''
  }
}

const CardItem = ({
  title,
  icon,
  isLoaded,
  selected,
  onClick
}: {
  title: ReactNode
  icon: string
  isLoaded: boolean
  selected?: boolean
  onClick: () => void
}) => {
  return (
    <StrategyItem
      onClick={onClick}
      className={`rounded-lg ${
        selected ? 'final-gradient' : 'bg-background'
      } flex items-center ${
        isLoaded ? 'justify-between' : 'justify-center'
      } px-3 cursor-pointer`}
    >
      {isLoaded ? (
        <>
          <span>{title}</span>
          <img
            className={`${icon === iconETH2 ? '-mt-[5px] -mr-[5px]' : ''}`}
            src={icon}
          />
        </>
      ) : (
        <PendingIndicator />
      )}
    </StrategyItem>
  )
}

type Props = {
  asset: number
  vaultId: number
}

type FormData = {
  tradeAmount0: number
  tradeAmount1: number
  marginAmount: number
}

const LitePositionTradeResult = ({ asset, vaultId }: Props) => {
  const { subVaultIndex, setSubVaultIndex } = vaultStore()
  const [isClosePosition, setClosePosition] = useState<boolean>(false)
  const { setPendingApproval, setPendingTx } = pendingStore()

  const [fields, setFields] = useState<FormData>({
    tradeAmount0: 0,
    tradeAmount1: 0,
    marginAmount: 0
  })

  const addresses = useAddressesAnyway()

  const balanceQuery = useERC20BalanceQuery(addresses.QuoteToken)
  const allowanceQuery = useAllowanceQuery(
    addresses.Perpetuals ? addresses.Perpetuals[asset].PerpetualMarket : '',
    addresses.QuoteToken
  )
  const squaredTradePrice = useTradePrice(asset, Product.SQUARED, [0, 1])

  const tradeResult = useTradeResult(
    asset,
    vaultId,
    subVaultIndex,
    [fields.tradeAmount0, fields.tradeAmount1],
    fields.marginAmount
  )
  const ethPrice = useCurrentEthPrice()
  const vaultStatus = useVaultStatus(asset, vaultId)

  const l1BlockNumber = useL1BlockNumber()

  const approve = useApproveMutation()
  const trade = useTrade(asset)
  const closePosition = useClosePosition(asset)

  const onApprove = async () => {
    const tx = await approve.mutateAsync({
      address: addresses.QuoteToken,
      spender: addresses.Perpetuals[asset].PerpetualMarket,
      amount: toScaled(fields.marginAmount, 6)
    })

    // track approve
    mixpanel.track('approve', {
      mode: 'lite',
      vaultId
    })

    await setPendingApproval(tx)
  }

  const onTrade = async () => {
    const trades = []

    if (!vaultStatus.data) {
      return
    }

    const deadline = l1BlockNumber + DEFAULT_DEADLINE_FROM_NOW

    if (subVaultIndex < 0) {
      const tx = await trade.mutateAsync({
        vaultId,
        trades: [],
        marginAmount: toScaled(fields.marginAmount || 0, 6),
        deadline
      })

      await setPendingTx(tx)

      return
    }

    if (isClosePosition) {
      const tx = await closePosition.mutateAsync({
        vaultId,
        subVaultIndex,
        marginAmount: toScaled(fields.marginAmount || 0, 6),
        deadline
      })

      // track trade
      mixpanel.track('trade', {
        mode: 'lite',
        isClose: true,
        vaultId: vaultId
      })

      await setPendingTx(tx)

      // Select pVault Summary
      setClosePosition(false)
      setSubVaultIndex(-1)

      return
    }

    const subVault = vaultStatus.data.subVaults[subVaultIndex]

    if (tradeResult && fields.tradeAmount0 !== 0) {
      trades.push({
        productId: Product.FUTURE,
        subVaultIndex,
        tradeAmount: toScaled(fields.tradeAmount0, 8),
        limitPrice: toScaled(
          applySlippageTorelance(
            tradeResult.future.tradePrice,
            fields.tradeAmount0 > 0
          ),
          8
        ),
        metadata: encodeMetadata(subVault.cardType, subVault.tokenId)
      })
    }

    if (tradeResult && fields.tradeAmount1 !== 0) {
      trades.push({
        productId: Product.SQUARED,
        subVaultIndex,
        tradeAmount: toScaled(fields.tradeAmount1, 8),
        limitPrice: toScaled(
          applySlippageTorelance(
            tradeResult.squared.tradePrice,
            fields.tradeAmount1 > 0
          ),
          8
        ),
        metadata: encodeMetadata(subVault.cardType, subVault.tokenId)
      })
    }

    const tx = await trade.mutateAsync({
      vaultId,
      trades,
      marginAmount: toScaled(fields.marginAmount || 0, 6),
      deadline
    })

    // clear
    setFields({ tradeAmount0: 0, tradeAmount1: 0, marginAmount: 0 })

    // track trade
    mixpanel.track('trade', {
      mode: 'lite',
      vaultId: vaultId,
      isUpdate: true,
      strategyId: subVault.cardType
    })

    await setPendingTx(tx)
  }

  const hasEnoughUSDC = () => {
    return balanceQuery.gte(toScaled(fields.marginAmount || 0, 6))
  }

  const isEnoughUSDCApproved = () => {
    return allowanceQuery.gte(toScaled(fields.marginAmount || 0, 6))
  }

  const isInsufficientLiquidityForThisTrade = () => {
    return (
      !!tradeResult &&
      (!tradeResult.future.isAvailable || !tradeResult.squared.isAvailable)
    )
  }

  const onSelectUpdateType = (e: any) => {
    if (!vaultStatus.data || !ethPrice.isSuccess) {
      return
    }

    const subVault = vaultStatus.data.subVaults[subVaultIndex]

    if (e.target.value === 'close') {
      if (e.target.checked) {
        setClosePosition(true)
        setFields({
          tradeAmount0: -subVault.positionPerpetuals[0],
          tradeAmount1: -subVault.positionPerpetuals[1],
          marginAmount: fields.marginAmount
        })
      } else {
        setClosePosition(false)
        setFields({
          tradeAmount0: 0,
          tradeAmount1: 0,
          marginAmount: fields.marginAmount
        })
      }
    } else {
      setClosePosition(false)

      // Gets funding rate of squared perpetual
      const fundingRate = tradeResult
        ? tradeResult.squared.fundingRate
        : squaredTradePrice.data?.fundingRate || 0

      if (subVault.cardType === CardType.CRAB) {
        const squaredAmount = subVault.positionPerpetuals[1]
        const delta = getDeltaOfSquared(
          squaredAmount,
          fundingRate,
          ethPrice.data
        )
        setFields({
          tradeAmount0: -delta - subVault.positionPerpetuals[0],
          tradeAmount1: 0,
          marginAmount: fields.marginAmount
        })
      } else if (subVault.cardType === CardType.GAMMA) {
        const squaredAmount = subVault.positionPerpetuals[1]
        const delta = getDeltaOfSquared(
          squaredAmount,
          fundingRate,
          ethPrice.data
        )
        setFields({
          tradeAmount0: -delta - subVault.positionPerpetuals[0],
          tradeAmount1: 0,
          marginAmount: fields.marginAmount
        })
      }
    }
  }

  const activeSubVaults = vaultStatus.data
    ? vaultStatus.data.subVaults.filter(
        subVault =>
          subVault.positionPerpetuals[0] !== 0 ||
          subVault.positionPerpetuals[1] !== 0
      )
    : []

  return (
    <div className="rounded-lg bg-secondaly w-full p-6">
      <div>
        <StrategyItem
          className={`rounded-lg ${
            subVaultIndex === -1 ? 'final-gradient' : 'bg-background'
          } flex items-center justify-center cursor-pointer`}
          onClick={() => {
            // clear trade positions
            setFields({
              tradeAmount0: 0,
              tradeAmount1: 0,
              marginAmount: fields.marginAmount
            })
            setClosePosition(false)

            setSubVaultIndex(-1)
          }}
        >
          <p>pVault Summary</p>
        </StrategyItem>
      </div>
      <HorizontalRule />
      <div className="space-y-[11px]">
        {activeSubVaults.length > 0 ? (
          activeSubVaults.map((subVault, i) => {
            return (
              <CardItem
                key={i}
                title={getCardTitle(subVault.cardType)}
                icon={getCardIcon(subVault.cardType)}
                isLoaded={subVault.isMetadataLoaded}
                selected={subVaultIndex === subVault.subVaultIndex}
                onClick={() => {
                  // clear trade positions
                  setFields({
                    tradeAmount0: 0,
                    tradeAmount1: 0,
                    marginAmount: fields.marginAmount
                  })
                  setClosePosition(false)

                  setSubVaultIndex(subVault.subVaultIndex)
                }}
              />
            )
          })
        ) : (
          <div>Please trade first.</div>
        )}
      </div>
      <HorizontalRule />
      {subVaultIndex >= 0 &&
      vaultStatus.data &&
      !!vaultStatus.data.subVaults[subVaultIndex] ? (
        <>
          <div className="space-y-4 text-xs">
            {vaultStatus.data.subVaults.length > 0 ? (
              vaultStatus.data.subVaults[subVaultIndex].cardType ===
              CardType.UNI_HEDGE ? (
                <UniUpdateForm
                  subVault={vaultStatus.data.subVaults[subVaultIndex]}
                  onChange={(amount0, amount1) => {
                    setFields({
                      ...fields,
                      tradeAmount0: amount0,
                      tradeAmount1: amount1
                    })
                  }}
                />
              ) : vaultStatus.data.subVaults[subVaultIndex].cardType ===
                  CardType.CRAB ||
                vaultStatus.data.subVaults[subVaultIndex].cardType ===
                  CardType.GAMMA ? (
                <div className="space-y-4">
                  <div className="space-x-2">
                    <input
                      type="radio"
                      name="update"
                      value="neutral"
                      onChange={onSelectUpdateType}
                    />
                    <label>Delta Neutral</label>
                  </div>
                  <div className="space-x-2">
                    <input
                      type="radio"
                      name="update"
                      value="close"
                      onChange={onSelectUpdateType}
                    />
                    <label>Close Position</label>
                  </div>
                </div>
              ) : (
                <div className="space-y-4">
                  <div className="space-x-2">
                    <input
                      type="checkbox"
                      name="update"
                      value="close"
                      checked={isClosePosition}
                      onChange={onSelectUpdateType}
                    />
                    <label>Close Position</label>
                  </div>
                </div>
              )
            ) : (
              <div />
            )}
            <div className="flex justify-between">
              <p>
                Amount (ETH)<sup className="text-[10px]">2</sup>, ETH:
              </p>
              <p>
                {getAmount(fields.tradeAmount1)}
                {', '}
                {getAmount(fields.tradeAmount0)}
              </p>
            </div>
            <div className="flex justify-between">
              <p>
                Entry Price (ETH)<sup className="text-[10px]">2</sup>, ETH:
              </p>
              <p>
                $
                {tradeResult
                  ? toPriceString(tradeResult.squared.tradePrice)
                  : 0}
                , $
                {tradeResult ? toPriceString(tradeResult.future.tradePrice) : 0}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Estimated Funding Rate:</p>
              <p>
                {toFundingRateString(
                  tradeResult?.squared.estimatedFundingRate || 0
                )}
                % ,{' '}
                {toFundingRateString(
                  tradeResult?.future.estimatedFundingRate || 0
                )}
                %
              </p>
            </div>
            <div className="flex justify-between">
              <p>Delta, Gamma:</p>
              <p>
                {toGreeksString(
                  getNetDelta(
                    fields.tradeAmount0,
                    fields.tradeAmount1,
                    tradeResult?.future.fundingRate || 0,
                    tradeResult?.squared.fundingRate || 0,
                    ethPrice.data || 0
                  )
                )}
                ,{' '}
                {toGreeksString(
                  getGamma(
                    fields.tradeAmount1,
                    tradeResult?.squared.fundingRate || 0
                  )
                )}
              </p>
            </div>
          </div>
          <HorizontalRule />
        </>
      ) : (
        <div />
      )}
      <MarginForm
        marginAmount={fields.marginAmount}
        marginAmountInVault={vaultStatus.data?.positionUsdc || 0}
        requiredMargin={
          tradeResult?.requiredMargin ||
          (vaultStatus.data ? -vaultStatus.data.positionUsdc : 0)
        }
        onChange={amount => {
          setFields({ ...fields, marginAmount: amount })
        }}
        minCollateral={tradeResult ? tradeResult.minCollateral : 0}
        liquidationPrices={tradeResult ? tradeResult.liquidationPrices : []}
      />
      <HorizontalRule />
      <TradeButtonArea
        marginAmount={fields.marginAmount}
        onApprove={onApprove}
        onTrade={onTrade}
        hasEnoughUSDC={hasEnoughUSDC()}
        isEnoughUSDCApproved={isEnoughUSDCApproved()}
        insufficientLiquidity={isInsufficientLiquidityForThisTrade()}
        isMarginEnough={tradeResult ? tradeResult.isMarginEnough : true}
        isExceededSoftLimit={
          tradeResult ? tradeResult.isExceededSoftLimit : false
        }
        isExceededPoolLimit={false}
        isExceededDeltaLimit={false}
        tradeType={getTradeType(fields, isClosePosition)}
        isSubVaultsLoaded={true}
        isTradeAmountsZero={
          fields.tradeAmount0 === 0 &&
          fields.tradeAmount1 === 0 &&
          fields.marginAmount === 0
        }
      />
    </div>
  )
}

const StrategyItem = styled.div`
  height: 50px;
`

export default LitePositionTradeResult
