import { gql } from '@apollo/client'

export const VAULT_HISTORY_QUERY = gql`
  query ($assetId: String, $vaultId: String, $skipCount: Int) {
    tradeHistoryEntities(
      first: 1000
      skip: $skipCount
      orderBy: timestamp
      orderDirection: asc
      where: { asset: $assetId, vault: $vaultId }
    ) {
      id
      vault {
        id
      }
      asset {
        id
      }
      txid
      action
      subVaultIndex
      trader
      productId
      tradeAmount
      tradePrice
      fundingFeePerPosition
      marginAmount
      timestamp
    }
  }
`

export type VaultHistoryEntity = {
  id: string
  vault: {
    id: string
  }
  asset: {
    id: string
  }
  txid: string
  action: string
  subVaultIndex: string
  trader: string
  productId: string
  tradeAmount: string
  tradePrice: string
  fundingFeePerPosition: string
  marginAmount: string
  timestamp: string
}
