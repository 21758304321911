import create from 'zustand'
import { CardType } from '../constants/enum'

interface State {
  selectedCard: CardType
  selectCard: (selectedCard: CardType) => void
}

const useStore = create<State>(set => ({
  selectedCard: CardType.NONE,
  selectCard: (selectedCard: CardType) => set({ selectedCard })
}))

export default useStore
