import { gql } from '@apollo/client'

export const FUNDING_PAYMENT_QUERY = gql`
  query (
    $first: Int
    $orderBy: BigInt
    $orderDirection: String
    $productId: BigInt
  ) {
    fundingPaymentEntities(
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { productId: $productId }
    ) {
      id
      productId
      fundingRate
      totalFundingPaidPerPosition
      fundingPaidPerPosition
      poolReceived
      timestamp
    }
  }
`
