/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from 'ethers'
import { Provider, TransactionRequest } from '@ethersproject/providers'
import type {
  MockChainlinkAggregator,
  MockChainlinkAggregatorInterface
} from '../MockChainlinkAggregator'

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint80',
        name: '',
        type: 'uint80'
      }
    ],
    name: 'getRoundData',
    outputs: [
      {
        internalType: 'uint80',
        name: 'roundId',
        type: 'uint80'
      },
      {
        internalType: 'int256',
        name: 'answer',
        type: 'int256'
      },
      {
        internalType: 'uint256',
        name: 'startedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'updatedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint80',
        name: 'answeredInRound',
        type: 'uint80'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'latestRoundData',
    outputs: [
      {
        internalType: 'uint80',
        name: 'roundId',
        type: 'uint80'
      },
      {
        internalType: 'int256',
        name: 'answer',
        type: 'int256'
      },
      {
        internalType: 'uint256',
        name: 'startedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'updatedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint80',
        name: 'answeredInRound',
        type: 'uint80'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint80',
        name: 'roundId',
        type: 'uint80'
      },
      {
        internalType: 'int256',
        name: 'answer',
        type: 'int256'
      }
    ],
    name: 'setLatestRoundData',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

const _bytecode =
  '0x608060405234801561001057600080fd5b506101ef806100206000396000f3fe608060405234801561001057600080fd5b50600436106100415760003560e01c80639a6fc8f5146100465780639f76840a146100b0578063feaf968c146100de575b600080fd5b61006c6004803603602081101561005c57600080fd5b50356001600160501b03166100e6565b60405180866001600160501b03168152602001858152602001848152602001838152602001826001600160501b031681526020019550505050505060405180910390f35b6100dc600480360360408110156100c657600080fd5b506001600160501b038135169060200135610120565b005b61006c61019a565b600060208190529081526040902080546001820154600283015460038401546004909401546001600160501b039384169492939192911685565b6001600160501b039182166000818152602081905260409020805469ffffffffffffffffffff19908116909217808255600180830194855542600280850182815560038087019384558454958a16958816959095179093559554909555549055915460049081559091015460058054919093169116179055565b6001546002546003546004546005546001600160501b0394851694168556fea26469706673582212202f8e000e7c5236547d7f0bd3b5f11a5f4ebcd822513022f9a5ee1592f97f166064736f6c63430007060033'

export class MockChainlinkAggregator__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0])
    } else {
      super(...args)
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MockChainlinkAggregator> {
    return super.deploy(overrides || {}) as Promise<MockChainlinkAggregator>
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {})
  }
  attach(address: string): MockChainlinkAggregator {
    return super.attach(address) as MockChainlinkAggregator
  }
  connect(signer: Signer): MockChainlinkAggregator__factory {
    return super.connect(signer) as MockChainlinkAggregator__factory
  }
  static readonly bytecode = _bytecode
  static readonly abi = _abi
  static createInterface(): MockChainlinkAggregatorInterface {
    return new utils.Interface(_abi) as MockChainlinkAggregatorInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MockChainlinkAggregator {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as MockChainlinkAggregator
  }
}
