/**
 * Calculates Implied Volatility(annual)
 * @param fundingRate
 * @returns
 */
export function getIV(fundingRate: number) {
  return Math.sqrt(fundingRate * 365)
}

export const getNetDelta = (
  tradeAmount0: number,
  tradeAmount1: number,
  fundingRate0: number,
  fundingRate1: number,
  ethPrice: number
) => {
  return (
    getDeltaOfFuture(tradeAmount0, fundingRate0) +
    getDeltaOfSquared(tradeAmount1, fundingRate1, ethPrice)
  )
}

export const getDeltaOfFuture = (
  tradeAmount0: number,
  fundingRate0: number
) => {
  return tradeAmount0 * (1 + fundingRate0)
}

export const getDeltaOfSquared = (
  tradeAmount1: number,
  fundingRate1: number,
  ethPrice: number
) => {
  return (tradeAmount1 * 2 * (1 + fundingRate1) * ethPrice) / 10000
}

export const getGamma = (tradeAmount1: number, fundingRate1: number) => {
  return (tradeAmount1 * 2 * (1 + fundingRate1)) / 10000
}

/**
 * Calculates Vega / day
 * @param tradeAmount1
 * @param tradePrice1
 * @param fundingRate1
 * @returns
 */
export const getVega = (tradeAmount1: number, fundingRate1: number) => {
  return 2 * Math.sqrt(fundingRate1) * tradeAmount1 * (1 + fundingRate1)
}

/**
 * Calculates Theta / day
 * @param tradeAmount
 * @param tradePrice
 * @param fundingRate
 * @returns
 */
export const getTheta = (
  tradeAmount: number,
  tradePrice: number,
  fundingRate: number
) => {
  // add 0 to convert -0 to 0
  return -(tradeAmount * tradePrice * fundingRate) + 0
}
