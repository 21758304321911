import React, { useState } from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  AreaChart,
  Tooltip
} from 'recharts'
import { useIndexPrices } from '../../hooks/query/chart/useIndexPrices'
import { ChartRange, Product } from '../../constants/enum'
import TimeRangeSelector from './TimeRangeSelector'
import { toFixedNumber, toPriceString } from '../../utils/number'
import { toDateString, toTimeString } from '../../utils/string'

type Props = {
  asset: string
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="text-white text-xs final-gradient rounded-lg p-1">
        <p>${toPriceString(payload[0].value)}</p>
      </div>
    )
  }
  return null
}

const PriceChart = ({ asset }: Props) => {
  const [priceType, setPriceType] = useState(Product.FUTURE)
  const [chartRange, setChartRange] = useState(ChartRange.Day)
  const indexPrices = useIndexPrices(asset, priceType, chartRange, 200)

  const change = indexPrices.data ? indexPrices.data.change : 0

  return (
    <div className="rounded-lg bg-secondaly w-full h-[469px]">
      <div className="flex justify-between items-center p-4">
        <div>
          <select
            className="rounded-lg bg-third w-[129px] h-9 text-center"
            onChange={e => {
              setPriceType(Number(e.target.value))
            }}
          >
            <option value="0">Chart ETH</option>
            <option value="1">Chart ETH2</option>
          </select>
        </div>
        <div className="flex items-center leading-4 space-x-2">
          <p className="text-sm">
            {priceType === Product.FUTURE
              ? 'ETH-PERPETUAL-USDC'
              : 'ETH2-PERPETUAL-USDC'}
          </p>
          <p className={`text-xs ${change >= 0 ? 'text-green' : 'text-red'}`}>
            {change >= 0 ? '▲' : '▼'}
            {toFixedNumber(change * 100)}%
          </p>
        </div>
        <TimeRangeSelector
          chartRange={chartRange}
          onSelect={chartRange => {
            setChartRange(chartRange)
          }}
        />
      </div>
      <div className="mt-4 p-3">
        {indexPrices.isLoading ? (
          <div className="flex items-center justify-center h-60">
            <div className="animate-ping h-4 w-4 final-gradient rounded-full"></div>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={340}>
            <AreaChart data={indexPrices.data.prices}>
              <defs>
                <linearGradient
                  id="blueLineGradation"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop offset="0%" stopColor="#257EFFFF" />
                  <stop offset="100%" stopColor="#8526FFFF" />
                </linearGradient>
                <linearGradient
                  id="blueChartGradation"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop offset="0%" stopColor="#5259FA1A" />
                  <stop offset="100%" stopColor="#5259FA00" />
                </linearGradient>
              </defs>
              <CartesianGrid stroke="#4c525f" strokeDasharray="5 5" />
              <XAxis
                dataKey="timestamp"
                tick={{ fontSize: 12 }}
                tickFormatter={timestamp =>
                  chartRange === ChartRange.Hour ||
                  chartRange === ChartRange.Day
                    ? toTimeString(timestamp)
                    : toDateString(timestamp)
                }
              />
              <YAxis
                dataKey="price"
                domain={[
                  indexPrices.data.domainLow,
                  indexPrices.data.domainHigh
                ]}
                tick={{ fontSize: 12 }}
                tickFormatter={(value: number) => `$${toPriceString(value)}`}
                orientation="right"
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="price"
                stroke="url(#blueLineGradation)"
                strokeWidth={2}
                fill="url(#blueChartGradation)"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
}

export default PriceChart
