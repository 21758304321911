import React from 'react'
import { useRoute } from 'wouter'
import { getAssetIndex } from '../utils/asset'
import { useProtocolStatus, useVolumes } from '../hooks/query/useProtocolStatus'
import { toAmountString, toPriceString } from '../utils/number'
import { useLPTokenPrice } from '../hooks/query/pool/usePoolStatus'
import { BigNumber } from 'ethers'
import { toUnscaled } from '../utils/bn'
import OpenInterestChart from '../components/chart/OpenInterestChart'

const StatusView = () => {
  const params = useRoute('/home/:asset')[1]

  const assetIndex = getAssetIndex(params?.asset)

  const protocolStatus = useProtocolStatus(assetIndex)
  const lpTokenPrice = useLPTokenPrice(assetIndex, 0)
  const volumes = useVolumes()

  return (
    <>
      <div className="grid grid-cols-2 gap-4 container mx-auto content-start">
        <div>
          <div className="rounded-lg bg-secondaly p-6">
            <label>TVL</label>
            <div className="flex justify-center text-lg font-semibold py-6">
              ${toPriceString(protocolStatus.data?.tvl || 0)}
            </div>
          </div>
        </div>
        <div>
          <div className="rounded-lg bg-secondaly p-6">
            <label>Number of Vaults</label>
            <div className="flex justify-center text-lg font-semibold py-6">
              {protocolStatus.data?.numVaults || 0}
            </div>
          </div>
        </div>
        <div>
          <div className="rounded-lg bg-secondaly p-6">
            <label>Total Notional Volume</label>
            <div className="flex justify-center text-lg font-semibold py-6">
              <span>
                $
                {protocolStatus.data
                  ? toPriceString(
                      protocolStatus.data.metrics[0].notionalVolume +
                        protocolStatus.data.metrics[1].notionalVolume
                    )
                  : 0}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="rounded-lg bg-secondaly p-6">
            <label>Estimated PnL</label>
            <div className="flex justify-between text-lg font-semibold py-2">
              <span>AMM</span>
              <span>
                {lpTokenPrice.isSuccess && protocolStatus.isSuccess
                  ? toPriceString(
                      toUnscaled(
                        lpTokenPrice.data.lpTokenPrice.sub(
                          BigNumber.from('10000000000000000')
                        ),
                        16
                      ) * protocolStatus.data.lpTokenTotalSupply
                    )
                  : 0}{' '}
                USDC
              </span>
            </div>

            <div className="flex justify-between text-lg font-semibold py-2">
              <span>Hedger</span>
              <span>
                {toPriceString(protocolStatus.data?.hedgerBalance || 0)} USDC
              </span>
            </div>
            <div className="flex justify-between text-lg font-semibold py-2">
              <span>FeePool</span>
              <span>
                {toPriceString(protocolStatus.data?.feePoolBalance || 0)} USDC
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="rounded-lg bg-secondaly p-10">
            <label>Future</label>
            <div className="col-span-1 row-span-1">
              <OpenInterestChart productId={0} />
            </div>
            <div className="flex justify-between text-lg font-semibold py-2">
              <span>Volume</span>
              <span>
                $
                {protocolStatus.data
                  ? toPriceString(protocolStatus.data.metrics[0].notionalVolume)
                  : 0}
              </span>
            </div>
            <div className="flex justify-between text-lg font-semibold py-2">
              <span>Open Long</span>
              <span>
                {protocolStatus.data
                  ? toAmountString(protocolStatus.data.metrics[0].openLong)
                  : 0}
              </span>
            </div>
            <div className="flex justify-between text-lg font-semibold py-2">
              <span>Open Short</span>
              <span>
                {protocolStatus.data
                  ? toAmountString(protocolStatus.data.metrics[0].openShort)
                  : 0}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="rounded-lg bg-secondaly p-10">
            <label>Squared</label>
            <div className="col-span-1 row-span-1">
              <OpenInterestChart productId={1} />
            </div>
            <div className="flex justify-between text-lg font-semibold py-2">
              <span>Volume</span>
              <span>
                $
                {protocolStatus.data
                  ? toPriceString(protocolStatus.data.metrics[1].notionalVolume)
                  : 0}
              </span>
            </div>
            <div className="flex justify-between text-lg font-semibold py-2">
              <span>Open Long</span>
              <span>
                {protocolStatus.data
                  ? toAmountString(protocolStatus.data.metrics[1].openLong)
                  : 0}
              </span>
            </div>
            <div className="flex justify-between text-lg font-semibold py-2">
              <span>Open Short</span>
              <span>
                {protocolStatus.data
                  ? toAmountString(protocolStatus.data.metrics[1].openShort)
                  : 0}
              </span>
            </div>
          </div>
        </div>

        <div>
          <div className="rounded-lg bg-secondaly p-10">
            <label>ETH Daily</label>
            <div className="flex justify-between text-lg font-semibold py-2">
              <table className="border-collapse table-fixed text-left">
                <thead>
                  <tr>
                    <th className="w-40">Date</th>
                    <th className="w-40">Volume</th>
                    <th className="w-40">Notional</th>
                  </tr>
                </thead>
                <tbody>
                  {volumes.data ? (
                    volumes.data.future.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.time}</td>
                          <td>{toAmountString(item.volume)}</td>
                          <td>${toPriceString(item.notionalVolume)}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <td></td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          <div className="rounded-lg bg-secondaly p-10">
            <label>ETH2 Daily</label>
            <div className="flex justify-between text-lg font-semibold py-2">
              <table className="border-collapse table-fixed text-left">
                <thead>
                  <tr>
                    <th className="w-40">Date</th>
                    <th className="w-40">Volume</th>
                    <th className="w-40">Notional</th>
                  </tr>
                </thead>
                <tbody>
                  {volumes.data ? (
                    volumes.data.squared.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.time}</td>
                          <td>{toAmountString(item.volume)}</td>
                          <td>${toPriceString(item.notionalVolume)}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <td></td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StatusView
