import React from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line
} from 'recharts'
import tradeStore from '../../store/trade'
import { useSpotPnLChartData } from '../../hooks/query/chart/useSpotPnLChartData'
import { toPriceString } from '../../utils/number'

type Props = {
  asset: string
  vaultId: number
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const dataDefs = (payload as { name: string; value: number }[]).map(p => ({
      name: p.name,
      value: p.value
    }))

    // sort by value
    dataDefs.sort((a: any, b: any) => b.value - a.value)

    return (
      <div className="text-xs rounded-lg p-1">
        {dataDefs.map((dataDef, i) => {
          return (
            <div key={i} className="text-white text-xs p-1">
              <p>
                {dataDef.name}: ${toPriceString(dataDef.value)}
              </p>
            </div>
          )
        })}
      </div>
    )
  }
  return null
}

const SpotPnLChart = ({ asset, vaultId }: Props) => {
  const { fields } = tradeStore()
  const spotPnL = useSpotPnLChartData(asset, vaultId, 0, fields)

  return (
    <div className="p-6 h-[410px]">
      <div>
        <p className="text-lg font-semibold">
          Trade&#39;s PnL & pVault Value vs Spot Price
        </p>
      </div>
      <div className="py-4">
        <ResponsiveContainer width="96%" height={322}>
          <LineChart data={spotPnL.values}>
            <CartesianGrid stroke="#4c525f" strokeDasharray="5 5" />
            <XAxis dataKey="price" tick={{ fontSize: 12 }} />
            <YAxis
              domain={[spotPnL.domainLow, spotPnL.domainHigh]}
              tick={{ fontSize: 12 }}
              tickFormatter={(value: number) => `$${toPriceString(value)}`}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line
              type="monotone"
              name="Trade"
              dataKey="value"
              stroke="#82ca9d"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              name="pVault"
              dataKey="netValue"
              stroke="#5259FA"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              name="Min. Collateral"
              dataKey="minCollateral"
              stroke="#FFFFFF"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default SpotPnLChart
