import { useQuery } from '@apollo/client'
import { useChainId } from '../network'
import { VaultEntities, VAULTS_QUERY } from '../../queries/vaultQuery'
import { PredyClient } from '../../utils/apollo-client'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useMemo } from 'react'

export function useVaultIds():
  | { isLoading: true; data: null }
  | { isLoading: false; data: number[] } {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()

  const { data } = useQuery<VaultEntities>(VAULTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    client: PredyClient[chainId],
    variables: {
      account: (account || '').toLowerCase()
    },
    pollInterval: 10000
  })

  const vaults = useMemo(() => {
    if (data) {
      return data.vaultEntities.map(entity => Number(entity.vaultId))
    }
    return null
  }, [data])

  if (data === undefined || vaults === null) {
    return {
      isLoading: true,
      data: null
    }
  }

  return {
    isLoading: false,
    data: vaults
  }
}

export function useLatestVaultId() {
  const vaultIds = useVaultIds()

  return {
    isLoading: vaultIds.isLoading,
    data: vaultIds.data && vaultIds.data.length > 0 ? vaultIds.data[0] : 0
  }
}
