import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { ERC20__factory } from '../../typechain'
import { useChainId, useIsSupportedChain } from '../network'
import { useEffect, useState } from 'react'
import { useAddresses } from '../useAddress'

export function useERC20BalanceQuery(address: string) {
  const { library, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  const [balance, setBalance] = useState<BigNumber>(BigNumber.from(0))

  const balanceQuery = useQuery(
    ['balance', account, address, chainId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')

      const contract = ERC20__factory.connect(address, library)
      return await contract.balanceOf(account)
    },

    {
      enabled: !!account && supportedChain && !!library && !!addresses
    }
  )

  useEffect(() => {
    if (balanceQuery.isSuccess) {
      setBalance(balanceQuery.data)
    }
  }, [balanceQuery.isSuccess, balanceQuery.data])

  return balance
}

export function useEthBalanceQuery() {
  const { library, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()

  return useQuery(
    ['balance', account, chainId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')

      return await library.getBalance(account)
    },

    {
      enabled: !!account && supportedChain && !!library
    }
  )
}
