import React, { useState } from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area,
  Tooltip
} from 'recharts'
import { ChartRange } from '../../constants/enum'
import TimeRangeSelector from './TimeRangeSelector'
import { toDateString, toTimeString } from '../../utils/string'
import OpenInterest from '../../hooks/query/useOpenInterest'
import { toAmountString } from '../../utils/number'

type Props = {
  asset: string
  vaultId: number
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="text-white text-xs green-gradient rounded-lg p-1">
        <p>Long: {payload[0].value}</p>
        <p>Short: {payload[1].value}</p>
      </div>
    )
  }
  return null
}

export interface IOpenInterestChartProps {
  productId: number
}

export default function OpenInterestChart(props: IOpenInterestChartProps) {
  const [chartRange, setChartRange] = useState(ChartRange.Week)

  const chartData = OpenInterest(chartRange, props.productId)
  let maxDomain = 1000
  if (chartData != null) {
    maxDomain = Math.round(
      Math.max.apply(
        null,
        chartData.map(a => a.openLong).concat(chartData.map(a => a.openShort))
      ) * 1.1
    )
  }
  const x = 500
  const domain = [0, maxDomain]

  return (
    <div>
      <div className="flex justify-between p-2 px-4">
        <p className="text-lg font-semibold text-green">Open Interest</p>
        <TimeRangeSelector
          chartRange={chartRange}
          onSelect={(chartRange: ChartRange) => {
            setChartRange(chartRange)
          }}
        />
      </div>
      <div>
        {!chartData ? (
          <div className="flex items-center justify-center h-60">
            <div className="animate-ping h-4 w-4 green-gradient rounded-full"></div>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={500}>
            <AreaChart data={chartData}>
              <defs>
                <linearGradient
                  id="longChartGradation"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor="#269c24" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#269c24" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                  id="shortChartGradation"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor="#F54242" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#F54242" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid stroke="#4c525f" />
              <XAxis
                dataKey="timestamp"
                tick={{ fontSize: 12 }}
                tickFormatter={timestamp =>
                  chartRange === ChartRange.Hour ||
                  chartRange === ChartRange.Day
                    ? toTimeString(timestamp)
                    : toDateString(timestamp)
                }
              />
              <YAxis
                domain={domain}
                tick={{ fontSize: 12 }}
                tickCount={10}
                tickMargin={5}
              />
              <Tooltip
                cursor={{ stroke: 'white', strokeWidth: 2, fill: 'none' }}
                content={<CustomTooltip />}
              />
              <Area
                type="monotone"
                dataKey="openLong"
                stroke="#269c24"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#longChartGradation)"
                activeDot={{
                  r: 4,
                  stroke: 'white',
                  strokeWidth: 2,
                  fill: '#2E3239'
                }}
              />
              <Area
                type="monotone"
                dataKey="openShort"
                stroke="#F54242"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#shortChartGradation)"
                activeDot={{
                  r: 4,
                  stroke: 'white',
                  strokeWidth: 2,
                  fill: '#2E3239'
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
}
