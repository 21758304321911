import React, { useState } from 'react'
import { useAddressesAnyway } from '../../hooks/useAddress'
import { DEFAULT_DEADLINE_FROM_NOW } from '../../constants'
import { toScaled } from '../../utils/bn'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { useTradeResult } from '../../hooks/query/useTradeResult'
import { useTrade } from '../../hooks/contracts/useTrade'
import { applySlippageTorelance } from '../../utils/slippage'
import modalStore from '../../store/modal'
import tradeStore from '../../store/trade'
import cardStore from '../../store/card'
import pendingStore from '../../store/pending'
import vaultStore from '../../store/vault'
import { useBlockTimestamp } from '../../hooks/useBlockTimestamp'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import { CardType, Product } from '../../constants/enum'
import iconETH from '../../assets/icons/Icon_ETH.svg'
import iconETH2 from '../../assets/icons/Icon_ETH2.svg'
import iconDelta from '../../assets/icons/Icon_Delta.svg'
import iconMagnifyingGlass from '../../assets/MagnifyingGlass.png'
import iconCross from '../../assets/Cross.png'
import imgTouch from '../../assets/touch.svg'
import HorizontalRule from '../common/HorizontalRule'
import { AmountForm, PositionForm } from './AmountForm'
import { MarginForm } from './forms/MarginForm'
import { UniForm } from './forms/UniForm'
import { CrabForm } from './forms/CrabForm'
import { GammaForm } from './forms/GammaForm'
import { useCurrentEthPrice } from '../../hooks/usePrice'
import { getNetDelta, getGamma } from '../../utils/bs'
import { encodeMetadata } from '../../utils/metadata'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import { getTradeType, TradeButtonArea } from './TradeButtonArea'
import { getAmount } from '../../utils'
import {
  toAPYString,
  toFundingRateString,
  toGreeksString,
  toIndexPriceString,
  toPriceString
} from '../../utils/number'
import mixpanel from '../../mixpanel'
import { useCrabApy } from '../../hooks/query/useCrabApy'
import {
  getNextSubVaultId,
  getSubVaultIdWithCardType
} from '../../utils/helpers/subVaultHelper'
import { useLocation } from 'wouter'
import { getAssetName } from '../../utils/asset'
import { useL1BlockNumber } from '../../hooks/blockNumber'

type Props = {
  asset: number
  isLoading: boolean
  vaultId: number
}

const LiteTradeResult = ({ asset, isLoading, vaultId }: Props) => {
  const setLocation = useLocation()[1]
  const [uniTokenId, setUniTokenId] = useState(0)
  const { fields, setFields } = tradeStore()
  const { setOpen } = modalStore()
  const { selectedCard } = cardStore()
  const { setPendingApproval, setPendingTx } = pendingStore()
  const { setSubVaultIndex } = vaultStore()

  const addresses = useAddressesAnyway()

  const balanceQuery = useERC20BalanceQuery(addresses.QuoteToken)
  const allowanceQuery = useAllowanceQuery(
    addresses.Perpetuals ? addresses.Perpetuals[asset].PerpetualMarket : '',
    addresses.QuoteToken
  )

  const vaultStatus = useVaultStatus(asset, vaultId)
  const subVaultIndex =
    vaultId === 0 || vaultStatus.data === null
      ? 0
      : getNextSubVaultId(vaultStatus.data)

  const tradeResult = useTradeResult(
    asset,
    vaultId,
    subVaultIndex,
    [fields.tradeAmount0, fields.tradeAmount1],
    fields.marginAmount
  )
  const l1BlockNumber = useL1BlockNumber()
  const ethPrice = useCurrentEthPrice()
  const crabApy = useCrabApy(
    asset,
    fields.tradeAmount0,
    fields.tradeAmount1,
    fields.marginAmount
  )

  const approve = useApproveMutation()
  const trade = useTrade(asset)

  const onApprove = async () => {
    const tx = await approve.mutateAsync({
      address: addresses.QuoteToken,
      spender: addresses.Perpetuals[asset].PerpetualMarket,
      amount: toScaled(fields.marginAmount, 6)
    })

    // track approve
    mixpanel.track('approve', {
      mode: 'lite',
      vaultId
    })

    await setPendingApproval(tx)
  }

  const onTrade = async () => {
    const trades = []

    if (!tradeResult || isLoading) {
      return
    }

    const subVaultIndex =
      vaultId === 0 || vaultStatus.data === null
        ? 0
        : getSubVaultIdWithCardType(vaultStatus.data, selectedCard)

    if (fields.tradeAmount0 !== 0) {
      trades.push({
        productId: Product.FUTURE,
        subVaultIndex,
        tradeAmount: toScaled(fields.tradeAmount0, 8),
        limitPrice: toScaled(
          applySlippageTorelance(
            tradeResult.future.tradePrice,
            fields.tradeAmount0 > 0
          ),
          8
        ),
        metadata: encodeMetadata(selectedCard, uniTokenId)
      })
    }

    if (fields.tradeAmount1 !== 0) {
      trades.push({
        productId: Product.SQUARED,
        subVaultIndex,
        tradeAmount: toScaled(fields.tradeAmount1, 8),
        limitPrice: toScaled(
          applySlippageTorelance(
            tradeResult.squared.tradePrice,
            fields.tradeAmount1 > 0
          ),
          8
        ),
        metadata: encodeMetadata(selectedCard, uniTokenId)
      })
    }

    const tx = await trade.mutateAsync({
      vaultId,
      trades,
      marginAmount: toScaled(fields.marginAmount || 0, 6),
      deadline: l1BlockNumber + DEFAULT_DEADLINE_FROM_NOW
    })

    // clear
    setFields({ tradeAmount0: 0, tradeAmount1: 0, marginAmount: 0 })

    // track trade
    mixpanel.track('trade', {
      mode: 'lite',
      vaultId: vaultId,
      strategyId: selectedCard
    })

    // Close modal
    setOpen(false)

    await setPendingTx(tx)

    // Transition to Positions view
    setSubVaultIndex(subVaultIndex)

    vaultStatus.refetch()

    setLocation(`/lite/position/${getAssetName(asset)}`)
  }

  const hasEnoughUSDC = () => {
    return balanceQuery.gte(toScaled(fields.marginAmount || 0, 6))
  }

  const isEnoughUSDCApproved = () => {
    return allowanceQuery.gte(toScaled(fields.marginAmount || 0, 6))
  }

  const isInsufficientLiquidityForThisTrade = () => {
    return (
      !!tradeResult &&
      (!tradeResult.future.isAvailable || !tradeResult.squared.isAvailable)
    )
  }

  const changeAmountsHandler = (amount0: number, amount1: number) => {
    setFields({
      ...fields,
      tradeAmount0: amount0,
      tradeAmount1: amount1
    })
    setOpen(true)
  }

  function getForm() {
    if (selectedCard === CardType.ETH2) {
      return (
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <img src={iconETH2} className="-mt-[7px] h-[58px]"></img>
              <div>
                <p>
                  ETH<sup className="text-xs">2</sup>
                </p>
                <p className="text-xs text-subtext">
                  ${toIndexPriceString(Math.pow(ethPrice.data || 0, 2))}/10,000
                </p>
              </div>
            </div>
            <div className="flex space-x-2">
              <img
                className="cursor-pointer"
                src={iconMagnifyingGlass}
                onClick={() => {
                  setOpen(true)
                }}
              />
              <img
                className="cursor-pointer"
                src={iconCross}
                onClick={() => {
                  setOpen(false)
                }}
              />
            </div>
          </div>
          <PositionForm
            amount={fields.tradeAmount1}
            onChange={amount => {
              setFields({ ...fields, tradeAmount0: 0, tradeAmount1: amount })
              setOpen(true)
            }}
            step={1}
            name={'tradeAmount1'}
          />
          <div className="space-y-2 text-xs">
            <div className="flex justify-between">
              <p>Entry Price:</p>
              <p>
                $
                {tradeResult
                  ? toPriceString(tradeResult.squared.tradePrice)
                  : 0}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Estimated Funding Rate:</p>
              <p>
                {tradeResult
                  ? toFundingRateString(
                      tradeResult.squared.estimatedFundingRate
                    )
                  : 0}
                %
              </p>
            </div>
            <div className="flex justify-between">
              <p>Delta, Gamma:</p>
              <p>
                {toGreeksString(
                  getNetDelta(
                    0,
                    fields.tradeAmount1,
                    0,
                    tradeResult?.squared.fundingRate || 0,
                    ethPrice.data || 0
                  )
                )}
                ,{' '}
                {toGreeksString(
                  getGamma(
                    fields.tradeAmount1,
                    tradeResult?.squared.fundingRate || 0
                  )
                )}
              </p>
            </div>
          </div>
        </div>
      )
    } else if (selectedCard === CardType.ETH) {
      return (
        <div className="space-y-4">
          <div className="flex flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <img src={iconETH} className="h-[50px]"></img>
              <div>
                <p>ETH</p>
                <p className="text-xs text-subtext">
                  ${toPriceString(ethPrice.data || 0)}
                </p>
              </div>
            </div>
            <div className="flex space-x-2">
              <img
                className="cursor-pointer"
                src={iconMagnifyingGlass}
                onClick={() => {
                  setOpen(true)
                }}
              />
              <img
                className="cursor-pointer"
                src={iconCross}
                onClick={() => {
                  setOpen(false)
                }}
              />
            </div>
          </div>
          <div className="m-0">
            <PositionForm
              amount={fields.tradeAmount0}
              onChange={amount => {
                setFields({ ...fields, tradeAmount0: amount, tradeAmount1: 0 })
                setOpen(true)
              }}
              step={1}
              name={'tradeAmount0'}
            />
          </div>
          <div className="space-y-2 text-xs">
            <div className="flex justify-between">
              <p>Entry Price:</p>
              <p>
                $
                {tradeResult ? toPriceString(tradeResult.future.tradePrice) : 0}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Estimated Funding Rate:</p>
              <p>
                {tradeResult
                  ? toFundingRateString(tradeResult.future.estimatedFundingRate)
                  : 0}
                %
              </p>
            </div>
            <div className="flex justify-between">
              <p>Delta, Gamma:</p>
              <p>
                {toGreeksString(
                  getNetDelta(
                    fields.tradeAmount0,
                    fields.tradeAmount1,
                    tradeResult?.future.fundingRate || 0,
                    0,
                    ethPrice.data || 0
                  )
                )}
                , 0
              </p>
            </div>
          </div>
        </div>
      )
    } else if (selectedCard === CardType.DELTA) {
      return (
        <div className="space-y-4">
          <div className="flex flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <img src={iconDelta} className="h-[50px]"></img>
              <div>
                <p>Delta</p>
              </div>
            </div>
            <div className="flex space-x-2">
              <img
                className="cursor-pointer"
                src={iconMagnifyingGlass}
                onClick={() => {
                  setOpen(true)
                }}
              />
              <img
                className="cursor-pointer"
                src={iconCross}
                onClick={() => {
                  setOpen(false)
                }}
              />
            </div>
          </div>
          <div className="m-0">
            <AmountForm
              amount={fields.tradeAmount0}
              onChange={amount => {
                setFields({ ...fields, tradeAmount0: amount, tradeAmount1: 0 })
                setOpen(true)
              }}
              step={1}
              name={'tradeAmount0'}
            />
          </div>
          <div className="space-y-2 text-xs">
            <div className="flex justify-between">
              <p>
                Amount (ETH)<sup className="text-xs">2</sup>, ETH:
              </p>
              <p>
                {getAmount(fields.tradeAmount1)}
                {', '}
                {getAmount(fields.tradeAmount0)}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Entry Price:</p>
              <p>
                $
                {tradeResult ? toPriceString(tradeResult.future.tradePrice) : 0}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Estimated Funding Rate:</p>
              <p>
                {tradeResult
                  ? toFundingRateString(tradeResult.future.estimatedFundingRate)
                  : 0}
                %
              </p>
            </div>
            <div className="flex justify-between">
              <p>Delta, Gamma:</p>
              <p>
                {toGreeksString(
                  getNetDelta(
                    fields.tradeAmount0,
                    fields.tradeAmount1,
                    tradeResult?.future.fundingRate || 0,
                    0,
                    ethPrice.data || 0
                  )
                )}
                , 0
              </p>
            </div>
          </div>
        </div>
      )
    } else if (selectedCard === CardType.GAMMA) {
      return (
        <GammaForm
          amounts={[fields.tradeAmount0, fields.tradeAmount1]}
          tradePrices={[
            tradeResult?.future.tradePrice || 0,
            tradeResult?.squared.tradePrice || 0
          ]}
          fundingRates={[
            tradeResult?.future.estimatedFundingRate || 0,
            tradeResult?.squared.estimatedFundingRate || 0
          ]}
          onChange={changeAmountsHandler}
        />
      )
    } else if (selectedCard === CardType.UNI_HEDGE) {
      return (
        <UniForm
          tokenId={uniTokenId}
          amounts={[fields.tradeAmount0, fields.tradeAmount1]}
          tradePrices={[
            tradeResult?.future.tradePrice || 0,
            tradeResult?.squared.tradePrice || 0
          ]}
          fundingRates={[
            tradeResult?.future.estimatedFundingRate || 0,
            tradeResult?.squared.estimatedFundingRate || 0
          ]}
          onChange={changeAmountsHandler}
          onLPTokenSelected={tokenId => {
            setUniTokenId(tokenId)
          }}
        />
      )
    } else if (selectedCard === CardType.CRAB) {
      return (
        <CrabForm
          asset={asset}
          amounts={[fields.tradeAmount0, fields.tradeAmount1]}
          tradePrices={[
            tradeResult?.future.tradePrice || 0,
            tradeResult?.squared.tradePrice || 0
          ]}
          fundingRates={[
            tradeResult?.future.estimatedFundingRate || 0,
            tradeResult?.squared.estimatedFundingRate || 0
          ]}
          onChange={changeAmountsHandler}
        />
      )
    } else {
      return (
        <div className="flex items-center justify-center">
          <img src={imgTouch} alt={'select a card'} />
        </div>
      )
    }
  }

  return (
    <div className="rounded-lg bg-secondaly w-full p-6 px-8">
      <div className="pt-2">
        <h3 className="text-xl">Select Amount</h3>
        <p className="text-[13px]">
          You have to select item first to see details
        </p>
      </div>
      <HorizontalRule />
      {getForm()}
      {selectedCard === CardType.NONE ? (
        <div />
      ) : (
        <div>
          <HorizontalRule />
          <MarginForm
            marginAmount={fields.marginAmount}
            requiredMargin={tradeResult?.requiredMargin || 0}
            marginAmountInVault={vaultStatus.data?.positionUsdc || 0}
            onChange={amount => {
              setFields({ ...fields, marginAmount: amount })
            }}
            minCollateral={tradeResult ? tradeResult.minCollateral : 0}
            liquidationPrices={tradeResult ? tradeResult.liquidationPrices : []}
          />
          {selectedCard === CardType.CRAB ? (
            <div className="mt-2 space-y-2 text-xs">
              <div className="flex justify-between">
                <p>Estimated APY:</p>
                <p> {toAPYString(crabApy.data?.income || 0)}%</p>
              </div>
              <div className="flex justify-between">
                <p>Estimated APY with Delta Hedging:</p>
                <p>{toAPYString(crabApy.data?.apy || 0)}%</p>
              </div>
            </div>
          ) : (
            <div />
          )}
          <HorizontalRule />
          <TradeButtonArea
            marginAmount={fields.marginAmount}
            onApprove={onApprove}
            onTrade={onTrade}
            hasEnoughUSDC={hasEnoughUSDC()}
            isEnoughUSDCApproved={isEnoughUSDCApproved()}
            insufficientLiquidity={isInsufficientLiquidityForThisTrade()}
            isMarginEnough={tradeResult ? tradeResult.isMarginEnough : true}
            isExceededSoftLimit={
              tradeResult ? tradeResult.isExceededSoftLimit : false
            }
            isExceededPoolLimit={
              false
              //tradeResult ? tradeResult.isExceededPoolLimit : false
            }
            isExceededDeltaLimit={
              tradeResult ? tradeResult.isExceededDeltaLimit : false
            }
            tradeType={getTradeType(fields, false)}
            isSubVaultsLoaded={vaultStatus.data?.isSubVaultsLoaded || true}
            isTradeAmountsZero={
              fields.tradeAmount0 === 0 &&
              fields.tradeAmount1 === 0 &&
              fields.marginAmount === 0
            }
          />
        </div>
      )}
    </div>
  )
}

export default LiteTradeResult
