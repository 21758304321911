import React from 'react'
import { useUniswapPosition } from '../../../hooks/query/useUniswapPositions'

type Props = {
  subVault: { positionPerpetuals: number[]; tokenId?: number }
  onChange: (amount0: number, amount1: number) => void
}

export const UniUpdateForm = ({ subVault, onChange }: Props) => {
  const uniPosition = useUniswapPosition(subVault.tokenId || 0)

  const onSelectUpdateType = (e: any) => {
    if (e.target.value === 'close') {
      onChange(-subVault.positionPerpetuals[0], -subVault.positionPerpetuals[1])
    } else {
      if (uniPosition.isSuccess) {
        onChange(
          uniPosition.data.futureAmount - subVault.positionPerpetuals[0],
          uniPosition.data.squaredAmount - subVault.positionPerpetuals[1]
        )
      }
    }
  }

  return (
    <div className="space-y-4">
      <div className="space-x-2">
        <input
          type="radio"
          name="update"
          value="neutral"
          onChange={onSelectUpdateType}
        />
        <label>Delta Neutral</label>
      </div>
      <div className="space-x-2">
        <input
          type="radio"
          name="update"
          value="close"
          onChange={onSelectUpdateType}
        />
        <label>Close Position</label>
      </div>
    </div>
  )
}
