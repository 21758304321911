import React from 'react'

type Props = {
  step: number
}

export const StepBar = ({ step }: Props) => {
  return (
    <div className="flex items-center justify-between w-[270px]">
      {step === 0 ? (
        <>
          <div className="rounded-full bg-third w-5 h-5 text-[10px] flex items-center justify-center">
            1
          </div>
          <div className="bg-third w-32 h-1"></div>
          <div className="bg-third w-32 h-1"></div>
          <div className="rounded-full bg-third w-5 h-5 text-[10px] flex items-center justify-center">
            2
          </div>
        </>
      ) : step === 1 ? (
        <>
          <div className="rounded-full left-gradient w-5 h-5 text-[10px] flex items-center justify-center">
            1
          </div>
          <div className="final-gradient w-32 h-1"></div>
          <div className="bg-third w-32 h-1"></div>
          <div className="rounded-full bg-third w-5 h-5 text-[10px] flex items-center justify-center">
            2
          </div>
        </>
      ) : (
        <>
          <div className="rounded-full left-gradient w-5 h-5 text-[10px] flex items-center justify-center">
            1
          </div>
          <div className="final-gradient w-64 h-1"></div>
          <div className="rounded-full right-gradient w-5 h-5 text-[10px] flex items-center justify-center">
            2
          </div>
        </>
      )}
    </div>
  )
}
