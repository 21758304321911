import { useQuery } from 'react-query'
import { useQuery as useApoloQuery } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useAddresses, useAddressesAnyway } from '../useAddress'
import { PerpetualMarket, PerpetualMarket__factory } from '../../typechain'
import { useChainId, useIsSupportedChain } from '../network'
import { toScaled, toUnscaled } from '../../utils/bn'
import { VaultEntity, VAULT_QUERY } from '../../queries/vaultQuery'
import { PredyClient } from '../../utils/apollo-client'
import { CardType, Product } from '../../constants/enum'
import { decodeMetadata } from '../../utils/metadata'
import { getVaultLiquidationPrice } from '../../utils/liquidationPrice'
import {
  getDeltaOfFuture,
  getDeltaOfSquared,
  getGamma,
  getTheta,
  getVega
} from '../../utils/bs'
import { useCurrentEthPrice } from '../usePrice'
import { useBlockNumber } from '../blockNumber'
import { useEffect, useState } from 'react'
import {
  calculateMinCollateral,
  calculatePositionValue,
  getVaultPositions
} from '../../utils/helpers/vaultHelpers'
import { PoolStatus, usePoolStatus } from './pool/usePoolStatus'
import { getTradePrices } from '../../wrapper'
import { useMinMargin } from './useMinCollateral'
import { Multicall, Multicall__factory } from '../../typechain/multicall'
import { useTradePrice } from './useTradePrice'

export type SubVaultStatus = {
  subVaultIndex: number
  cardType: CardType
  tokenId?: number
  isMetadataLoaded: boolean
  positionPerpetuals: number[]
  tradePrices: number[]
  entryPrices: number[]
  entryFundingFee: number[]
  positionValues: number[]
  fundingPaids: number[]
  deltas: number[]
  gammas: number[]
  vegas: number[]
  thetas: number[]
}

export type VaultStatus = {
  vaultId: number
  positionValue: number
  minCollateral: number
  positionUsdc: number
  amounts: number[]
  subVaults: SubVaultStatus[]
  netStatus: SubVaultStatus
  isInsolvent: boolean
  liquidationPrices: number[]
  isSubVaultsLoaded: boolean
}

function sumPosition(
  subVaults: { positionPerpetuals: BigNumber[] }[],
  productId: number
) {
  return subVaults.reduce(
    (acc: number, subVault: any) =>
      acc + toUnscaled(subVault.positionPerpetuals[productId], 8),
    0
  )
}

/**
 * Calculates vault status in local
 */
async function calculateVaultStatusInLocal(
  contract: PerpetualMarket,
  vaultId: number,
  vaultEntity: VaultEntity,
  ethPrice: number,
  poolStatuses: PoolStatus[],
  minMargin: number
): Promise<VaultStatus> {
  const vault = await contract.getTraderVault(vaultId)

  const amounts = [
    sumPosition(vault.subVaults, Product.FUTURE),
    sumPosition(vault.subVaults, Product.SQUARED)
  ]

  const minCollateral = calculateMinCollateral(
    amounts,
    0,
    0,
    ethPrice,
    minMargin
  )

  const subVaults = vault.subVaults.map((subVault, subVaultIndex) => {
    const metadataStr = vaultEntity.subVaults
      .filter(subVault => Number(subVault.subVaultId) === subVaultIndex)
      .map(subVault => subVault.metadata)
    let metadata: { card: CardType; tokenId?: number } = {
      card: CardType.PRO,
      tokenId: 0
    }
    try {
      metadata = decodeMetadata(metadataStr[0] || '0x')
    } catch (e) {
      console.warn(e)
    }
    return {
      subVaultIndex,
      cardType: metadata.card,
      tokenId: metadata.tokenId,
      isMetadataLoaded: metadataStr.length > 0 && metadataStr[0] !== '0x',
      positionPerpetuals: subVault.positionPerpetuals.map(p =>
        toUnscaled(p, 8)
      ),
      tradePrices: [0, 0],
      entryPrices: subVault.entryPrices.map(p => toUnscaled(p, 8)),
      entryFundingFee: subVault.entryFundingFee.map(p => toUnscaled(p, 16)),
      positionValues: [0, 0],
      fundingPaids: [0, 0],
      deltas: [0, 0],
      gammas: [0, 0],
      vegas: [0, 0],
      thetas: [0, 0]
    }
  })

  const vaultStatus = {
    vaultId,
    amounts,
    positionValue: 0,
    minCollateral,
    positionUsdc: toUnscaled(vault.positionUsdc, 8),
    subVaults,
    netStatus: calculateNetStatus(subVaults, [0, 0]),
    isInsolvent: false,
    liquidationPrices: [],
    isSubVaultsLoaded: isSubVaultsLoaded(subVaults)
  }

  const vaultPosition = getVaultPositions(vaultStatus)

  vaultStatus.positionValue = calculatePositionValue(
    vaultPosition.positions,
    vaultPosition.entryPrices,
    ethPrice,
    (ethPrice * ethPrice) / 10000,
    vaultPosition.entryFundingFees,
    poolStatuses[Product.FUTURE].amountFundingPaidPerPosition,
    poolStatuses[Product.SQUARED].amountFundingPaidPerPosition,
    vaultStatus.positionUsdc
  )

  return vaultStatus
}

async function getVaultStatus(
  vaultEntity: VaultEntity,
  multicall: Multicall,
  contract: PerpetualMarket,
  vaultId: number,
  ethPrice: number,
  poolStatuses: PoolStatus[],
  minMargin: number,
  currentFundingRates: [number, number]
): Promise<VaultStatus> {
  let rawVaultStatus
  try {
    rawVaultStatus = await contract.getVaultStatus(vaultId)
  } catch (e) {
    return calculateVaultStatusInLocal(
      contract,
      vaultId,
      vaultEntity,
      ethPrice,
      poolStatuses,
      minMargin
    )
  }

  const positionValues = rawVaultStatus.positionValues
  const fundingPaids = rawVaultStatus.fundingPaid

  const subVaults = await Promise.all(
    rawVaultStatus.rawVaultData.subVaults.map(
      async (subVault, subVaultIndex) => {
        const tradePrices = await getTradePrices(
          multicall,
          contract,
          subVault.positionPerpetuals.map(positionPerpetual =>
            positionPerpetual.mul(-1)
          )
        )

        const metadataStr = vaultEntity.subVaults
          .filter(subVault => Number(subVault.subVaultId) === subVaultIndex)
          .map(subVault => subVault.metadata)
        let metadata: { card: CardType; tokenId?: number } = {
          card: CardType.PRO,
          tokenId: 0
        }
        try {
          metadata = decodeMetadata(metadataStr[0] || '0x')
        } catch (e) {
          console.warn(e)
        }

        // positions
        const positions = subVault.positionPerpetuals.map(positionPerpetual =>
          toUnscaled(positionPerpetual, 8)
        )

        return {
          subVaultIndex,
          cardType: metadata.card,
          tokenId: metadata.tokenId,
          isMetadataLoaded: metadataStr.length > 0 && metadataStr[0] !== '0x',
          tradePrices: tradePrices.map(tradePrice => tradePrice.tradePrice),
          positionPerpetuals: positions,
          entryPrices: subVault.entryPrices.map(entryPrices =>
            toUnscaled(entryPrices, 8)
          ),
          entryFundingFee: subVault.entryFundingFee.map(entryFundingFee =>
            toUnscaled(entryFundingFee, 16)
          ),
          positionValues: positionValues[subVaultIndex].map(positionValue =>
            toUnscaled(positionValue, 8)
          ),
          fundingPaids: fundingPaids[subVaultIndex].map(fundingPaid =>
            toUnscaled(fundingPaid, 8)
          ),
          deltas: [
            getDeltaOfFuture(positions[0], currentFundingRates[0]),
            getDeltaOfSquared(positions[1], currentFundingRates[1], ethPrice)
          ],
          gammas: [0, getGamma(positions[1], currentFundingRates[1])],
          vegas: [0, getVega(positions[1], currentFundingRates[1])],
          thetas: [
            getTheta(
              positions[0],
              tradePrices[0].tradePrice,
              currentFundingRates[0]
            ),
            getTheta(
              positions[1],
              tradePrices[1].tradePrice,
              currentFundingRates[1]
            )
          ]
        }
      }
    )
  )

  const amounts = [
    sumPosition(rawVaultStatus.rawVaultData.subVaults, Product.FUTURE),
    sumPosition(rawVaultStatus.rawVaultData.subVaults, Product.SQUARED)
  ]

  const tradePrices = await getTradePrices(multicall, contract, [
    toScaled(-amounts[0], 8),
    toScaled(-amounts[1], 8)
  ])

  let liquidationPrices: number[] = []

  const vaultStatus = {
    vaultId: Number(vaultId),
    amounts,
    positionValue: toUnscaled(rawVaultStatus.positionValue, 8),
    minCollateral: toUnscaled(rawVaultStatus.minCollateral, 8),
    positionUsdc: toUnscaled(rawVaultStatus.rawVaultData.positionUsdc, 8),
    subVaults,
    netStatus: calculateNetStatus(subVaults, [
      tradePrices[0].tradePrice,
      tradePrices[1].tradePrice
    ]),
    isInsolvent: false,
    liquidationPrices: [],
    isSubVaultsLoaded: isSubVaultsLoaded(subVaults)
  }

  if (tradePrices[0].isAvailable && tradePrices[1].isAvailable) {
    liquidationPrices = getVaultLiquidationPrice(
      vaultStatus,
      tradePrices[0].tradePrice,
      tradePrices[1].tradePrice,
      tradePrices[0].fundingRate,
      tradePrices[1].fundingRate,
      minMargin
    )
  }

  return { ...vaultStatus, liquidationPrices }
}

function calculateNetStatus(
  subVaults: SubVaultStatus[],
  tradePrices: number[]
): SubVaultStatus {
  return subVaults.reduce(
    (acc, s) => {
      return {
        cardType: CardType.NONE,
        isMetadataLoaded: true,
        subVaultIndex: -1,
        positionPerpetuals: acc.positionPerpetuals.map(
          (p, i) => p + s.positionPerpetuals[i]
        ),
        positionValues: acc.positionValues.map(
          (p, i) => p + s.positionValues[i]
        ),
        tradePrices,
        entryPrices: acc.entryPrices.map((e, i) =>
          acc.positionPerpetuals[i] === 0 && s.positionPerpetuals[i] === 0
            ? 0
            : (e * acc.positionPerpetuals[i] +
                s.entryPrices[i] * s.positionPerpetuals[i]) /
              (acc.positionPerpetuals[i] + s.positionPerpetuals[i])
        ),
        entryFundingFee: acc.entryFundingFee.map((e, i) =>
          acc.entryFundingFee[i] === 0 && s.entryFundingFee[i] === 0
            ? 0
            : (e * acc.positionPerpetuals[i] +
                s.entryFundingFee[i] * s.positionPerpetuals[i]) /
              (acc.positionPerpetuals[i] + s.positionPerpetuals[i])
        ),
        fundingPaids: acc.fundingPaids.map((p, i) => p + s.fundingPaids[i]),
        deltas: acc.deltas.map((p, i) => p + s.deltas[i]),
        gammas: acc.gammas.map((p, i) => p + s.gammas[i]),
        vegas: acc.vegas.map((p, i) => p + s.vegas[i]),
        thetas: acc.thetas.map((p, i) => p + s.thetas[i])
      }
    },
    {
      cardType: CardType.NONE,
      isMetadataLoaded: true,
      subVaultIndex: -1,
      positionPerpetuals: [0, 0],
      tradePrices,
      entryPrices: [0, 0],
      entryFundingFee: [0, 0],
      positionValues: [0, 0],
      fundingPaids: [0, 0],
      deltas: [0, 0],
      gammas: [0, 0],
      vegas: [0, 0],
      thetas: [0, 0]
    }
  )
}

function isSubVaultsLoaded(subVaults: SubVaultStatus[]) {
  return subVaults.reduce(
    (acc, i) =>
      acc &&
      (i.isMetadataLoaded ||
        (i.positionPerpetuals[0] === 0 && i.positionPerpetuals[1] === 0)),
    true
  )
}

export function useVaultStatus(assetIndex: number, vaultId: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const ethPrice = useCurrentEthPrice()
  const blockNumber = useBlockNumber()
  const poolStatus = usePoolStatus(assetIndex)
  const baseVaultInfo = useBaseVaultInfo(assetIndex, vaultId)
  const minMargin = useMinMargin(assetIndex)
  const currentFutureTradePrice = useTradePrice(
    assetIndex,
    Product.FUTURE,
    [0, 0]
  )
  const currentSquaredTradePrice = useTradePrice(
    assetIndex,
    Product.SQUARED,
    [0, 0]
  )

  const [vaultStatusState, setVaultStatus] = useState<{
    isLoaded: boolean
    data: VaultStatus | null
    refetch: () => void
  }>({
    isLoaded: vaultId === 0,
    data: null,
    refetch: () => {
      return
    }
  })

  const vaultStatusQuery = useQuery(
    ['vault', chainId, assetIndex, vaultId, blockNumber],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')
      if (!ethPrice.isSuccess) throw new Error('ethPrice not loaded')
      if (!baseVaultInfo) throw new Error('baseVaultInfo not set')
      if (!poolStatus.isSuccess) throw new Error('poolStatus not set')
      if (!minMargin.isSuccess) throw new Error('minMargin not set')
      if (!currentFutureTradePrice.isSuccess)
        throw new Error('currentFutureTradePrice not set')
      if (!currentSquaredTradePrice.isSuccess)
        throw new Error('currentSquaredTradePrice not set')

      const contract = PerpetualMarket__factory.connect(
        addresses.Perpetuals[assetIndex].PerpetualMarket,
        library
      )
      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        library
      )
      const vaultStatus: VaultStatus = await getVaultStatus(
        baseVaultInfo,
        multicall,
        contract,
        vaultId,
        ethPrice.data,
        poolStatus.data.status,
        minMargin.data,
        [
          currentFutureTradePrice.data.fundingRate,
          currentSquaredTradePrice.data.fundingRate
        ]
      )

      return vaultStatus
    },
    {
      enabled:
        supportedChain &&
        !!library &&
        !!addresses &&
        ethPrice.isSuccess &&
        !!baseVaultInfo &&
        poolStatus.isSuccess &&
        minMargin.isSuccess &&
        currentFutureTradePrice.isSuccess &&
        currentSquaredTradePrice.isSuccess
    }
  )

  useEffect(() => {
    if (vaultStatusQuery.isSuccess) {
      setVaultStatus({
        isLoaded: true,
        data: vaultStatusQuery.data,
        refetch: () => vaultStatusQuery.refetch()
      })
    }
  }, [vaultStatusQuery.isSuccess, vaultStatusQuery.data])

  return vaultStatusState
}

export function useBaseVaultInfo(assetIndex: number, vaultId: number) {
  const addresses = useAddressesAnyway()
  const assetId =
    addresses.Perpetuals[assetIndex].PerpetualMarket.toLocaleLowerCase()
  const chainId = useChainId()

  const { data } = useApoloQuery<{ vaultEntities: VaultEntity[] }>(
    VAULT_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      client: PredyClient[chainId],
      variables: {
        id: vaultId.toString(),
        assetId: assetId
      },
      pollInterval: 10000
    }
  )

  return data?.vaultEntities[0]
}
