import React from 'react'
import styled from 'styled-components'
import { useRoute } from 'wouter'
import LiteTradeResult from '../components/trade/LiteTradeResult'
import { getAssetIndex } from '../utils/asset'
import modalStore from '../store/modal'
import vaultStore from '../store/vault'
import banner from '../assets/banner.svg'
import CardList from '../components/CardList'
import SpotPnLChart from '../components/chart/SpotPnLChart'
import { useLatestVaultId } from '../hooks/query/useVaultIds'

const LiteTradeView = () => {
  const params = useRoute('/home/:asset')[1]
  const { vaultId } = vaultStore()
  const latestVaultId = useLatestVaultId()
  const { isOpen, setOpen } = modalStore()

  return (
    <div className="grid md:grid-cols-lite gap-4 max-w-5xl mx-auto">
      <div className="col-span-1 px-2 md:px-0">
        <div className="relative hidden md:block">
          <img className="w-full rounded-lg" src={banner} />
          <BannerCenter className="absolute top-[50%] left-[50%] text-white text-center md:w-[620px]">
            <p className="text-[25px] font-[600] mb-2">
              Trade, Hedge, and Earn with Derivative instantly!
            </p>
            <p className="text-[13px] font-[500]">
              Power perpetual on the most capital effieient pVault connected
              AMM.
            </p>
          </BannerCenter>
        </div>
        <div className="my-4 relative">
          <CardList />

          {isOpen ? (
            <div className="absolute z-10 w-full h-full">
              <div className="h-full flex items-center justify-center">
                <div
                  className="fixed backdrop-blur-sm bg-black/70 left-0 top-0 w-full h-full"
                  onClick={() => {
                    setOpen(false)
                  }}
                ></div>
                <div className="absolute z-20 bg-secondaly -top-[480px] w-[666px] rounded-lg pointer-events-auto">
                  <SpotPnLChart
                    asset={params?.asset || 'eth'}
                    vaultId={vaultId}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <div className="col-span-1 z-20 px-2 md:px-0">
        <LiteTradeResult
          asset={getAssetIndex(params?.asset)}
          isLoading={latestVaultId.isLoading}
          vaultId={vaultId}
        />
      </div>
    </div>
  )
}

const BannerCenter = styled.div`
  transform: translate(-50%, -50%);
`

export default LiteTradeView
