import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from '../network'
import {
  ActivePositionsEntities,
  ACTIVE_POSITIONS_QUERY,
  PositionEntity,
  POSITION_QUERY
} from '../../queries/uniswap/positionQuery'
import { UNI_ETH_USDC_POOL_ADDRESS } from '../../constants/addresses'
import { POOL_ENTITY, POOL_QUERY } from '../../queries/uniswap/poolQuery'
import {
  getGreeksFromUniLPPosition,
  getPriceFromTick
} from '../../utils/uniswap'
import { toScaled } from '../../utils/bn'
import { UniswapClient } from '../../utils/apollo-client'
import { useCurrentEthPrice } from '../usePrice'

export type UniActivePosition = {
  id: number
  delta: number
  gamma: number
  squaredAmount: number
  futureAmount: number
  lowerPrice: number
  upperPrice: number
}

export function useUniswapPositions() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()

  return useQuery(
    ['uniswap_token_ids', chainId, account],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')

      const client = UniswapClient[chainId]

      const response1: ActivePositionsEntities = await client.query({
        query: ACTIVE_POSITIONS_QUERY,
        variables: {
          poolAddress: UNI_ETH_USDC_POOL_ADDRESS[0],
          owner: account.toLowerCase()
        }
      })
      const response2: ActivePositionsEntities = await client.query({
        query: ACTIVE_POSITIONS_QUERY,
        variables: {
          poolAddress: UNI_ETH_USDC_POOL_ADDRESS[1],
          owner: account.toLowerCase()
        }
      })

      const tokenIds: number[] = response1.data.positions
        .concat(response2.data.positions)
        .map(position => {
          return Number(position.id)
        })

      return tokenIds
    },
    {
      enabled: supportedChain && !!library
    }
  )
}

export function useUniswapPosition(tokenId: number) {
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const ethPrice = useCurrentEthPrice()

  return useQuery(
    ['uniswap_position', chainId, tokenId],
    async () => {
      if (!ethPrice.isSuccess) throw new Error('price is not loaded')

      const client = UniswapClient[chainId]

      const response1: PositionEntity = await client.query({
        query: POSITION_QUERY,
        variables: {
          id: tokenId.toString()
        }
      })
      const response2: PositionEntity = await client.query({
        query: POSITION_QUERY,
        variables: {
          id: tokenId.toString()
        }
      })

      const poolEntity1: POOL_ENTITY = await client.query({
        query: POOL_QUERY,
        variables: {
          poolAddress: UNI_ETH_USDC_POOL_ADDRESS[0]
        }
      })

      const poolEntity2: POOL_ENTITY = await client.query({
        query: POOL_QUERY,
        variables: {
          poolAddress: UNI_ETH_USDC_POOL_ADDRESS[1]
        }
      })

      const position = response1.data.position || response2.data.position

      let pl = getPriceFromTick(Number(position.tickLower.tickIdx), '6', '18')
      let pu = getPriceFromTick(Number(position.tickUpper.tickIdx), '6', '18')
      if (pl > pu) {
        const t = pl
        pl = pu
        pu = t
      }

      const greeks = getGreeksFromUniLPPosition(
        BigNumber.from(position.liquidity).div(toScaled(1, 12)).toNumber(),
        Number(
          position.pool.id === poolEntity1.data.pool.id
            ? poolEntity1.data.pool.token0Price
            : poolEntity2.data.pool.token0Price
        ),
        pu
      )

      const squaredAmount = (greeks.gamma * 10000) / 2
      const futureAmount = -(
        greeks.delta +
        (2 * ethPrice.data * squaredAmount) / 10000
      )

      const uniActivePositions: UniActivePosition = {
        id: Number(position.id),
        delta: greeks.delta,
        gamma: greeks.gamma,
        squaredAmount,
        futureAmount,
        lowerPrice: pl,
        upperPrice: pu
      }
      return uniActivePositions
    },
    {
      enabled: supportedChain && ethPrice.isSuccess && tokenId > 0
    }
  )
}
