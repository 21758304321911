import { Product } from '../constants/enum'
import { toAmountString } from './number'

export function productToString(product: Product) {
  if (product === Product.FUTURE) return 'ETH'
  else if (product === Product.SQUARED) return 'ETH2'
  return 'Undefined'
}

export function getSymbol(product: Product) {
  if (product === Product.FUTURE) return 'ETH-PERP-USDC'
  else if (product === Product.SQUARED) return 'ETH2-PERP-USDC'
  return 'Undefined'
}

export function getAmount(amount: number) {
  return `${amount === 0 ? '' : amount > 0 ? 'Long' : 'Short'} ${toAmountString(
    Math.abs(amount)
  )}`
}
