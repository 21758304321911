import React from 'react'
import { useAddressesAnyway } from '../../hooks/useAddress'
import { DEFAULT_DEADLINE_FROM_NOW } from '../../constants'
import { toScaled } from '../../utils/bn'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { useTradeResult } from '../../hooks/query/useTradeResult'
import { useTrade } from '../../hooks/contracts/useTrade'
import { applySlippageTorelance } from '../../utils/slippage'
import modalStore from '../../store/modal'
import tradeStore from '../../store/trade'
import pendingStore from '../../store/pending'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import { CardType, Product } from '../../constants/enum'
import iconETH from '../../assets/icons/Icon_ETH.svg'
import iconETH2 from '../../assets/icons/Icon_ETH2.svg'
import iconMagnifyingGlass from '../../assets/MagnifyingGlass.png'
import iconCross from '../../assets/Cross.png'
import HorizontalRule from '../common/HorizontalRule'
import { PositionForm } from './AmountForm'
import { MarginForm } from './forms/MarginForm'
import { getTradeType, TradeButtonArea } from './TradeButtonArea'
import { getDeltaOfFuture, getDeltaOfSquared, getGamma } from '../../utils/bs'
import { useCurrentEthPrice } from '../../hooks/usePrice'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import {
  toFundingRateString,
  toGreeksString,
  toIndexPriceString,
  toPriceString
} from '../../utils/number'
import mixpanel from '../../mixpanel'
import { encodeMetadata } from '../../utils/metadata'
import { getSubVaultIdWithCardType } from '../../utils/helpers/subVaultHelper'
import { useL1BlockNumber } from '../../hooks/blockNumber'

type Props = {
  asset: number
  isLoading: boolean
  vaultId: number
}

const TradeResult = ({ asset, isLoading, vaultId }: Props) => {
  const { fields, setFields } = tradeStore()
  const { setOpen } = modalStore()
  const { setPendingApproval, setPendingTx } = pendingStore()

  const addresses = useAddressesAnyway()

  const balanceQuery = useERC20BalanceQuery(addresses.QuoteToken)
  const allowanceQuery = useAllowanceQuery(
    addresses.Perpetuals ? addresses.Perpetuals[asset].PerpetualMarket : '',
    addresses.QuoteToken
  )

  const vaultStatus = useVaultStatus(asset, vaultId)
  const tradeResult = useTradeResult(
    asset,
    vaultId,
    vaultStatus.data
      ? getSubVaultIdWithCardType(vaultStatus.data, CardType.PRO)
      : 0,
    [fields.tradeAmount0, fields.tradeAmount1],
    fields.marginAmount
  )

  const ethPrice = useCurrentEthPrice()
  const l1BlockNumber = useL1BlockNumber()

  const approve = useApproveMutation()
  const trade = useTrade(asset)

  const onApprove = async () => {
    const tx = await approve.mutateAsync({
      address: addresses.QuoteToken,
      spender: addresses.Perpetuals[asset].PerpetualMarket,
      amount: toScaled(fields.marginAmount, 6)
    })

    // track approve
    mixpanel.track('approve', {
      mode: 'pro',
      vaultId
    })

    // Set pending
    await setPendingApproval(tx)
  }

  const onTrade = async () => {
    const trades = []

    if (!tradeResult || isLoading) {
      return
    }

    const subVaultIndex = vaultStatus.data
      ? getSubVaultIdWithCardType(vaultStatus.data, CardType.PRO)
      : 0

    if (fields.tradeAmount0 !== 0) {
      trades.push({
        productId: Product.FUTURE,
        subVaultIndex,
        tradeAmount: toScaled(fields.tradeAmount0, 8),
        limitPrice: toScaled(
          applySlippageTorelance(
            tradeResult.future.tradePrice,
            fields.tradeAmount0 > 0
          ),
          8
        ),
        metadata: encodeMetadata(CardType.PRO, 0)
      })
    }

    if (fields.tradeAmount1 !== 0) {
      trades.push({
        productId: Product.SQUARED,
        subVaultIndex,
        tradeAmount: toScaled(fields.tradeAmount1, 8),
        limitPrice: toScaled(
          applySlippageTorelance(
            tradeResult.squared.tradePrice,
            fields.tradeAmount1 > 0
          ),
          8
        ),
        metadata: encodeMetadata(CardType.PRO, 0)
      })
    }

    const tx = await trade.mutateAsync({
      vaultId,
      trades,
      marginAmount: toScaled(fields.marginAmount || 0, 6),
      deadline: l1BlockNumber + DEFAULT_DEADLINE_FROM_NOW
    })

    // clear
    setFields({ tradeAmount0: 0, tradeAmount1: 0, marginAmount: 0 })

    // track trade
    mixpanel.track('trade', {
      mode: 'pro',
      vaultId
    })

    // Set pending
    await setPendingTx(tx)
  }

  const hasEnoughUSDC = () => {
    return balanceQuery.gte(toScaled(fields.marginAmount || 0, 6))
  }

  const isEnoughUSDCApproved = () => {
    return allowanceQuery.gte(toScaled(fields.marginAmount || 0, 6))
  }

  const isInsufficientLiquidityForThisTrade = () => {
    return (
      !!tradeResult &&
      (!tradeResult.future.isAvailable || !tradeResult.squared.isAvailable)
    )
  }

  return (
    <div className="rounded-lg bg-secondaly w-full p-6 px-8">
      <div className="pt-2">
        <h3 className="text-xl">Select Amount</h3>
        <p className="text-[13px]">
          You have to select item first to see details
        </p>
      </div>
      <HorizontalRule />
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <img src={iconETH2} className="-mt-[7px] h-[58px]"></img>
            <div>
              <p>
                (ETH)<sup className="text-xs">2</sup>
              </p>
              <p className="text-xs text-subtext">
                ${toIndexPriceString(Math.pow(ethPrice.data || 0, 2))}/10,000
              </p>
            </div>
          </div>
          <div className="flex space-x-2">
            <img
              className="cursor-pointer"
              src={iconMagnifyingGlass}
              onClick={() => {
                setOpen(true)
              }}
            />
            <img
              className="cursor-pointer"
              src={iconCross}
              onClick={() => {
                setOpen(false)
              }}
            />
          </div>
        </div>
        <PositionForm
          amount={fields.tradeAmount1}
          onChange={amount => {
            setFields({ ...fields, tradeAmount1: amount })
          }}
          step={1}
          name={'tradeAmount1'}
        />
        <div className="space-y-2 text-xs">
          <div className="flex justify-between">
            <p>Entry Price:</p>
            <p>
              ${tradeResult ? toPriceString(tradeResult.squared.tradePrice) : 0}
            </p>
          </div>
          <div className="flex justify-between">
            <p>Estimated Funding Rate:</p>
            <p>
              {tradeResult
                ? toFundingRateString(tradeResult.squared.estimatedFundingRate)
                : 0}
              %
            </p>
          </div>
          <div className="flex justify-between">
            <p>Delta, Gamma:</p>
            <p>
              {toGreeksString(
                getDeltaOfSquared(
                  fields.tradeAmount1,
                  tradeResult?.squared.fundingRate || 0,
                  ethPrice.data || 0
                )
              )}
              ,{' '}
              {toGreeksString(
                getGamma(
                  fields.tradeAmount1,
                  tradeResult?.squared.fundingRate || 0
                )
              )}
            </p>
          </div>
        </div>
      </div>
      <HorizontalRule />
      <div className="space-y-4">
        <div className="flex flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <img src={iconETH} className="h-[50px]"></img>
            <div>
              <p>ETH</p>
              <p className="text-xs text-subtext">
                ${toPriceString(ethPrice.data || 0)}
              </p>
            </div>
          </div>
          <div className="flex space-x-2">
            <img
              className="cursor-pointer"
              src={iconMagnifyingGlass}
              onClick={() => {
                setOpen(true)
              }}
            />
            <img
              className="cursor-pointer"
              src={iconCross}
              onClick={() => {
                setOpen(false)
              }}
            />
          </div>
        </div>
        <div className="m-0">
          <PositionForm
            amount={fields.tradeAmount0}
            onChange={amount => {
              setFields({ ...fields, tradeAmount0: amount })
            }}
            step={1}
            name={'tradeAmount0'}
          />
        </div>
        <div className="space-y-2 text-xs">
          <div className="flex justify-between">
            <p>Entry Price:</p>
            <p>
              ${tradeResult ? toPriceString(tradeResult.future.tradePrice) : 0}
            </p>
          </div>
          <div className="flex justify-between">
            <p>Estimated Funding Rate:</p>
            <p>
              {tradeResult
                ? toFundingRateString(tradeResult.future.estimatedFundingRate)
                : 0}
              %
            </p>
          </div>
          <div className="flex justify-between">
            <p>Delta, Gamma:</p>
            <p>
              {toGreeksString(
                getDeltaOfFuture(
                  fields.tradeAmount0,
                  tradeResult?.future.fundingRate || 0
                )
              )}
              , 0
            </p>
          </div>
        </div>
      </div>
      <HorizontalRule />
      <MarginForm
        marginAmount={fields.marginAmount}
        requiredMargin={tradeResult?.requiredMargin || 0}
        marginAmountInVault={vaultStatus.data?.positionUsdc || 0}
        onChange={amount => {
          setFields({ ...fields, marginAmount: amount })
        }}
        minCollateral={tradeResult ? tradeResult.minCollateral : 0}
        liquidationPrices={tradeResult ? tradeResult.liquidationPrices : []}
      />
      <HorizontalRule />
      <TradeButtonArea
        marginAmount={fields.marginAmount}
        onApprove={onApprove}
        onTrade={onTrade}
        hasEnoughUSDC={hasEnoughUSDC()}
        isEnoughUSDCApproved={isEnoughUSDCApproved()}
        insufficientLiquidity={isInsufficientLiquidityForThisTrade()}
        isMarginEnough={tradeResult ? tradeResult.isMarginEnough : true}
        isExceededSoftLimit={
          tradeResult ? tradeResult.isExceededSoftLimit : false
        }
        isExceededPoolLimit={
          false
          //tradeResult ? tradeResult.isExceededPoolLimit : false
        }
        isExceededDeltaLimit={
          tradeResult ? tradeResult.isExceededDeltaLimit : false
        }
        tradeType={getTradeType(fields, false)}
        isSubVaultsLoaded={true}
        isTradeAmountsZero={
          fields.tradeAmount0 === 0 &&
          fields.tradeAmount1 === 0 &&
          fields.marginAmount === 0
        }
      />
    </div>
  )
}

export default TradeResult
