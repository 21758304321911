import React, { useEffect, useMemo, useState } from 'react'
import { Router, Route, Redirect, Switch, useLocation } from 'wouter'
import localforage from 'localforage'

import { useEagerConnect } from './hooks/wallet'
import AgreementView from './pages/AgreementView'
import ProView from './pages/ProView'
import LiteTradeView from './pages/LiteTradeView'
import LitePositionView from './pages/LitePositionView'
import StatusView from './pages/StatusView'
import PoolView from './pages/PoolView'
import RankingView from './pages/RankingView'

import LoadingIndicator from './components/common/LoadingIndicator'
import NetworkErrorLabel from './components/common/NetworkErrorLabel'
import Header from './components/header/Header'
import { ApolloProvider } from '@apollo/client'
import { PredyClient } from './utils/apollo-client'
import { DEFAULT_CHAIN } from './constants'
import { useChainId } from './hooks/network'

const App = () => {
  const [loaded, setLoaded] = useState(false)
  const setLocation = useLocation()[1]

  const chainId = useChainId()

  const client = useMemo(() => PredyClient[chainId] || PredyClient[DEFAULT_CHAIN], [chainId])

  useEffect(() => {
    ; (async () => {
      // check residence once. if value is set to true, proceed
      const value = await localforage.getItem('residence')
      if (!value) {
        setLocation('/agreement')
      }
      setLoaded(true)
    })()
    // eslint-disable-next-line
  }, [])

  const tried = useEagerConnect()
  if (!tried || !loaded) return <LoadingIndicator />

  return (
    <ApolloProvider client={client}>
      <div className="bg-background">
        <NetworkErrorLabel />
        <Router>
          <Switch>
            <Route path="/agreement" component={AgreementView} />
            <Route>
              {() => (
                <>
                  <Header />
                  <div className="container mx-auto mt-4">
                    <Route path="/">
                      <Redirect to="/lite/trade/eth" />
                    </Route>
                    <Route path="/pro/:asset" component={ProView} />
                    <Route path="/lite/trade/:asset" component={LiteTradeView} />
                    <Route path="/lite/position/:asset" component={LitePositionView} />
                    <Route path="/status/:asset" component={StatusView} />
                    <Route path="/pool/:asset" component={PoolView} />
                    <Route path="/ranking/:asset/:competition" component={RankingView} />
                  </div>
                </>
              )}
            </Route>
          </Switch>
        </Router>
      </div>
    </ApolloProvider>
  )
}

export default App
