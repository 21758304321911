/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import { Provider } from '@ethersproject/providers'
import type {
  IPerpetualMarket,
  IPerpetualMarketInterface
} from '../IPerpetualMarket'

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: '_marginToAdd',
        type: 'int256'
      }
    ],
    name: 'addMargin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_depositAmount',
        type: 'uint256'
      }
    ],
    name: 'deposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'execHedge',
    outputs: [
      {
        internalType: 'uint256',
        name: 'amountUsdc',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amountUnderlying',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'int256',
        name: '_deltaLiquidityAmount',
        type: 'int256'
      }
    ],
    name: 'getLPTokenPrice',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      },
      {
        internalType: 'int128[2]',
        name: '_tradeAmounts',
        type: 'int128[2]'
      }
    ],
    name: 'getMinCollateralToAddPosition',
    outputs: [
      {
        internalType: 'int256',
        name: 'minCollateral',
        type: 'int256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getTokenAmountForHedging',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_productId',
        type: 'uint256'
      },
      {
        internalType: 'int128',
        name: '_tradeAmount',
        type: 'int128'
      }
    ],
    name: 'getTradePrice',
    outputs: [
      {
        components: [
          {
            internalType: 'int256',
            name: 'tradePrice',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'indexPrice',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'fundingRate',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'tradeFee',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'protocolFee',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'fundingFee',
            type: 'int256'
          },
          {
            internalType: 'uint256',
            name: 'totalValue',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'totalFee',
            type: 'uint256'
          }
        ],
        internalType: 'struct IPerpetualMarket.TradeInfo',
        name: 'tradePriceInfo',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      }
    ],
    name: 'getTraderVault',
    outputs: [
      {
        components: [
          {
            internalType: 'int128',
            name: 'positionUsdc',
            type: 'int128'
          },
          {
            components: [
              {
                internalType: 'int128[2]',
                name: 'positionPerpetuals',
                type: 'int128[2]'
              },
              {
                internalType: 'uint128[2]',
                name: 'entryPrices',
                type: 'uint128[2]'
              },
              {
                internalType: 'int256[2]',
                name: 'entryFundingFee',
                type: 'int256[2]'
              }
            ],
            internalType: 'struct TraderVaultLib.SubVault[]',
            name: 'subVaults',
            type: 'tuple[]'
          },
          {
            internalType: 'bool',
            name: 'isInsolvent',
            type: 'bool'
          }
        ],
        internalType: 'struct TraderVaultLib.TraderVault',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      }
    ],
    name: 'getVaultStatus',
    outputs: [
      {
        components: [
          {
            internalType: 'int256',
            name: 'positionValue',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'minCollateral',
            type: 'int256'
          },
          {
            internalType: 'int256[2][]',
            name: 'positionValues',
            type: 'int256[2][]'
          },
          {
            internalType: 'int256[2][]',
            name: 'fundingPaid',
            type: 'int256[2][]'
          },
          {
            components: [
              {
                internalType: 'int128',
                name: 'positionUsdc',
                type: 'int128'
              },
              {
                components: [
                  {
                    internalType: 'int128[2]',
                    name: 'positionPerpetuals',
                    type: 'int128[2]'
                  },
                  {
                    internalType: 'uint128[2]',
                    name: 'entryPrices',
                    type: 'uint128[2]'
                  },
                  {
                    internalType: 'int256[2]',
                    name: 'entryFundingFee',
                    type: 'int256[2]'
                  }
                ],
                internalType: 'struct TraderVaultLib.SubVault[]',
                name: 'subVaults',
                type: 'tuple[]'
              },
              {
                internalType: 'bool',
                name: 'isInsolvent',
                type: 'bool'
              }
            ],
            internalType: 'struct TraderVaultLib.TraderVault',
            name: 'rawVaultData',
            type: 'tuple'
          }
        ],
        internalType: 'struct IPerpetualMarket.VaultStatus',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_depositAmount',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: '_initialFundingRate',
        type: 'int256'
      }
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      }
    ],
    name: 'liquidateByPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'vaultId',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'productId',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'subVaultIndex',
                type: 'uint256'
              },
              {
                internalType: 'int128',
                name: 'tradeAmount',
                type: 'int128'
              },
              {
                internalType: 'uint256',
                name: 'limitPrice',
                type: 'uint256'
              },
              {
                internalType: 'bytes',
                name: 'metadata',
                type: 'bytes'
              }
            ],
            internalType: 'struct IPerpetualMarket.TradeParams[]',
            name: 'trades',
            type: 'tuple[]'
          },
          {
            internalType: 'int256',
            name: 'marginAmount',
            type: 'int256'
          },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        internalType: 'struct IPerpetualMarket.MultiTradeParams',
        name: '_tradeParams',
        type: 'tuple'
      }
    ],
    name: 'trade',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint128',
        name: '_withdrawnAmount',
        type: 'uint128'
      }
    ],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export class IPerpetualMarket__factory {
  static readonly abi = _abi
  static createInterface(): IPerpetualMarketInterface {
    return new utils.Interface(_abi) as IPerpetualMarketInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IPerpetualMarket {
    return new Contract(address, _abi, signerOrProvider) as IPerpetualMarket
  }
}
