import { ContractTransaction } from 'ethers'
import create from 'zustand'

interface State {
  isPendingApproval: boolean
  isPendingTx: boolean
  setPendingApproval: (tx: ContractTransaction) => Promise<void>
  setPendingTx: (tx: ContractTransaction) => Promise<void>
}

const useStore = create<State>(set => ({
  isPendingApproval: false,
  isPendingTx: false,
  setPendingApproval: async (tx: ContractTransaction) => {
    set({ isPendingApproval: true })
    await tx.wait()
    set({ isPendingApproval: false })
  },
  setPendingTx: async (tx: ContractTransaction) => {
    set({ isPendingTx: true })
    await tx.wait()
    set({ isPendingTx: false })
  }
}))

export default useStore
