import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Web3ReactProvider } from '@web3-react/core'
import App from './App'
import getLibrary from './utils/getLibrary'
import './index.css'

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
