import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useChainId } from '../../network'
import { toUnscaled } from '../../../utils/bn'
import { useAddresses } from '../../useAddress'
import { PerpetualMarket__factory } from '../../../typechain'

export function useHedgeState(assetIndex: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()

  return useQuery(
    ['hedge_status', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = PerpetualMarket__factory.connect(
        addresses.Perpetuals[assetIndex].PerpetualMarket,
        library
      )

      const tokenAmounts = await contract.getTokenAmountForHedging()
      const vault1Status = await contract.getVaultStatus(1)

      return {
        isLong: tokenAmounts[0],
        usdcAmount: toUnscaled(tokenAmounts[1], 6),
        underlyingAmount: toUnscaled(tokenAmounts[2], 18),
        vault1: {
          positionValue: toUnscaled(vault1Status.positionValue, 8),
          minCollateral: toUnscaled(vault1Status.minCollateral, 8),
          positions: toUnscaled(
            vault1Status.rawVaultData.subVaults[0].positionPerpetuals[0],
            8
          )
        }
      }
    },
    {
      enabled: !!library && !!addresses
    }
  )
}
