import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { SPACE } from '../../constants'
import { injected } from '../../connectors'
import WalletIcon from '../../assets/wallet_icon_white.svg'
import { usePrevious } from 'react-use'
import { switchNetwork } from '../../utils/switchNetwork'
import mixpanel from '../../mixpanel'
import { useIsSupportedChain } from '../../hooks/network'

export const ConnectButton: React.FC = ({ children }) => {
  return (
    <div className="w-40 h-10">
      <BaseConnectButton>{children}</BaseConnectButton>
    </div>
  )
}

export const BaseConnectButton: React.FC = ({ children }) => {
  const { account, active, activate, error } = useWeb3React()
  const [activateClicked, setActivateClicked] = useState(false)
  const prevClicked = usePrevious(activateClicked)
  const supported = useIsSupportedChain()

  useEffect(() => {
    if (!prevClicked && activateClicked && active && account) {
      // To tract user connection, please put code here
      mixpanel.track('connect', {
        address: account,
        clicked: true
      })
    }
  }, [activateClicked, active]) // eslint-disable-line

  const onClick = async () => {
    await switchNetwork()
    await activate(injected)
    setActivateClicked(true)
  }

  if (active && account) {
    return (
      <div className="w-full h-full final-gradient text-white rounded-md flex flex-row">
        <Icon className="basis-1/4" src={WalletIcon} />
        <div className="basis-3/4 my-auto">
          {account.slice(0, 6)}…{account.slice(38)}
        </div>
      </div>
    )
  }

  return (
    <button
      className="w-full h-full final-gradient text-white rounded-md flex justify-center items-center"
      onClick={onClick}
    >
      {children
        ? children
        : !error && supported
        ? 'Connect Wallet'
        : 'Switch Network'}
    </button>
  )
}

const Icon = styled.img`
  margin-right: ${SPACE.SMALL};
`

export default ConnectButton
