import { gql } from '@apollo/client'

export const MARGIN_HISTORY_QUERY = gql`
  query ($startTime: BigInt, $endTime: BigInt, $action: String) {
    tradeHistoryEntities(
      where: {
        action: $action
        timestamp_gt: $startTime
        timestamp_lt: $endTime
      }
    ) {
      id
      txid
      vault {
        vaultId
      }
      action
      trader
      marginAmount
      timestamp
    }
  }
`

export type MarginHistoryEntities = {
  tradeHistoryEntities: {
    id: string
    txid: string
    vault: {
      vaultId: string
    }
    action: string
    trader: string
    marginAmount: string
    timestamp: string
  }[]
}
