import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { PerpetualMarket__factory } from '../../typechain'

export function useDeposit() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()

  return useMutation(async (depositAmount: BigNumber) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = PerpetualMarket__factory.connect(
      addresses.Perpetuals[0].PerpetualMarket,
      library.getSigner()
    )

    return await contract.deposit(depositAmount)
  })
}

export function useWithdraw() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()

  return useMutation(async (withdrawAmount: BigNumber) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = PerpetualMarket__factory.connect(
      addresses.Perpetuals[0].PerpetualMarket,
      library.getSigner()
    )

    return await contract.withdraw(withdrawAmount)
  })
}
