import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { injected } from '../connectors'
import { DEFAULT_CHAIN } from '../constants'
import { Addresses, NETWORKS } from '../constants/addresses'

export function useAddresses() {
  const { library, activate } = useWeb3React<ethers.providers.Web3Provider>()

  // TODO: initial value
  const [addresses, setAddresses] = useState<Addresses | undefined>(
    library && library.network ? NETWORKS[library.network.chainId] : undefined
  )

  useEffect(() => {
    const handleNetworkChanged = (network: ethers.providers.Network) => {
      setAddresses(NETWORKS[network.chainId])
      activate(injected, undefined, true).catch(error => {
        console.error('Failed to activate after chain changed', error)
      })
    }

    library?.on('network', handleNetworkChanged)
    return () => {
      library?.off('network', handleNetworkChanged)
    }
  }, [library?.network, activate, library])

  return addresses
}

export function useAddressesAnyway() {
  return useAddresses() || NETWORKS[DEFAULT_CHAIN]
}
