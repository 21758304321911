import React from 'react'
import { ChartRange } from '../../constants/enum'

type Props = {
  chartRange: ChartRange
  onSelect: (chartRange: ChartRange) => void
}

const TimeRangeSelector = ({ chartRange, onSelect }: Props) => {
  return (
    <div className="space-x-2 text-xs">
      <button
        className={`w-[37px] rounded-lg p-1 ${
          chartRange === ChartRange.Hour ? 'final-gradient' : 'bg-third'
        }`}
        onClick={() => {
          onSelect(ChartRange.Hour)
        }}
      >
        1H
      </button>
      <button
        className={`w-[37px] rounded-lg p-1 ${
          chartRange === ChartRange.Day ? 'final-gradient' : 'bg-third'
        }`}
        onClick={() => {
          onSelect(ChartRange.Day)
        }}
      >
        1D
      </button>
      <button
        className={`w-[37px] rounded-lg p-1 ${
          chartRange === ChartRange.Week ? 'final-gradient' : 'bg-third'
        }`}
        onClick={() => {
          onSelect(ChartRange.Week)
        }}
      >
        1W
      </button>
      <button
        className={`w-[37px] rounded-lg p-1 ${
          chartRange === ChartRange.Month ? 'final-gradient' : 'bg-third'
        }`}
        onClick={() => {
          onSelect(ChartRange.Month)
        }}
      >
        1M
      </button>
      <button
        className={`w-[37px] rounded-lg p-1 ${
          chartRange === ChartRange.Year ? 'final-gradient' : 'bg-third'
        }`}
        onClick={() => {
          onSelect(ChartRange.Year)
        }}
      >
        1Y
      </button>
    </div>
  )
}

export default TimeRangeSelector
