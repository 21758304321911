import { gql } from '@apollo/client'

export const OPENINTEREST = gql`
  query GetOpenInterest($ts: BigInt!, $productId: BigInt!, $tsFilter: BigInt!) {
    openInterestEntities(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: {
        timestamp_gt: $ts
        productId: $productId
        timestamp_lt: $tsFilter
      }
    ) {
      id
      timestamp
      openLong
      openShort
    }
  }
`

export const OPENINTERESTSTARTER = gql`
  query GetLpPrice($ts: BigInt!, $productId: BigInt!, $tsFilter: BigInt!) {
    openInterestEntities(
      first: 1
      orderBy: timestamp
      orderDirection: desc
      where: {
        timestamp_gt: $ts
        productId: $productId
        timestamp_lt: $tsFilter
      }
    ) {
      id
      timestamp
      openLong
      openShort
    }
  }
`

export type OpenInterestHistoryEntity = {
  id: string
  timestamp: number
  openLong: number
  openShort: number
}
