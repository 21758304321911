import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { TradeType } from '../../constants/enum'
import { ERRORS } from '../../constants/messages'
import { TradeFormData } from '../../store/trade'
import pendingStore from '../../store/pending'
import { toPriceString } from '../../utils/number'
import PendingIndicator from '../common/PendingIndicator'
import { StepBar } from './StepBar'

type Props = {
  marginAmount: number
  hasEnoughUSDC: boolean
  isEnoughUSDCApproved: boolean
  insufficientLiquidity: boolean
  isMarginEnough: boolean
  isExceededSoftLimit: boolean
  isExceededPoolLimit: boolean
  isExceededDeltaLimit: boolean
  tradeType: TradeType
  isSubVaultsLoaded: boolean
  isTradeAmountsZero: boolean
  onApprove: () => void
  onTrade: () => void
}

export const TradeButtonArea = ({
  hasEnoughUSDC,
  isEnoughUSDCApproved,
  insufficientLiquidity,
  isMarginEnough,
  marginAmount,
  isExceededSoftLimit,
  isExceededPoolLimit,
  isExceededDeltaLimit,
  tradeType,
  isSubVaultsLoaded,
  isTradeAmountsZero,
  onApprove,
  onTrade
}: Props) => {
  const { active } = useWeb3React()
  const { isPendingApproval, isPendingTx } = pendingStore()

  const approveButtonEnabled =
    hasEnoughUSDC &&
    !isEnoughUSDCApproved &&
    !insufficientLiquidity &&
    active &&
    !isPendingApproval
  const buyButtonEnabled =
    hasEnoughUSDC &&
    isEnoughUSDCApproved &&
    !insufficientLiquidity &&
    isMarginEnough &&
    !isExceededSoftLimit &&
    !isExceededPoolLimit &&
    !isExceededDeltaLimit &&
    active &&
    !isPendingTx &&
    isSubVaultsLoaded &&
    !isTradeAmountsZero
  return (
    <div>
      <div className="flex justify-between font-semibold">
        <div>Total Amount</div>
        <div>{toPriceString(marginAmount)} USDC</div>
      </div>
      <div className="py-4 flex justify-center">
        <StepBar
          step={
            isMarginEnough && hasEnoughUSDC && !insufficientLiquidity
              ? isEnoughUSDCApproved
                ? 2
                : 1
              : 0
          }
        />
      </div>
      <div className="flex justify-between">
        <button
          className={`w-32 h-[50px] p-2 ${
            approveButtonEnabled ? 'final-gradient' : 'disabled:bg-third'
          } rounded-lg text-xs font-semibold`}
          onClick={onApprove}
          disabled={!approveButtonEnabled}
        >
          {isPendingApproval ? <PendingIndicator /> : 'Approve USDC'}
        </button>
        <button
          className={`w-32 h-[50px] p-2 ${
            buyButtonEnabled ? 'final-gradient' : 'disabled:bg-third'
          } rounded-lg text-xs font-semibold`}
          disabled={!buyButtonEnabled}
          onClick={onTrade}
        >
          {isPendingTx ? <PendingIndicator /> : tradeTypeToStr(tradeType)}
        </button>
      </div>

      {!hasEnoughUSDC ? (
        <div className="mt-2 text-xs text-red">
          {ERRORS.INSUFFICIENT_BALANCE}
        </div>
      ) : (
        <div />
      )}
      {insufficientLiquidity ? (
        <div className="mt-2 text-xs text-red">
          {ERRORS.INSUFFICIENT_LIQUIDITY}
        </div>
      ) : (
        <div />
      )}
      {!isMarginEnough ? (
        <div className="mt-2 text-xs text-red">{ERRORS.LESS_MARGIN}</div>
      ) : (
        <div />
      )}
      {isExceededSoftLimit || isExceededPoolLimit ? (
        <div className="mt-2 text-xs text-red">{ERRORS.SOFT_LIMIT}</div>
      ) : (
        <div />
      )}
      {isExceededDeltaLimit ? (
        <div className="mt-2 text-xs text-red">{ERRORS.DELTA_LIMIT}</div>
      ) : (
        <div />
      )}
      {!active ? (
        <div className="mt-2 text-xs text-red">{ERRORS.CONNECT_NETWORK}</div>
      ) : (
        <div />
      )}
      {!isSubVaultsLoaded ? (
        <div className="mt-2 text-xs text-white">
          {ERRORS.SUBVAULTS_ARE_LOADING_NOW}
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

function tradeTypeToStr(tradeType: TradeType) {
  switch (tradeType) {
    case TradeType.Trade:
      return 'Trade Now'
    case TradeType.Close:
      return 'Close Now'
    case TradeType.Deposit:
      return 'Deposit Now'
    case TradeType.Withdraw:
      return 'Withdraw Now'
    default:
      return 'Trade Now'
  }
}

export function getTradeType(tradeField: TradeFormData, isClose: boolean) {
  if (tradeField.tradeAmount0 === 0 && tradeField.tradeAmount1 === 0) {
    if (tradeField.marginAmount > 0) {
      return TradeType.Deposit
    } else if (tradeField.marginAmount < 0) {
      return TradeType.Withdraw
    }
  }

  return isClose ? TradeType.Close : TradeType.Trade
}
