import { ChartRange } from '../constants/enum'

export default function getStartTimestamp(range: ChartRange) {
  let startTimestamp = 0
  const currentTimestamp: number = Math.floor(Date.now() / 1000)
  switch (range) {
    case ChartRange.Hour:
      startTimestamp = currentTimestamp - 60 * 60
      break
    case ChartRange.Day:
      startTimestamp = currentTimestamp - 60 * 60 * 24
      break
    case ChartRange.Week:
      startTimestamp = currentTimestamp - 60 * 60 * 24 * 7
      break
    case ChartRange.Month:
      startTimestamp = currentTimestamp - 60 * 60 * 24 * 30
      break
    case ChartRange.Year:
      startTimestamp = currentTimestamp - 60 * 60 * 24 * 365
      break
  }
  return startTimestamp
}
