import React, { useState } from 'react'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import { toPriceString } from '../../utils/number'
import VaultHistory from './VaultHistory'
import VaultPositions from './VaultPositions'

type Props = {
  asset: number
  vaultId: number
}

enum Tab {
  Positions,
  History
}

const HorizontalRule = () => {
  return <hr className="my-0 border-third w-full" />
}

const VaultSummary = ({ asset, vaultId }: Props) => {
  const vaultStatus = useVaultStatus(asset, vaultId)

  return (
    <div className="flex items-center justify-center">
      <div className="text-xs">Vault Summary:</div>

      <div className="flex flex-wrap md:flex-no-wrap items-center justify-center divide-x divide-third">
        <div className="px-2">
          <div className="w-20 text-[10px] text-subtext font-semibold">
            Position Value:
          </div>
          <div className="text-xs">
            $
            {vaultStatus.data
              ? toPriceString(vaultStatus.data.positionValue)
              : '-'}
          </div>
        </div>
        <div className="px-2">
          <div className="w-24 text-[10px] text-subtext font-semibold">
            Liquidation Price:
          </div>
          <div className="text-xs">
            {!vaultStatus.data ||
            vaultStatus.data.liquidationPrices.length === 0 ? (
              <div>-</div>
            ) : vaultStatus.data.liquidationPrices.length === 1 ? (
              <div>
                $ {toPriceString(vaultStatus.data.liquidationPrices[0])}
              </div>
            ) : (
              <div>
                $ {toPriceString(vaultStatus.data.liquidationPrices[0])}, $
                {toPriceString(vaultStatus.data.liquidationPrices[1])}
              </div>
            )}
          </div>
        </div>
        <div className="px-2">
          <div className="w-20 text-[10px] text-subtext font-semibold">
            Min. Collateral:
          </div>
          <div className="text-xs">
            $
            {vaultStatus.data
              ? toPriceString(vaultStatus.data.minCollateral)
              : '-'}
          </div>
        </div>
        <div className="px-2">
          <div className="w-20 text-[10px] text-subtext font-semibold">
            USDC Amount:
          </div>
          <div className="text-xs">
            $
            {vaultStatus.data
              ? toPriceString(vaultStatus.data.positionUsdc)
              : '-'}
          </div>
        </div>
      </div>
    </div>
  )
}

const VaultInfo = ({ asset, vaultId }: Props) => {
  const [tab, setTab] = useState(Tab.Positions)
  const vaultStatus = useVaultStatus(asset, vaultId)

  return (
    <div className="rounded-lg bg-secondaly leading-5">
      <div className="flex justify-between items-center px-6 py-2">
        <div>
          <ul className="flex items-center space-x-8 text-sm">
            <li
              className={`cursor-pointer ${
                tab === Tab.Positions ? 'text-color-final-gradient' : ''
              }`}
              onClick={() => {
                setTab(Tab.Positions)
              }}
            >
              Open Positions
            </li>
            <li
              className={`cursor-pointer ${
                tab === Tab.History ? 'text-color-final-gradient' : ''
              }`}
              onClick={() => {
                setTab(Tab.History)
              }}
            >
              Order History
            </li>
          </ul>
          <span
            className={`relative block h-[1px] w-[132px] final-gradient ${
              tab === Tab.Positions
                ? '-translate-x-[24px]'
                : 'translate-x-[112px]'
            } top-[16px] transition ease-in-out`}
          ></span>
        </div>
        <VaultSummary asset={asset} vaultId={vaultId} />
      </div>

      <HorizontalRule />

      <div className="p-6">
        {tab === Tab.Positions ? (
          vaultStatus ? (
            <VaultPositions
              asset={asset}
              vaultId={vaultId}
              isLite={false}
            ></VaultPositions>
          ) : (
            <div />
          )
        ) : (
          <VaultHistory asset={asset} vaultId={vaultId}></VaultHistory>
        )}
      </div>
    </div>
  )
}

export const VaultInfoLite = ({ asset, vaultId }: Props) => {
  const vaultStatus = useVaultStatus(asset, vaultId)

  return (
    <div className="rounded-lg bg-secondaly leading-5">
      <div className="flex flex-wrap md:flex-no-wrap justify-between items-center px-6 py-2 space-x-0">
        <div className="w-32">Open Positions</div>
        <VaultSummary asset={asset} vaultId={vaultId} />
      </div>

      <HorizontalRule />

      <div className="p-6">
        {vaultStatus ? (
          <VaultPositions asset={asset} vaultId={vaultId} isLite={true} />
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}
export default VaultInfo
