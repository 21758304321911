import { DocumentNode, useQuery } from '@apollo/client'
import {
  OPENINTEREST,
  OPENINTERESTSTARTER,
  OpenInterestHistoryEntity
} from '../../queries/openInterestQuery'
import { ChartRange } from '../../constants/enum'
import getStartTimestamp from '../../utils/chartTimestamp'
import { useEffect, useState } from 'react'

function OpenInterest(range: ChartRange, productId: number) {
  let startTimestamp: number = getStartTimestamp(range)
  const [timestampFilter, setTimestampFilter] = useState(2000000000)
  const [historicalData, setHistoricalData] = useState<
    OpenInterestHistoryEntity[]
  >([])
  const [noData, setNoData] = useState(false)
  let queryName: DocumentNode = OPENINTEREST

  if (noData) {
    queryName = OPENINTERESTSTARTER
    startTimestamp = 1
  } else {
    queryName = OPENINTEREST
    startTimestamp = getStartTimestamp(range)
  }

  const { loading, error, data } = useQuery<{
    openInterestEntities: OpenInterestHistoryEntity[]
  }>(queryName, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ts: startTimestamp,
      productId: productId,
      tsFilter: timestampFilter
    }
  })

  useEffect(() => {
    setHistoricalData(<OpenInterestHistoryEntity[]>[])
    setTimestampFilter(2000000000)
  }, [range])

  useEffect(() => {
    if (!loading) {
      if (data && data.openInterestEntities.length > 0) {
        const fixedData = data.openInterestEntities.map(e => {
          return {
            ...e,
            openLong: e.openLong / 1e8,
            openShort: e.openShort / 1e8
          }
        })
        setHistoricalData(
          historicalData
            .concat(fixedData)
            .filter(
              (val, ind, self) =>
                ind === self.findIndex(item => item.id === val.id)
            )
            .sort((a, b) => a.timestamp - b.timestamp)
        )
        const returnedData = [...data.openInterestEntities]
        const tsFilter = returnedData.sort(
          (a, b) => a.timestamp - b.timestamp
        )[0].timestamp
        console.log('Set filter to:' + tsFilter)
        setTimestampFilter(tsFilter)
        setNoData(false)
      } else if (historicalData.length == 0) {
        setNoData(true)
        console.log('Data length zero')
      }
    } else {
      console.log('Loading data')
    }
  }, [loading])

  return historicalData
}

export default OpenInterest
