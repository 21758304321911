import { SubVault, VaultPositionInfo } from '../liquidationPrice'

export function calculateMinCollateral(
  positions: number[],
  n0: number,
  n1: number,
  underlyingPrice: number,
  minMargin: number
) {
  const alpha = 0.05
  const minCollateral =
    alpha *
    underlyingPrice *
    (Math.abs(
      (2 * underlyingPrice * (1 + n1) * positions[1]) / 10000 +
        (1 + n0) * positions[0]
    ) +
      (2 * alpha * underlyingPrice * (1 + n1) * Math.abs(positions[1])) / 10000)
  if (minCollateral < minMargin) {
    return minMargin
  }
  return minCollateral
}

export function calculatePositionValue(
  positions: number[],
  entryPrices: number[],
  tradePrice0: number,
  tradePrice1: number,
  fundingEntries: number[],
  fundingFeePerPosition0: number,
  fundingFeePerPosition1: number,
  margin: number
) {
  const perpetualValue =
    positions[0] * (tradePrice0 - entryPrices[0]) +
    positions[1] * (tradePrice1 - entryPrices[1])

  const fundingPaid =
    positions[0] * (fundingEntries[0] - fundingFeePerPosition0) +
    positions[1] * (fundingEntries[1] - fundingFeePerPosition1)

  return perpetualValue + fundingPaid + margin
}

export function updateEntryPrice(
  entryPrice: number,
  position: number,
  tradePrice: number,
  positionTrade: number
) {
  let newEntryPrice = 0
  let profitValue = 0

  const newPosition = position + positionTrade

  if (positionTrade === 0) {
    return {
      newEntryPrice: entryPrice,
      profitValue: 0
    }
  }

  if (
    position === 0 ||
    (position > 0 && positionTrade > 0) ||
    (position < 0 && positionTrade < 0)
  ) {
    newEntryPrice =
      (entryPrice * Math.abs(position) + tradePrice * Math.abs(positionTrade)) /
      Math.abs(position + positionTrade)
  } else if (
    (position > 0 && positionTrade < 0 && newPosition > 0) ||
    (position < 0 && positionTrade > 0 && newPosition < 0)
  ) {
    newEntryPrice = entryPrice
    profitValue = -positionTrade * (tradePrice - entryPrice)
  } else {
    if (newPosition != 0) {
      newEntryPrice = tradePrice
    }

    profitValue = position * (tradePrice - entryPrice)
  }

  return {
    newEntryPrice,
    profitValue
  }
}

export function getVaultPositions(vault: VaultPositionInfo | null) {
  if (vault === null) {
    return {
      positions: [0, 0],
      entryPrices: [0, 0],
      entryFundingFees: [0, 0]
    }
  }
  const a0 = vault.subVaults.reduce(
    (acc: number, subVault: SubVault) => acc + subVault.positionPerpetuals[0],
    0
  )
  const a1 = vault.subVaults.reduce(
    (acc: number, subVault: SubVault) => acc + subVault.positionPerpetuals[1],
    0
  )
  const e0 = vault.subVaults.reduce(
    (acc: number, subVault: SubVault) =>
      acc + subVault.positionPerpetuals[0] * subVault.entryPrices[0],
    0
  )
  const e1 = vault.subVaults.reduce(
    (acc: number, subVault: SubVault) =>
      acc + subVault.positionPerpetuals[1] * subVault.entryPrices[1],
    0
  )
  const entryFundingFee0 = vault.subVaults.reduce(
    (acc: number, subVault: SubVault) =>
      acc + subVault.positionPerpetuals[0] * subVault.entryFundingFee[0],
    0
  )
  const entryFundingFee1 = vault.subVaults.reduce(
    (acc: number, subVault: SubVault) =>
      acc + subVault.positionPerpetuals[1] * subVault.entryFundingFee[1],
    0
  )

  return {
    positions: [a0, a1],
    entryPrices: [a0 === 0 ? 0 : e0 / a0, a1 === 0 ? 0 : e1 / a1],
    entryFundingFees: [
      a0 === 0 ? 0 : entryFundingFee0 / a0,
      a1 === 0 ? 0 : entryFundingFee1 / a1
    ]
  }
}
