import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { useIsSupportedChain } from '../../hooks/network'

const NetworkErrorLabel = () => {
  const { error } = useWeb3React()
  const supported = useIsSupportedChain()
  if (!error && supported) return null

  return <div>This network is not supported</div>
}

export default NetworkErrorLabel
