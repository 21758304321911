import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers, BigNumber } from 'ethers'
import { useChainId, useIsSupportedChain } from '../network'
import { toUnscaled } from '../../utils/bn'
import { getIV } from '../../utils/bs'
import { FUNDING_PAYMENT_QUERY } from '../../queries/fundingPaymentQuery'
import { PredyClient } from '../../utils/apollo-client'

type FundingPaymentEntities = {
  data: {
    fundingPaymentEntities: {
      productId: string
      fundingRate: string
      totalFundingPaidPerPosition: string
      fundingPaidPerPosition: string
      poolReceived: string
      timestamp: string
    }[]
  }
}

export type FundingPayment = {
  productId: number
  fundingRate: number
  impliedVolatility: number
  totalFundingPaidPerPosition: number
  fundingPaidPerPosition: number
  poolReceived: number
  timestamp: number
}

export function useFundingPayment(productId: number, limit: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()

  return useQuery(
    ['funding_payment', chainId, productId, limit],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')

      const client = PredyClient[chainId]

      const response: FundingPaymentEntities = await client.query({
        query: FUNDING_PAYMENT_QUERY,
        variables: {
          first: limit,
          orderBy: 'timestamp',
          orderDirection: 'desc',
          productId: productId.toString()
        }
      })

      const positions: FundingPayment[] = await Promise.all(
        response.data.fundingPaymentEntities.map(async fundingPayment => {
          const fundingRate = toUnscaled(
            BigNumber.from(fundingPayment.fundingRate),
            16
          )
          return {
            productId: Number(fundingPayment.productId),
            fundingRate: fundingRate,
            impliedVolatility: getIV(fundingRate),
            totalFundingPaidPerPosition: toUnscaled(
              BigNumber.from(fundingPayment.totalFundingPaidPerPosition),
              16
            ),
            fundingPaidPerPosition: toUnscaled(
              BigNumber.from(fundingPayment.fundingPaidPerPosition),
              16
            ),
            poolReceived: toUnscaled(
              BigNumber.from(fundingPayment.poolReceived),
              8
            ),
            timestamp: Number(fundingPayment.timestamp)
          }
        })
      )

      return positions
    },
    {
      enabled: supportedChain && !!library
    }
  )
}
