export enum Product {
  FUTURE = 0,
  SQUARED = 1
}

export enum Action {
  TRADE = 'TRADE',
  DEPOSIT = 'DEPOSIT_MARGIN',
  WITHDRAW = 'WITHDRAW_MARGIN',
  LIQUIDATE = 'LIQUIDATE'
}

export enum ChartRange {
  Hour,
  Day,
  Week,
  Month,
  Year
}

export enum CardType {
  NONE = 0,
  ETH = 1,
  ETH2 = 2,
  DELTA = 3,
  GAMMA = 4,
  UNI_HEDGE = 5,
  CRAB = 6,
  PRO = 7
}

export enum TradeType {
  Trade,
  Close,
  Deposit,
  Withdraw
}
