import React, { useEffect, useState } from 'react'
import imgMinus from '../../assets/minus.svg'
import imgPlus from '../../assets/plus.svg'

type Props = {
  amount: number
  name: string
  onChange: (amount: number) => void
  step: number
  domainLow?: number
}

function addStep(amount: number, step: number, isAddition: boolean) {
  const n = Math.round(amount / step)
  const nextAmount = step * (n + (isAddition ? 1 : -1))
  return Number(nextAmount.toFixed(5))
}

export const PositionForm = ({ amount, name, onChange, step }: Props) => {
  const [value, setValue] = useState(amount.toString())

  useEffect(() => {
    setValue(Math.abs(amount).toString())
  }, [amount])

  return (
    <div className="flex items-center justify-between">
      <button
        className="rounded-full w-[30px] h-[30px] final-gradient flex items-center justify-center"
        onClick={() => {
          const nextAmount = addStep(amount, step, false)
          setValue(String(Math.abs(Number(nextAmount))))
          onChange(nextAmount)
        }}
      >
        <img src={imgMinus} alt="minus" />
      </button>
      <div className="relative inline-block h-[30px]">
        <span className="absolute left-12 top-[7px]">
          {amount == 0 ? '' : amount > 0 ? 'Long' : 'Short'}
        </span>
        <input
          autoComplete={'off'}
          className={`rounded-full bg-background ${
            amount == 0 ? 'indent-[4.7rem]' : 'indent-24'
          } h-full`}
          name={name}
          value={value}
          onChange={e => {
            setValue(e.target.value)
          }}
          onBlur={e => {
            setValue(String(Math.abs(Number(e.target.value))))
            onChange(Number(e.target.value))
          }}
        />
      </div>
      <button
        className="rounded-full w-[30px] h-[30px] final-gradient flex items-center justify-center"
        onClick={() => {
          const nextAmount = addStep(amount, step, true)
          setValue(String(Math.abs(Number(nextAmount))))
          onChange(nextAmount)
        }}
      >
        <img src={imgPlus} alt="plus" />
      </button>
    </div>
  )
}

export const AmountForm = ({
  amount,
  name,
  onChange,
  step,
  domainLow
}: Props) => {
  const [value, setValue] = useState(amount.toString())

  useEffect(() => {
    setValue(amount.toString())
  }, [amount])

  return (
    <div className="flex items-center justify-between">
      <button
        className="rounded-full w-[30px] h-[30px] final-gradient flex items-center justify-center"
        onClick={() => {
          let nextAmount = addStep(amount, step, false)
          if (domainLow != undefined) {
            if (nextAmount < domainLow) {
              nextAmount = domainLow
            }
          }
          setValue(String(nextAmount))
          onChange(nextAmount)
        }}
      >
        <img src={imgMinus} alt="minus" />
      </button>
      <div className="relative inline-block h-[30px]">
        <input
          autoComplete={'off'}
          className="rounded-full bg-background text-center h-full"
          name={name}
          value={value}
          onChange={e => {
            setValue(e.target.value)
          }}
          onBlur={e => {
            setValue(String(Number(e.target.value)))
            onChange(Number(e.target.value))
          }}
        />
      </div>
      <button
        className="rounded-full w-[30px] h-[30px] final-gradient flex items-center justify-center"
        onClick={() => {
          const nextAmount = addStep(amount, step, true)
          setValue(String(nextAmount))
          onChange(nextAmount)
        }}
      >
        <img src={imgPlus} alt="plus" />
      </button>
    </div>
  )
}
