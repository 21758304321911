import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from '../network'
import { useTradePrice } from './useTradePrice'
import { Product } from '../../constants/enum'
import { useCurrentEthPrice } from '../usePrice'
import { useVariance } from './pool/useVariance'

export type CrabAPY = {
  income: number
  apy: number
}

export function useCrabApy(
  assetIndex: number,
  amountFuture: number,
  amountSquared: number,
  margin: number
) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const futureTradePrice = useTradePrice(assetIndex, Product.FUTURE, [
    amountFuture,
    amountSquared
  ])
  const squaredTradePrice = useTradePrice(assetIndex, Product.SQUARED, [
    amountFuture,
    amountSquared
  ])
  const ethPrice = useCurrentEthPrice()
  const variance = useVariance(assetIndex)

  return useQuery(
    ['crab_apy', chainId, amountFuture, amountSquared, margin],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!futureTradePrice.isSuccess)
        throw new Error('futureTradePrice not set')
      if (!squaredTradePrice.isSuccess)
        throw new Error('squaredTradePrice not set')
      if (!ethPrice.isSuccess) throw new Error('tradePrice not set')
      if (!variance.isSuccess) throw new Error('variance not set')

      const indexPrice = (ethPrice.data * ethPrice.data) / 10000

      const income =
        Math.abs(amountSquared) *
        indexPrice *
        squaredTradePrice.data.fundingRate
      const cost =
        Math.abs(amountSquared) * indexPrice * variance.data +
        amountFuture * ethPrice.data * futureTradePrice.data.fundingRate

      return {
        income: (income * 365) / margin,
        apy: ((income - cost) * 365) / margin
      }
    },
    {
      enabled:
        !!library &&
        supportedChain &&
        futureTradePrice.isSuccess &&
        squaredTradePrice.isSuccess &&
        ethPrice.isSuccess &&
        variance.isSuccess &&
        margin > 0
    }
  )
}
