import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { useChainId, useIsSupportedChain } from '../network'
import { useMinCollateral, useMinMargin } from './useMinCollateral'
import { TradePrice, useTradePrice } from './useTradePrice'
import { Product } from '../../constants/enum'
import {
  getVaultLiquidationPrice,
  VaultPositionInfo
} from '../../utils/liquidationPrice'
import { ethers } from 'ethers'
import { useVaultStatus, VaultStatus } from './useVaultStatus'
import { useCurrentEthPrice } from '../usePrice'
import { useBlockNumber } from '../blockNumber'
import { useEffect, useState } from 'react'
import { useVaultLimit } from './pool/usePoolStatus'
import { updateEntryPrice } from '../../utils/helpers/vaultHelpers'
import { getTradePrices } from '../../wrapper'
import { PerpetualMarket__factory } from '../../typechain'
import { useAddresses } from '../useAddress'
import { toScaled } from '../../utils/bn'
import { DELTA_LIMIT, TRADE_FEE_1, TRADE_FEE_2 } from '../../constants'
import { Multicall__factory } from '../../typechain/multicall'
import { getDeltaOfFuture, getDeltaOfSquared } from '../../utils/bs'

export type TradeResult = {
  future: TradePrice
  squared: TradePrice
  minCollateral: number
  isMarginEnough: boolean
  requiredMargin: number
  positionUsdc: number
  liquidationPrices: number[]
  isExceededSoftLimit: boolean
  isExceededDeltaLimit: boolean
}

export function useTradeResult(
  asset: number,
  vaultId: number,
  subVaultIndex: number,
  tradeAmounts: number[],
  margin: number
) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()
  const addresses = useAddresses()
  const supportedChain = useIsSupportedChain()
  const minCollateral = useMinCollateral(asset, vaultId, tradeAmounts)
  const futureTradePrice = useTradePrice(asset, Product.FUTURE, tradeAmounts)
  const squaredTradePrice = useTradePrice(asset, Product.SQUARED, tradeAmounts)
  const ethPrice = useCurrentEthPrice()
  const blockNumber = useBlockNumber()
  const [tradeResult, setTradeResult] = useState<TradeResult | null>(null)
  const vaultLimits = useVaultLimit(asset)
  const vaultStatus = useVaultStatus(asset, vaultId)
  const minMargin = useMinMargin(asset)

  const tradeResultQuery = useQuery(
    [
      'trade_result',
      chainId,
      blockNumber,
      asset,
      vaultId,
      subVaultIndex,
      tradeAmounts,
      margin
    ],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!futureTradePrice.isSuccess)
        throw new Error('future trade price not set')
      if (!squaredTradePrice.isSuccess)
        throw new Error('squared trade price not set')
      if (!minCollateral.isSuccess) throw new Error('min collateral not set')
      if (!ethPrice.isSuccess) throw new Error('ethPrice not loaded')
      if (!vaultStatus.isLoaded) throw new Error('vaultStatus is not loaded')
      if (!minMargin.isSuccess) throw new Error('minMargin is not loaded')
      if (!addresses) throw new Error('addresses not set')
      if (!vaultLimits.isSuccess) throw new Error('vaultLimits is not loaded')

      const vaultPositionInfo = createVaultPositionInfoAfterTrade(
        vaultStatus.data,
        subVaultIndex,
        futureTradePrice.data.tradePrice,
        squaredTradePrice.data.tradePrice,
        tradeAmounts,
        margin
      )

      const isExceededSoftLimit = checkSoftLimit(
        vaultPositionInfo,
        vaultStatus.data,
        tradeAmounts,
        subVaultIndex,
        vaultLimits.data
      )

      const isExceededDeltaLimit = checkDeltaLimit(
        vaultStatus.data,
        tradeAmounts,
        ethPrice.data
      )

      const contract = PerpetualMarket__factory.connect(
        addresses.Perpetuals[asset].PerpetualMarket,
        library
      )
      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        library
      )

      const futureVaultPositinos = vaultPositionInfo.subVaults.reduce(
        (acc: number, subVault: any) => acc + subVault.positionPerpetuals[0],
        0
      )
      const squaredVaultPositinos = vaultPositionInfo.subVaults.reduce(
        (acc: number, subVault: any) => acc + subVault.positionPerpetuals[1],
        0
      )
      const tradePriceToClose = await getTradePrices(multicall, contract, [
        toScaled(-futureVaultPositinos, 8),
        toScaled(-squaredVaultPositinos, 8)
      ])

      const liquidationPrices = getVaultLiquidationPrice(
        vaultPositionInfo,
        tradePriceToClose[0].tradePrice,
        tradePriceToClose[1].tradePrice,
        tradePriceToClose[0].fundingRate,
        tradePriceToClose[1].fundingRate,
        minMargin.data
      )

      // the case of crab & no liquidation price is no enough margin
      const isCrabAndNoliquidationPrice =
        squaredVaultPositinos < 0 &&
        futureVaultPositinos > 0 &&
        liquidationPrices.length === 0

      const isCloseTrade = vaultPositionInfo.subVaults.reduce(
        (acc, subVault) => {
          return (
            acc &&
            subVault.positionPerpetuals[0] === 0 &&
            subVault.positionPerpetuals[1] === 0
          )
        },
        true
      )
      const positionValue = vaultStatus.data
        ? vaultStatus.data.positionValue
        : 0

      // total trade fee
      const tradeFee =
        Math.abs(tradeAmounts[0]) *
          futureTradePrice.data.tradePrice *
          TRADE_FEE_1 +
        Math.abs(tradeAmounts[1]) *
          squaredTradePrice.data.tradePrice *
          TRADE_FEE_2

      // if withdraw case, trader want to withdraw all margin
      // if trade case, check strictly
      const isMarginEnough = isCloseTrade
        ? positionValue + margin >= minCollateral.data
        : positionValue + margin - tradeFee * 2 > minCollateral.data &&
          !isCrabAndNoliquidationPrice

      const result: TradeResult = {
        future: futureTradePrice.data,
        squared: squaredTradePrice.data,
        minCollateral: minCollateral.data,
        isMarginEnough,
        requiredMargin: minCollateral.data - positionValue,
        positionUsdc: vaultStatus.data ? vaultStatus.data.positionUsdc : 0,
        liquidationPrices,
        isExceededSoftLimit,
        isExceededDeltaLimit
      }

      return result
    },
    {
      enabled:
        !!library &&
        supportedChain &&
        futureTradePrice.isSuccess &&
        squaredTradePrice.isSuccess &&
        minCollateral.isSuccess &&
        ethPrice.isSuccess &&
        vaultLimits.isSuccess &&
        subVaultIndex >= 0 &&
        vaultStatus.isLoaded &&
        minMargin.isSuccess &&
        !!addresses
    }
  )

  useEffect(() => {
    if (tradeResultQuery.isSuccess) {
      setTradeResult(tradeResultQuery.data)
    }
  }, [tradeResultQuery.isSuccess, tradeResultQuery.data])

  return tradeResult
}

function checkDeltaLimit(
  vault: VaultStatus | null,
  tradeAmounts: number[],
  ethPrice: number
) {
  let vaultPositinos0
  let vaultPositinos1

  if (vault === null) {
    vaultPositinos0 = 0
    vaultPositinos1 = 0
  } else {
    vaultPositinos0 = vault.subVaults.reduce(
      (acc: number, subVault: any) => acc + subVault.positionPerpetuals[0],
      0
    )
    vaultPositinos1 = vault.subVaults.reduce(
      (acc: number, subVault: any) => acc + subVault.positionPerpetuals[1],
      0
    )
  }

  const vaultPositinosAfterTrade0 = vaultPositinos0 + tradeAmounts[0]
  const vaultPositinosAfterTrade1 = vaultPositinos1 + tradeAmounts[1]

  console.log(
    vaultPositinos0,
    vaultPositinos1,
    vaultPositinosAfterTrade0,
    vaultPositinosAfterTrade1
  )

  const delta =
    getDeltaOfFuture(vaultPositinos0, 0) +
    getDeltaOfSquared(vaultPositinos1, 0, ethPrice)
  const deltaAfter =
    getDeltaOfFuture(vaultPositinosAfterTrade0, 0) +
    getDeltaOfSquared(vaultPositinosAfterTrade1, 0, ethPrice)

  return (
    Math.abs(delta) + DELTA_LIMIT < Math.abs(deltaAfter) &&
    Math.abs(deltaAfter) > DELTA_LIMIT
  )
}

function checkSoftLimit(
  vaultPositionInfo: VaultPositionInfo,
  vault: VaultStatus | null,
  tradeAmounts: number[],
  subVaultIndex: number,
  softLimits: number[]
) {
  const checkAmountsGreaterThanSoftLimit = (a0: number, a1: number) =>
    Math.abs(a0) > softLimits[0] || Math.abs(a1) > softLimits[1]

  if (vault === null) {
    return false
  }

  const vaultPositinos0 = vault.subVaults.reduce(
    (acc: number, subVault: any) => acc + subVault.positionPerpetuals[0],
    0
  )
  const vaultPositinos1 = vault.subVaults.reduce(
    (acc: number, subVault: any) => acc + subVault.positionPerpetuals[1],
    0
  )

  if (
    tradeAmounts[0] * vaultPositinos0 <= 0 &&
    Math.abs(tradeAmounts[0]) <= Math.abs(vaultPositinos0) &&
    tradeAmounts[1] * vaultPositinos1 <= 0 &&
    Math.abs(tradeAmounts[1]) <= Math.abs(vaultPositinos1)
  ) {
    return false
  }

  return (
    checkAmountsGreaterThanSoftLimit(
      vaultPositionInfo.subVaults.reduce(
        (acc: number, subVault: any) => acc + subVault.positionPerpetuals[0],
        0
      ),
      vaultPositionInfo.subVaults.reduce(
        (acc: number, subVault: any) => acc + subVault.positionPerpetuals[1],
        0
      )
    ) ||
    checkAmountsGreaterThanSoftLimit(
      vaultPositionInfo.subVaults[subVaultIndex].positionPerpetuals[0],
      vaultPositionInfo.subVaults[subVaultIndex].positionPerpetuals[1]
    )
  )
}

function checkPoolLimit(
  tradeAmounts: number[],
  poolLimits: { long: number; short: number }[]
) {
  const check = (
    tradeAmount: number,
    poolLimit: { long: number; short: number }
  ) =>
    tradeAmount > 0
      ? tradeAmount > poolLimit.long
      : tradeAmount < poolLimit.short

  return (
    check(tradeAmounts[0], poolLimits[0]) ||
    check(tradeAmounts[1], poolLimits[1])
  )
}

/**
 * Create vault position info after trade
 * @param vaultStatus
 * @param subVaultIndex
 * @param futureTradePrice
 * @param squaredTradePrice
 * @param tradeAmounts
 * @param margin
 * @returns
 */
function createVaultPositionInfoAfterTrade(
  vaultStatus: VaultStatus | null,
  subVaultIndex: number,
  futureTradePrice: number,
  squaredTradePrice: number,
  tradeAmounts: number[],
  margin: number
) {
  const vaultPositionInfo: VaultPositionInfo = {
    vaultId: vaultStatus ? vaultStatus.vaultId : 0,
    positionUsdc: vaultStatus ? vaultStatus.positionUsdc : 0,
    subVaults: vaultStatus
      ? vaultStatus.subVaults.map(subVault => ({
          positionPerpetuals: subVault.positionPerpetuals.map(v => v),
          entryPrices: subVault.entryPrices.map(v => v),
          entryFundingFee: subVault.entryFundingFee.map(v => v),
          fundingPaids: subVault.fundingPaids.map(v => v)
        }))
      : []
  }

  if (vaultPositionInfo.subVaults.length === subVaultIndex) {
    vaultPositionInfo.subVaults.push({
      positionPerpetuals: [0, 0],
      entryPrices: [futureTradePrice, squaredTradePrice],
      entryFundingFee: [0, 0],
      fundingPaids: [0, 0]
    })
  } else {
    for (let i = 0; i < 2; i++) {
      if (
        vaultPositionInfo.subVaults[subVaultIndex].positionPerpetuals[i] ===
          0 &&
        tradeAmounts[i] === 0
      ) {
        vaultPositionInfo.subVaults[subVaultIndex].entryPrices[i] =
          i === 0 ? futureTradePrice : squaredTradePrice
      } else {
        const result = updateEntryPrice(
          vaultPositionInfo.subVaults[subVaultIndex].entryPrices[i],
          vaultPositionInfo.subVaults[subVaultIndex].positionPerpetuals[i],
          i === 0 ? futureTradePrice : squaredTradePrice,
          tradeAmounts[i]
        )
        vaultPositionInfo.subVaults[subVaultIndex].entryPrices[i] =
          result.newEntryPrice
        vaultPositionInfo.positionUsdc += result.profitValue
      }
    }
  }

  vaultPositionInfo.subVaults[subVaultIndex].positionPerpetuals[0] +=
    tradeAmounts[0]
  vaultPositionInfo.subVaults[subVaultIndex].positionPerpetuals[1] +=
    tradeAmounts[1]
  vaultPositionInfo.positionUsdc += margin

  return vaultPositionInfo
}
