import React from 'react'
import VaultHistory from './VaultHistory'

type Props = {
  asset: number
  vaultId: number
}

const VaultHistoryLite = ({ asset, vaultId }: Props) => {
  return (
    <div className="rounded-lg bg-secondaly leading-5 p-6">
      <div className="flex justify-between items-center mb-2">
        <div>Order History</div>
      </div>

      <VaultHistory asset={asset} vaultId={vaultId} />
    </div>
  )
}

export default VaultHistoryLite
