import { BigNumber } from 'ethers'
import { useChainId } from '../network'
import { toUnscaled } from '../../utils/bn'
import { useAddressesAnyway } from '../useAddress'
import { Action, Product } from '../../constants/enum'
import { PredyClient } from '../../utils/apollo-client'
import { productToString } from '../../utils'
import { toTimeString } from '../../utils/string'
import { useQuery } from '@apollo/client'
import {
  VaultHistoryEntity,
  VAULT_HISTORY_QUERY
} from '../../queries/vaultHistoryQuery'
import { useEffect, useState } from 'react'

export type HistoryItem = {
  txid: string
  action: Action
  subVaultIndex: number
  productId: number
  product: Product
  productName: string
  tradeAmount: number
  tradePrice: number
  fundingFeePerPosition: number
  marginAmount: number
  timestamp: number
  time: string
}

export function useVaultHistory(asset: number, vaultId: number) {
  const addresses = useAddressesAnyway()
  const chainId = useChainId()

  const assetId =
    addresses.Perpetuals[asset].PerpetualMarket.toLocaleLowerCase()

  const [skipCount, setSkipCount] = useState(0)
  const [vaultHistories, setVaultHistories] = useState<VaultHistoryEntity[]>([])

  const { data, loading } = useQuery<{
    tradeHistoryEntities: VaultHistoryEntity[]
  }>(VAULT_HISTORY_QUERY, {
    fetchPolicy: 'cache-and-network',
    client: PredyClient[chainId],
    notifyOnNetworkStatusChange: true,
    variables: {
      assetId,
      vaultId: vaultId.toString(),
      skipCount: skipCount
    },
    pollInterval: 10000
  })

  useEffect(() => {
    if (!loading) {
      if (data && data.tradeHistoryEntities.length > 0) {
        setVaultHistories(
          vaultHistories
            .concat(data.tradeHistoryEntities)
            .filter(
              (val, ind, self) =>
                ind === self.findIndex(item => item.id === val.id)
            )
        )
        setSkipCount(skipCount + 1000)
      } else {
        setSkipCount(0)
      }
    }
  }, [loading])

  const history: HistoryItem[] = vaultHistories.map(historyItem => {
    const action = <Action>historyItem.action
    const productId = Number(historyItem.productId)
    const product = <Product>productId
    const timestamp = Number(historyItem.timestamp)

    return {
      txid: historyItem.txid,
      action,
      subVaultIndex:
        action === Action.TRADE ? Number(historyItem.subVaultIndex) : -1,
      productId,
      product,
      productName: productToString(product),
      tradeAmount: toUnscaled(BigNumber.from(historyItem.tradeAmount), 8),
      tradePrice: toUnscaled(BigNumber.from(historyItem.tradePrice), 8),
      fundingFeePerPosition: toUnscaled(
        BigNumber.from(historyItem.fundingFeePerPosition),
        16
      ),
      marginAmount: toUnscaled(BigNumber.from(historyItem.marginAmount), 6),
      timestamp,
      time: toTimeString(timestamp)
    }
  })

  return history.sort((a, b) => b.timestamp - a.timestamp)
}
