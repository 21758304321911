import React, { useCallback, useEffect, useState } from 'react'
import iconUni from '../../../assets/icons/Icon_Uni.svg'
import iconMagnifyingGlass from '../../../assets/MagnifyingGlass.png'
import iconCross from '../../../assets/Cross.png'
import {
  useUniswapPosition,
  useUniswapPositions
} from '../../../hooks/query/useUniswapPositions'
import { getNetDelta, getGamma } from '../../../utils/bs'
import { useCurrentEthPrice } from '../../../hooks/usePrice'
import modalStore from '../../../store/modal'
import { getAmount } from '../../../utils'
import {
  toFundingRateString,
  toGreeksString,
  toPriceString
} from '../../../utils/number'

type Props = {
  tokenId: number
  amounts: number[]
  tradePrices: number[]
  fundingRates: number[]
  onChange: (amount0: number, amount1: number) => void
  onLPTokenSelected: (tokenId: number) => void
}

export const UniForm = ({
  tokenId,
  amounts,
  tradePrices,
  fundingRates,
  onChange,
  onLPTokenSelected
}: Props) => {
  const { setOpen } = modalStore()
  const [localTokenId, setLocalTokenId] = useState('')
  const uniPositions = useUniswapPositions()
  const uniPosition = useUniswapPosition(tokenId)
  const ethPrice = useCurrentEthPrice()

  const myOnChange = useCallback((a0, a1) => {
    onChange(a0, a1)
  }, [])

  useEffect(() => {
    if (uniPosition.isSuccess) {
      myOnChange(uniPosition.data.futureAmount, uniPosition.data.squaredAmount)
    }
  }, [tokenId, uniPosition.isSuccess, uniPosition.data, myOnChange])

  const onChangeHandler = async (e: any) => {
    const tokenId = Number(e.target.value)
    onLPTokenSelected(tokenId)
    setLocalTokenId(tokenId.toString())
  }

  return (
    <div className="space-y-4">
      <div className="flex flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <img src={iconUni} className="h-[50px]"></img>
          <div>
            <p>Uni V3 Hedge</p>
          </div>
        </div>
        <div className="flex space-x-2">
          <img
            className="cursor-pointer"
            src={iconMagnifyingGlass}
            onClick={() => {
              setOpen(true)
            }}
          />
          <img
            className="cursor-pointer"
            src={iconCross}
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
      </div>
      <div className="space-y-2 text-xs">
        <div className="flex justify-between pb-2">
          <p>LP NFT ID in Wallet: </p>
          <select
            className="text-third h-5 w-[88px]"
            value={tokenId}
            name={'unitokenids'}
            onChange={onChangeHandler}
          >
            <option>not selected</option>
            {(uniPositions.data || []).map((p, i) => (
              <option key={i} value={p}>
                {p}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-between pb-2">
          <p>LP NFT ID: </p>
          <input
            className="text-third h-5 text-right w-[88px]"
            type="number"
            value={localTokenId}
            onChange={e => {
              setLocalTokenId(e.target.value)
            }}
            onBlur={onChangeHandler}
          ></input>
        </div>

        <div className="flex justify-between">
          <p>
            Amount (ETH)<sup>2</sup>, ETH:
          </p>
          <p>
            {getAmount(amounts[1])}
            {', '}
            {getAmount(amounts[0])}
          </p>
        </div>
        <div className="flex justify-between">
          <p>
            Entry Price (ETH)<sup>2</sup>, ETH:
          </p>
          <p>
            ${toPriceString(tradePrices[1])}, ${toPriceString(tradePrices[0])}
          </p>
        </div>
        <div className="flex justify-between">
          <p>Estimated Funding Rate:</p>
          <p>
            {toFundingRateString(fundingRates[1])}% ,{' '}
            {toFundingRateString(fundingRates[0])}%
          </p>
        </div>

        <div className="flex justify-between">
          <p>Delta, Gamma:</p>
          <p>
            {toGreeksString(
              getNetDelta(
                amounts[0],
                amounts[1],
                fundingRates[0],
                fundingRates[1],
                ethPrice.data || 0
              )
            )}
            , {toGreeksString(getGamma(amounts[1], fundingRates[1]))}
          </p>
        </div>
      </div>
    </div>
  )
}
