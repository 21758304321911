/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from 'ethers'
import { Provider, TransactionRequest } from '@ethersproject/providers'
import type { MathTester, MathTesterInterface } from '../MathTester'

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_x',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: '_y',
        type: 'int256'
      }
    ],
    name: 'testAddDelta',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_a',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_from',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_to',
        type: 'uint256'
      }
    ],
    name: 'testScale',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'pure',
    type: 'function'
  }
]

const _bytecode =
  '0x608060405234801561001057600080fd5b50610320806100206000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c80632818a7d71461003b578063a868c6aa14610070575b600080fd5b61005e6004803603604081101561005157600080fd5b5080359060200135610099565b60408051918252519081900360200190f35b61005e6004803603606081101561008657600080fd5b50803590602081013590604001356100ae565b60006100a583836100c5565b90505b92915050565b60006100bb848484610153565b90505b9392505050565b600080821215610115578282600003840391508110610110576040805162461bcd60e51b815260206004820152600260248201526104d360f41b604482015290519081900360640190fd5b6100a8565b50808201828110156100a8576040805162461bcd60e51b81526020600482015260026024820152614d3160f01b604482015290519081900360640190fd5b6000818311156101af57604682840310610199576040805162461bcd60e51b8152602060048201526002602482015261269960f11b604482015290519081900360640190fd5b6101a884838503600a0a610209565b90506100be565b81831015610202576046838303106101f3576040805162461bcd60e51b8152602060048201526002602482015261269960f11b604482015290519081900360640190fd5b6101a884848403600a0a610270565b50826100be565b600080821161025f576040805162461bcd60e51b815260206004820152601a60248201527f536166654d6174683a206469766973696f6e206279207a65726f000000000000604482015290519081900360640190fd5b81838161026857fe5b049392505050565b60008261027f575060006100a8565b8282028284828161028c57fe5b04146100a55760405162461bcd60e51b81526004018080602001828103825260218152602001806102ca6021913960400191505060405180910390fdfe536166654d6174683a206d756c7469706c69636174696f6e206f766572666c6f77a26469706673582212209dfa15722b8ddf4083d2f92424f219886ecd95bbd30140c20520b0dcc04b95ea64736f6c63430007060033'

export class MathTester__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0])
    } else {
      super(...args)
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MathTester> {
    return super.deploy(overrides || {}) as Promise<MathTester>
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {})
  }
  attach(address: string): MathTester {
    return super.attach(address) as MathTester
  }
  connect(signer: Signer): MathTester__factory {
    return super.connect(signer) as MathTester__factory
  }
  static readonly bytecode = _bytecode
  static readonly abi = _abi
  static createInterface(): MathTesterInterface {
    return new utils.Interface(_abi) as MathTesterInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MathTester {
    return new Contract(address, _abi, signerOrProvider) as MathTester
  }
}
