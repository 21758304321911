import bn from 'bignumber.js'

const Q96 = new bn(2).pow(96)

export function getGreeksFromUniLPPosition(
  liquidity: number,
  price: number,
  upperPrice: number
) {
  const delta = liquidity * (1 / Math.sqrt(price) - 1 / Math.sqrt(upperPrice))
  const gamma = 0.5 * liquidity * price ** (-3 / 2)

  return {
    delta,
    gamma
  }
}

const encodePriceSqrt = (price: number | string | bn): bn => {
  return new bn(price).sqrt().multipliedBy(new bn(2).pow(96)).integerValue(3)
}

function expandDecimals(n: number | string | bn, exp: number): bn {
  return new bn(n).multipliedBy(new bn(10).pow(exp))
}

export const getPriceFromTick = (
  tick: number,
  token0Decimal: string,
  token1Decimal: string
): number => {
  const sqrtPrice = new bn(Math.pow(Math.sqrt(1.0001), tick)).multipliedBy(
    new bn(2).pow(96)
  )
  const token0 = expandDecimals(1, Number(token0Decimal))
  const token1 = expandDecimals(1, Number(token1Decimal))
  const L2 = encodePriceSqrt(token0)
    .multipliedBy(encodePriceSqrt(token1))
    .div(Q96)
  const price = L2.multipliedBy(Q96)
    .div(sqrtPrice)
    .div(new bn(2).pow(96))
    .div(new bn(10).pow(token0Decimal))
    .pow(2)

  return price.toNumber()
}
