import { gql } from '@apollo/client'

export const METRICS_QUERY = gql`
  query ($first: Int) {
    metricsEntities(first: $first) {
      id
      asset {
        id
      }
      openLong
      openShort
      volume
      notionalVolume
    }
  }
`

export const AGGREGATED_VOLUME_QUERY = gql`
  query ($first: Int, $productId: BigInt, $interval: String) {
    aggregatedVolumes(
      first: $first
      orderBy: openTimestamp
      orderDirection: desc
      where: { productId: $productId, interval: $interval }
    ) {
      id
      asset {
        id
      }
      productId
      interval
      openTimestamp
      closeTimestamp
      volume
      notionalVolume
    }
  }
`

export type MetricsEntities = {
  metricsEntities: {
    id: string
    asset: {
      id: string
    }
    openLong: string
    openShort: string
    volume: string
    notionalVolume: string
  }[]
}

export type AggregatedVolume = {
  aggregatedVolumes: {
    id: string
    asset: {
      id: string
    }
    productId: string
    interval: string
    openTimestamp: string
    closeTimestamp: string
    volume: string
    notionalVolume: string
  }[]
}
