/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from 'ethers'
import { Provider, TransactionRequest } from '@ethersproject/providers'
import type { PricerTester, PricerTesterInterface } from '../PricerTester'

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_productId',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: '_spotPrice',
        type: 'int256'
      }
    ],
    name: 'testCalculateDelta',
    outputs: [
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      }
    ],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_productId',
        type: 'uint256'
      }
    ],
    name: 'testCalculateGamma',
    outputs: [
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      }
    ],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_productId',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: '_spotPrice',
        type: 'int256'
      }
    ],
    name: 'testCalculatePrice',
    outputs: [
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      }
    ],
    stateMutability: 'pure',
    type: 'function'
  }
]

const _bytecode =
  '0x608060405234801561001057600080fd5b506102a3806100206000396000f3fe608060405234801561001057600080fd5b50600436106100415760003560e01c80632b6872bc1461004657806352540cac1461007b578063aa89884b14610098575b600080fd5b6100696004803603604081101561005c57600080fd5b50803590602001356100bb565b60408051918252519081900360200190f35b6100696004803603602081101561009157600080fd5b50356100d0565b610069600480360360408110156100ae57600080fd5b50803590602001356100e3565b60006100c783836100ef565b90505b92915050565b60006100db82610157565b90505b919050565b60006100c78383610178565b6000826100fd5750806100ca565b82600114156101255764e8d4a51000610116838061019d565b8161011d57fe5b0590506100ca565b6040805162461bcd60e51b815260206004820152600260248201526104e560f41b604482015290519081900360640190fd5b600081610166575060006100de565b81600114156101255750614e206100de565b60008261018a57506305f5e1006100ca565b8260011415610125576127106101168360025b6000826101ac575060006100ca565b826000191480156101c05750600160ff1b82145b156101fc5760405162461bcd60e51b81526004018080602001828103825260278152602001806102476027913960400191505060405180910390fd5b8282028284828161020957fe5b05146100c75760405162461bcd60e51b81526004018080602001828103825260278152602001806102476027913960400191505060405180910390fdfe5369676e6564536166654d6174683a206d756c7469706c69636174696f6e206f766572666c6f77a264697066735822122095ee2cce82392e33792cfc768a5f86dac87e02b43525496f90ecbcba4fdb49ac64736f6c63430007060033'

export class PricerTester__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0])
    } else {
      super(...args)
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<PricerTester> {
    return super.deploy(overrides || {}) as Promise<PricerTester>
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {})
  }
  attach(address: string): PricerTester {
    return super.attach(address) as PricerTester
  }
  connect(signer: Signer): PricerTester__factory {
    return super.connect(signer) as PricerTester__factory
  }
  static readonly bytecode = _bytecode
  static readonly abi = _abi
  static createInterface(): PricerTesterInterface {
    return new utils.Interface(_abi) as PricerTesterInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): PricerTester {
    return new Contract(address, _abi, signerOrProvider) as PricerTester
  }
}
