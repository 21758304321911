import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useBlockNumber } from './blockNumber'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

export function useBlockTimestamp() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const blockNumber = useBlockNumber()
  const [timestamp, setTimestamp] = useState(0)

  const timestampQuery = useQuery(
    ['timestamp', blockNumber],
    async () => {
      if (!library) throw new Error('library not set')

      const block = await library.getBlock('latest')

      return block.timestamp
    },
    {
      enabled: !!library
    }
  )

  useEffect(() => {
    if (timestampQuery.isSuccess) {
      setTimestamp(timestampQuery.data)
    }
  }, [timestampQuery])

  return timestamp
}
