import create from 'zustand'

export type TradeFormData = {
  tradeAmount0: number
  tradeAmount1: number
  marginAmount: number
}

interface State {
  fields: TradeFormData
  setFields: (fields: TradeFormData) => void
}

const useStore = create<State>(set => ({
  fields: {
    tradeAmount0: 0,
    tradeAmount1: 0,
    marginAmount: 0
  },
  setFields: (fields: TradeFormData) => set({ fields })
}))

export default useStore
