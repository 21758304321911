import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from '../network'
import { toScaled, toUnscaled } from '../../utils/bn'
import { useAddresses } from '../useAddress'
import { PerpetualMarket__factory } from '../../typechain'
import { useVaultStatus } from './useVaultStatus'
import { calculateMinCollateral } from '../../utils/helpers/vaultHelpers'
import { useCurrentEthPrice } from '../usePrice'
import { MIN_MARGINS } from '../../constants'

export function useMinCollateral(
  asset: number,
  vaultId: number,
  tradeAmounts: number[]
) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const vaultStatus = useVaultStatus(asset, vaultId)
  const ethPrice = useCurrentEthPrice()
  const minMargin = useMinMargin(asset)

  return useQuery(
    ['min_collateral', chainId, asset, vaultId, tradeAmounts],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')
      if (!ethPrice.isSuccess) throw new Error('ethPrice not loaded')
      if (!vaultStatus.isLoaded) throw new Error('vaultStatus not loaded')
      if (!minMargin.isSuccess) throw new Error('minMargin not loaded')

      const contract = PerpetualMarket__factory.connect(
        addresses.Perpetuals[asset].PerpetualMarket,
        library
      )

      try {
        const minCollateral = await contract.getMinCollateralToAddPosition(
          vaultId,
          [toScaled(tradeAmounts[0], 8), toScaled(tradeAmounts[1], 8)]
        )

        return toUnscaled(minCollateral, 6)
      } catch (e) {
        return calculateMinCollateral(
          [
            (vaultStatus.data ? vaultStatus.data.amounts[0] : 0) +
              tradeAmounts[0],
            (vaultStatus.data ? vaultStatus.data.amounts[1] : 0) +
              tradeAmounts[1]
          ],
          0,
          0,
          ethPrice.data,
          minMargin.data
        )
      }
    },
    {
      enabled:
        supportedChain &&
        !!library &&
        !!addresses &&
        ethPrice.isSuccess &&
        vaultStatus.isLoaded &&
        minMargin.isSuccess
    }
  )
}

export function useMinMargin(assetIndex: number) {
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()

  return useQuery(
    ['min_margin', chainId, assetIndex],
    async () => {
      if (!addresses) throw new Error('addresses not set')

      return MIN_MARGINS[chainId]
    },
    {
      enabled: supportedChain && !!addresses
    }
  )
}
