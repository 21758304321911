import React from 'react'
import styled from 'styled-components'

type Props = {
  onChange: (value: boolean) => void
}

export const Switch = ({ onChange }: Props) => {
  return (
    <SwitchLabel>
      <Input onChange={e => onChange(e.target.checked)} />
      <Slider></Slider>
    </SwitchLabel>
  )
}

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`

const Input = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }

  ${Input}:checked + & {
    background-color: #04eca6;
  }

  ${Input}:checked + &:before {
    transform: translateX(26px);
  }
`
