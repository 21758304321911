export const FONT_SIZE = {
  XX_SMALL: '0.58rem',
  X_SMALL: '0.69rem',
  SMALL: '0.83rem',
  MEDIUM: '1rem',
  LARGE: '1.2rem',
  X_LARGE: '1.44rem',
  XX_LARGE: '1.73rem'
} as const

export const SPACE = {
  SMALL: '4px',
  MEDIUM: '8px',
  LARGE: '16px',
  X_LARGE: '20px',
  XX_LARGE: '28px',
  XXX_LARGE: '48px'
} as const

export const RADIUS = {
  SMALL: '4px',
  MEDIUM: '8px',
  LARGE: '12px',
  ROUND: '100px'
} as const

export const SUPPORTED_CHAIN_IDS = [42161, 421611]
export const DEFAULT_CHAIN = SUPPORTED_CHAIN_IDS[0]

export const NETWORK_PARAMS: { [key: number]: any } = {
  42161: {
    chainId: '0x' + Number(42161).toString(16),
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'AETH',
      symbol: 'AETH',
      decimals: 18
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io']
  },
  421611: {
    chainId: '0x' + Number(421611).toString(16),
    chainName: 'Arbitrum Testnet',
    nativeCurrency: {
      name: 'AETH',
      symbol: 'AETH',
      decimals: 18
    },
    rpcUrls: ['https://rinkeby.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://testnet.arbiscan.io']
  }
}

// slippage torelance is 1.0%
export const SLIPPAGE_TORELANCE = 10

export const MIN_MARGINS: { [key: number]: number } = {
  42161: 200,
  421611: 200
}
export const VAULT_RISK_PARAM = 0.05
// 12 * 240 = 2880 seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 4

export const TOOLTIPS = {
  pnl: `The unrealized profit or loss of your position, calculated using current mark price provided by Pool.`,
  margin: `margin description here`
} as const

export const SQUARD_PERP_FUNDING_MULTIPLIER = 5.5
export const FUTURE_PERP_MAX_FUNDING_RATE = 0.0022
export const MULTIPLIED_PRICE = 1.2
export const TRADE_FEE_1 = 0.0005
export const TRADE_FEE_2 = 2 * TRADE_FEE_1
export const DELTA_LIMIT = 1.5
