import { gql } from '@apollo/client'

export const LPPRICE = gql`
  query GetLpPrice($ts: BigInt!, $tsFilter: BigInt!) {
    lpTokenPriceEntities(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gt: $ts, timestamp_lt: $tsFilter }
    ) {
      id
      timestamp
      price
    }
  }
`

export const LPPRICESTARTER = gql`
  query GetLpPrice($ts: BigInt!, $tsFilter: BigInt!) {
    lpTokenPriceEntities(
      first: 1
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gt: $ts, timestamp_lt: $tsFilter }
    ) {
      id
      timestamp
      price
    }
  }
`

export type LpPriceHistoryEntity = {
  id: string
  timestamp: number
  price: number
}
