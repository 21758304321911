import React from 'react'
import {
  useLPTokenPrice,
  useNettingInfo,
  usePoolStatus,
  useVaultLimit,
  useTotalLiquidity
} from '../../hooks/query/pool/usePoolStatus'
import { Product } from '../../constants/enum'
import { toAmountString, toAPYString, toPriceString } from '../../utils/number'
import HorizontalRule from '../common/HorizontalRule'
import { useHedgeState } from '../../hooks/query/pool/useHedgeState'
import { toUnscaled } from '../../utils/bn'

type Props = {
  asset: number
}

const PoolStatus = ({ asset }: Props) => {
  const poolStatus = usePoolStatus(asset)
  const totalLiquidity = useTotalLiquidity(asset)
  const softLimits = useVaultLimit(asset)
  const nettingInfo = useNettingInfo(asset)
  const hedgeStatus = useHedgeState(asset)
  const lpTokenPrice = useLPTokenPrice(asset, 0)

  return (
    <div className="rounded-lg bg-secondaly w-full h-full px-20 py-8">
      <h4 className="pb-[17px] text-lg font-semibold">Pool Status</h4>
      <div className="text-sm">
        <HorizontalRule />
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div>ETH-PERP Position</div>
            <div>
              {toAmountString(
                poolStatus.data?.status[Product.FUTURE].positionPerpetuals || 0
              )}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>ETH2-PERP Position</div>
            <div>
              {toAmountString(
                poolStatus.data?.status[Product.SQUARED].positionPerpetuals || 0
              )}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div>Soft Limit(Future)</div>
            <div>
              {toPriceString(
                softLimits.data ? softLimits.data[Product.FUTURE] : 0
              )}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div>Soft Limit(Squared)</div>
            <div>
              {toPriceString(
                softLimits.data ? softLimits.data[Product.SQUARED] : 0
              )}
            </div>
          </div>
        </div>
        <HorizontalRule />
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div>LP Token Price</div>
            <div>
              {lpTokenPrice.isSuccess
                ? toUnscaled(lpTokenPrice.data.lpTokenPrice, 16).toFixed(6)
                : 0}{' '}
              USDC
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Utilization Ratio</div>
            <div>{toAPYString(poolStatus.data?.utilizationRatio || 0)} %</div>
          </div>
          <div className="flex items-center justify-between">
            <div>Total Liquidity Amount</div>
            <div>{toPriceString(totalLiquidity.data || 0)} USDC</div>
          </div>
          <div className="flex items-center justify-between">
            <div>Total Value</div>
            <div>${toPriceString(poolStatus.data?.totalValue || 0)}</div>
          </div>
          <div className="flex items-center justify-between">
            <div>Locked Amount for ETH-PERP</div>
            <div>
              {toPriceString(
                poolStatus.data?.status[Product.FUTURE].amountLockedLiquidity ||
                  0
              )}{' '}
              USDC
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Locked Amount for ETH2-PERP</div>
            <div>
              {toPriceString(
                poolStatus.data?.status[Product.SQUARED]
                  .amountLockedLiquidity || 0
              )}{' '}
              USDC
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div>PnL for ETH-PERP</div>
            <div>
              {toPriceString(
                poolStatus.data?.status[Product.FUTURE].unrealizedPnL || 0
              )}{' '}
              USDC
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>PnL for ETH2-PERP</div>
            <div>
              {toPriceString(
                poolStatus.data?.status[Product.SQUARED].unrealizedPnL || 0
              )}{' '}
              USDC
            </div>
          </div>
        </div>

        <HorizontalRule />
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div>ETH</div>
            <div>
              {toAmountString(nettingInfo.data?.amountUnderlying || 0)} ETH
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Reserved USDC for ETH-PERP</div>
            <div>
              {toPriceString(nettingInfo.data?.amountsUsdc[0] || 0)} USDC
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Reserved USDC for ETH2-PERP</div>
            <div>
              {toPriceString(nettingInfo.data?.amountsUsdc[1] || 0)} USDC
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>Hedge Position Value</div>
            <div>
              {toPriceString(nettingInfo.data?.totalHedgePositionValue || 0)}{' '}
              USDC
            </div>
          </div>
        </div>
        <HorizontalRule />
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div>Is Selling Underlying</div>
            <div>{hedgeStatus.data?.isLong ? 'No' : 'Yes'}</div>
          </div>
          <div className="flex items-center justify-between">
            <div>Underlying</div>
            <div>{toAmountString(hedgeStatus.data?.underlyingAmount || 0)}</div>
          </div>
          <div className="flex items-center justify-between">
            <div>USDC</div>
            <div>{toAmountString(hedgeStatus.data?.usdcAmount || 0)}</div>
          </div>
          <div className="flex items-center justify-between">
            <div>Vault1</div>
            <div>
              Amt: {toAmountString(hedgeStatus.data?.vault1.positions || 0)}
            </div>
            <div>
              Val: {toAmountString(hedgeStatus.data?.vault1.positionValue || 0)}
            </div>
            <div>
              Min: {toAmountString(hedgeStatus.data?.vault1.minCollateral || 0)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PoolStatus
