import { BigNumber } from "ethers"
import { Product } from "./constants/enum"
import { PerpetualMarket } from "./typechain"
import { Multicall } from "./typechain/multicall"
import { toUnscaled } from "./utils/bn"

const ONE16E = '10000000000000000'

/**
 * Fetch trade price from smart contract
 * @param contract 
 * @param productId 
 * @param tradeAmounts 
 * @returns 
 */
export async function getTradePrice(
  contract: PerpetualMarket,
  productId: number,
  tradeAmounts: BigNumber[]
) {
  try {
    const result = await contract.getTradePrice(productId, tradeAmounts as [BigNumber, BigNumber])

    let tradePrice = result.tradePrice

    // workaround for trade price with 0 amount
    if(tradeAmounts[productId].eq(0)) {
      tradePrice = result.indexPrice.mul(result.fundingRate.add(ONE16E)).div(ONE16E)
    }

    return {
      productId,
      tradePrice: toUnscaled(tradePrice, 8),
      fundingRate: toUnscaled(result.fundingRate, 16),
      totalFee: toUnscaled(result.totalFee, 8),
      isAvailable: true
    }  
  }catch(e) {
    return {
      productId,
      tradePrice: 0,
      fundingRate: 0,
      totalFee: 0,
      isAvailable: false
    }    
  }
}

/**
 * Fetch trade prices with multicall
 * @param multicall 
 * @param contract 
 * @param tradeAmounts 
 * @returns 
 */
export async function getTradePrices(
  multicall: Multicall,
  contract: PerpetualMarket,
  tradeAmounts: BigNumber[]
) {

  const calls = [
    {
      target: contract.address,
      callData: contract.interface.encodeFunctionData('getTradePrice', [
        Product.FUTURE,
        tradeAmounts as [BigNumber, BigNumber]
      ])
    },
    {
      target: contract.address,
      callData: contract.interface.encodeFunctionData('getTradePrice', [
        Product.SQUARED,
        tradeAmounts as [BigNumber, BigNumber]
      ])
    },
  ]

  const result = await multicall.callStatic.tryAggregate(false, calls)

  const tradePrices = result.map((r, productId) => {
    if(r.success) {
      const tradePriceResult = contract.interface.decodeFunctionResult(
        'getTradePrice',
        r.returnData
      )[0]

      let tradePrice = tradePriceResult[0]

      // workaround for trade price with 0 amount
      if(tradeAmounts[productId].eq(0)) {
        tradePrice = tradePriceResult[1].mul(tradePriceResult[2].add(ONE16E)).div(ONE16E)
      }

      return {
        productId,
        tradePrice: toUnscaled(tradePrice, 8),
        fundingRate: toUnscaled(tradePriceResult[2], 16),
        totalFee: toUnscaled(tradePriceResult[3], 8),
        isAvailable: true
      }  
    } else {
      return {
        productId,
        tradePrice: 0,
        fundingRate: 0,
        totalFee: 0,
        isAvailable: false
      }       
    }
  
  })
  
  return tradePrices
}
