import { ethers, BigNumber, ContractTransaction } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useMutation } from 'react-query'
import { ERC20__factory } from '../../typechain'

type ApproveParams = {
  amount: BigNumber
  address: string
  spender: string
}

export function useApproveMutation() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()

  return useMutation<ContractTransaction, unknown, ApproveParams>(
    async ({ address, spender, amount }) => {
      if (!library) throw new Error('not connected')

      const contract = ERC20__factory.connect(address, library.getSigner())
      return await contract.approve(spender, amount)
    }
  )
}
