import { gql } from '@apollo/client'

export const ACTIVE_POSITIONS_QUERY = gql`
  query activePositions($poolAddress: String!, $owner: String!) {
    positions(where: { pool: $poolAddress, owner: $owner, liquidity_gt: 0 }) {
      id
      owner
      liquidity
      token0 {
        id
        symbol
      }
      token1 {
        id
        symbol
      }
      pool {
        id
      }
      tickLower {
        id
        price0
        price1
        tickIdx
      }
      tickUpper {
        id
        price0
        price1
        tickIdx
      }
      collectedFeesToken0
      collectedFeesToken1
      depositedToken0
      depositedToken1
      withdrawnToken0
      withdrawnToken1
    }
  }
`

export const POSITION_QUERY = gql`
  query position($id: String!) {
    position(id: $id) {
      id
      owner
      liquidity
      token0 {
        id
        symbol
      }
      token1 {
        id
        symbol
      }
      pool {
        id
      }
      tickLower {
        id
        price0
        price1
        tickIdx
      }
      tickUpper {
        id
        price0
        price1
        tickIdx
      }
      collectedFeesToken0
      collectedFeesToken1
      depositedToken0
      depositedToken1
      withdrawnToken0
      withdrawnToken1
    }
  }
`

export type PositionSchema = {
  id: string
  owner: string
  liquidity: string
  pool: {
    id: string
  }
  tickLower: {
    id: string
    price0: string
    price1: string
    tickIdx: string
  }
  tickUpper: {
    id: string
    price0: string
    price1: string
    tickIdx: string
  }
  collectedFeesToken0: string
  collectedFeesToken1: string
  depositedToken0: string
  depositedToken1: string
  withdrawnToken0: string
  withdrawnToken1: string
}

export type PositionEntity = {
  data: {
    position: PositionSchema
  }
}

export type ActivePositionsEntities = {
  data: {
    positions: PositionSchema[]
  }
}
