import create from 'zustand'

interface State {
  vaultId: number
  subVaultIndex: number
  setVaultId: (vaultId: number) => void
  setSubVaultIndex: (subVaultIndex: number) => void
}

const useStore = create<State>(set => ({
  vaultId: 0,
  subVaultIndex: 0,
  setVaultId: (vaultId: number) => set({ vaultId, subVaultIndex: -1 }),
  setSubVaultIndex: (subVaultIndex: number) => set({ subVaultIndex })
}))

export default useStore
