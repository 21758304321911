import React from 'react'
import { Action } from '../../constants/enum'
import { useVaultHistory } from '../../hooks/query/useVaultHistory'
import { getAmount, getSymbol } from '../../utils'
import { toPriceString } from '../../utils/number'

type Props = {
  asset: number
  vaultId: number
}

const VaultHistory = ({ asset, vaultId }: Props) => {
  const vaultHistory = useVaultHistory(asset, vaultId)

  return (
    <table className="border-collapse table-fixed text-left">
      <thead className="text-[10px] text-subtext font-medium">
        <tr>
          <th className="w-40 pb-3">Entry Date</th>
          <th className="w-40">Product</th>
          <th className="w-40">Amount</th>
          <th className="w-40">Entry Price</th>
          <th className="w-40">PnL</th>
        </tr>
      </thead>
      <tbody className="text-xs font-normal">
        {(vaultHistory || []).map((vaultHistoryItem, j) => {
          return (
            <tr key={j} className="border-b border-third">
              <td className="py-2">{vaultHistoryItem.time}</td>
              <td>
                {vaultHistoryItem.action === Action.TRADE
                  ? getSymbol(vaultHistoryItem.product)
                  : 'USDC'}
              </td>
              <td>
                {vaultHistoryItem.action === Action.TRADE
                  ? getAmount(vaultHistoryItem.tradeAmount)
                  : vaultHistoryItem.action === Action.DEPOSIT
                  ? `Deposit ${toPriceString(vaultHistoryItem.marginAmount)}`
                  : vaultHistoryItem.action === Action.WITHDRAW
                  ? `Withdraw ${toPriceString(vaultHistoryItem.marginAmount)}`
                  : `Liquidate ${toPriceString(vaultHistoryItem.marginAmount)}`}
              </td>
              <td>
                {vaultHistoryItem.action === Action.TRADE
                  ? `$${toPriceString(vaultHistoryItem.tradePrice)}`
                  : '-'}
              </td>
              <td>
                {vaultHistoryItem.action === Action.TRADE &&
                vaultHistoryItem.marginAmount !== 0
                  ? `$${toPriceString(vaultHistoryItem.marginAmount)}`
                  : '-'}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default VaultHistory
