import { TRADE_FEE_1, TRADE_FEE_2, VAULT_RISK_PARAM } from '../constants'
import {
  calculateMinCollateral,
  getVaultPositions
} from './helpers/vaultHelpers'

export type SubVault = {
  positionPerpetuals: number[]
  entryPrices: number[]
  entryFundingFee: number[]
  fundingPaids: number[]
}

export type VaultPositionInfo = {
  vaultId: number
  positionUsdc: number
  subVaults: SubVault[]
}

export function getVaultLiquidationPrice(
  vaultStatus: VaultPositionInfo,
  tradePrice0: number,
  tradePrice1: number,
  n0: number,
  n1: number,
  minMargin: number
) {
  const vaultPosition = getVaultPositions(vaultStatus)

  let assetValue = 0

  if (vaultPosition.positions[0] === 0) {
    assetValue = vaultStatus.subVaults.reduce(
      (acc: number, subVault: SubVault) =>
        acc +
        (tradePrice0 - subVault.entryPrices[0]) *
          subVault.positionPerpetuals[0],
      0
    )
  }

  if (vaultPosition.positions[1] === 0) {
    assetValue = vaultStatus.subVaults.reduce(
      (acc: number, subVault: SubVault) =>
        acc +
        (tradePrice1 - subVault.entryPrices[1]) *
          subVault.positionPerpetuals[1],
      0
    )
  }

  const totalFundingPaid = vaultStatus.subVaults.reduce(
    (acc: number, subVault: SubVault) =>
      acc + subVault.fundingPaids[0] + subVault.fundingPaids[1],
    0
  )

  return getLiquidationPrice(
    vaultPosition.positions[0],
    vaultPosition.positions[1],
    vaultPosition.entryPrices[0],
    vaultPosition.entryPrices[1],
    n0,
    n1,
    vaultStatus.positionUsdc + assetValue + totalFundingPaid,
    minMargin
  )
}

export function getLiquidationPrice(
  a0: number,
  a1: number,
  e0: number,
  e1: number,
  n0: number,
  n1: number,
  v: number,
  minMargin: number
) {
  const alpha = VAULT_RISK_PARAM
  let tradeFee0 = 0
  let tradeFee1 = 0
  if (a0 > 0) {
    tradeFee0 = -TRADE_FEE_1
  } else if (a0 < 0) {
    tradeFee0 = TRADE_FEE_1
  }
  if (a1 > 0) {
    tradeFee1 = -TRADE_FEE_2
  } else if (a1 < 0) {
    tradeFee1 = TRADE_FEE_2
  }

  const r1 = solveQuadraticFormula(
    ((2 * alpha * (1 + alpha) - (1 + tradeFee1)) * a1 * (1 + n1)) / 10000,
    a0 * (alpha - (1 + tradeFee0)) * (1 + n0),
    a0 * e0 + a1 * e1 - v
  )
  const r2 = solveQuadraticFormula(
    ((2 * alpha * (alpha - 1) - (1 + tradeFee1)) * a1 * (1 + n1)) / 10000,
    -a0 * (alpha + (1 + tradeFee0)) * (1 + n0),
    a0 * e0 + a1 * e1 - v
  )
  const r3 = solveQuadraticFormula(
    ((2 * alpha * (1 - alpha) - (1 + tradeFee1)) * a1 * (1 + n1)) / 10000,
    a0 * (alpha - (1 + tradeFee0)) * (1 + n0),
    a0 * e0 + a1 * e1 - v
  )
  const r4 = solveQuadraticFormula(
    ((2 * alpha * (-alpha - 1) - (1 + tradeFee1)) * a1 * (1 + n1)) / 10000,
    -a0 * (alpha + (1 + tradeFee0)) * (1 + n0),
    a0 * e0 + a1 * e1 - v
  )

  const results: number[] = []

  if (a1 >= 0) {
    r1.forEach(r => {
      if (2 * r * a1 >= -a0 * 10000) {
        results.push(r)
      }
    })
    r2.forEach(r => {
      if (2 * r * a1 < -a0 * 10000) {
        results.push(r)
      }
    })
  } else {
    r3.forEach(r => {
      if (2 * r * a1 >= -a0 * 10000) {
        results.push(r)
      }
    })
    r4.forEach(r => {
      if (2 * r * a1 < -a0 * 10000) {
        results.push(r)
      }
    })
  }
  const minCollateralResults = solveQuadraticFormula(
    ((1 + n1) * (1 + tradeFee1) * a1) / 10000,
    (1 + n0) * (1 + tradeFee0) * a0,
    v - (minMargin + a0 * e0 + a1 * e1)
  )
    .filter(r => r >= 0)
    .filter(r => {
      const minCollateral = calculateMinCollateral(
        [a0, a1],
        n0,
        n1,
        r,
        minMargin
      )
      return minCollateral <= minMargin
    })

  return results
    .filter(r => r >= 0)
    .filter(r => {
      const minCollateral = calculateMinCollateral(
        [a0, a1],
        n0,
        n1,
        r,
        minMargin
      )
      return minCollateral > minMargin
    })
    .concat(minCollateralResults)
}

function solveQuadraticFormula(a: number, b: number, c: number) {
  if (a === 0) {
    return [-c / b]
  }

  const e = Math.sqrt(b ** 2 - 4 * a * c)
  return [(-b + e) / (2 * a), (-b - e) / (2 * a)]
}
