import React from 'react'
import { Product } from '../constants/enum'
import { useTradeHistory } from '../hooks/query/useTradeHistory'
import { toAmountString, toPriceString } from '../utils/number'

const TradeHistory = () => {
  const tradeHistory = useTradeHistory()

  return (
    <div className="rounded-lg bg-secondaly w-full h-full">
      <h4 className="text-sm p-3 pb-[17px] font-semibold">Recent Trades</h4>
      <table className="w-full text-xs text-left rounded-lg border-separate border-1 border-third table-auto">
        <thead>
          <tr className="text-third">
            <th className="pl-[8px] py-[7px]">Product</th>
            <th>Price</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {tradeHistory.map((tradeHistoryItem, j) => {
            return (
              <tr
                key={j}
                className={`last:rounded-lg ${
                  tradeHistoryItem.isLong
                    ? 'bg-transparent-green'
                    : 'bg-transparent-red'
                }`}
              >
                <td className="pl-[10px] py-[7px]">
                  {tradeHistoryItem.product === Product.FUTURE ? (
                    <p>ETH</p>
                  ) : (
                    <p>
                      ETH<sup className="text-[8px]">2</sup>
                    </p>
                  )}
                </td>
                <td
                  className={
                    tradeHistoryItem.isLong ? 'text-green' : 'text-red'
                  }
                >
                  ${toPriceString(tradeHistoryItem.tradePrice)}
                </td>
                <td>{toAmountString(tradeHistoryItem.tradeAmount)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TradeHistory
