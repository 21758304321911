import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { PerpetualMarket__factory } from '../../typechain'
import { Product } from '../../constants/enum'

type TradeParams = {
  vaultId: number
  trades: {
    productId: Product
    subVaultIndex: number
    tradeAmount: BigNumberish
    limitPrice: BigNumber
    metadata: string
  }[]
  marginAmount: BigNumberish
  deadline: number
}

export function useTrade(assetIndex: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()

  return useMutation(async (params: TradeParams) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = PerpetualMarket__factory.connect(
      addresses.Perpetuals[assetIndex].PerpetualMarket,
      library.getSigner()
    )

    return await contract.trade(params)
  })
}
