import React, { useEffect } from 'react'
import { useVaultIds } from '../../hooks/query/useVaultIds'
import vaultIcon from '../../assets/vault.svg'
import vaultStore from '../../store/vault'

const VaultSelector = () => {
  const vaultIds = useVaultIds()
  const { vaultId, setVaultId } = vaultStore()

  useEffect(() => {
    if (!vaultIds.isLoading) {
      setVaultId(vaultIds.data[0] || 0)
    }
    // eslint-disable-next-line
  }, [vaultIds.isLoading, vaultIds.data])

  return (
    <div className="flex items-center justify-center space-x-1">
      <img src={vaultIcon} alt={'vault'}></img>
      <span className="hidden md:block">pVault ID:</span>
      {vaultIds.isLoading ? (
        <p>...</p>
      ) : vaultIds.data.length > 1 ? (
        <select
          className="bg-secondaly"
          value={vaultId}
          onChange={e => {
            setVaultId(Number(e.target.value))
          }}
        >
          <option key={0} value={0}>
            New
          </option>
          {vaultIds.data.map(vaultId => (
            <option key={vaultId} value={vaultId}>
              {vaultId}
            </option>
          ))}
        </select>
      ) : vaultIds.data.length === 0 ? (
        <div className="px-1">New</div>
      ) : (
        <div className="px-1">{vaultIds.data[0]}</div>
      )}
    </div>
  )
}

export default VaultSelector
