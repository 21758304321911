import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { PerpetualMarket__factory } from '../../typechain'
import { Product } from '../../constants/enum'

type ClosePositionParams = {
  vaultId: number
  subVaultIndex: number
  marginAmount: BigNumber
  deadline: number
}

export function useClosePosition(assetIndex: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()

  return useMutation(async (params: ClosePositionParams) => {
    if (!account) throw new Error('Account not set')
    if (!library) throw new Error('library not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = PerpetualMarket__factory.connect(
      addresses.Perpetuals[assetIndex].PerpetualMarket,
      library.getSigner()
    )

    const vault = await contract.getTraderVault(params.vaultId)

    const positionPerpetuals =
      vault.subVaults[params.subVaultIndex].positionPerpetuals

    const trades = []

    if (!positionPerpetuals[0].eq(0)) {
      trades.push({
        productId: Product.FUTURE,
        subVaultIndex: params.subVaultIndex,
        tradeAmount: positionPerpetuals[0].mul(-1),
        limitPrice: 0,
        metadata: '0x'
      })
    }

    if (!positionPerpetuals[1].eq(0)) {
      trades.push({
        productId: Product.SQUARED,
        subVaultIndex: params.subVaultIndex,
        tradeAmount: positionPerpetuals[1].mul(-1),
        limitPrice: 0,
        metadata: '0x'
      })
    }

    return await contract.trade({
      vaultId: params.vaultId,
      trades,
      marginAmount: params.marginAmount,
      deadline: params.deadline
    })
  })
}
