import React, { useState } from 'react'
import { Product } from '../../constants/enum'
import {
  SubVaultStatus,
  useVaultStatus
} from '../../hooks/query/useVaultStatus'
import vaultStore from '../../store/vault'
import { getAmount, getSymbol } from '../../utils'
import { toGreeksString, toPriceString } from '../../utils/number'

type Props = {
  asset: number
  vaultId: number
  isLite: boolean
}

const SubVaultPositions = ({
  subVault,
  isLite,
  isExpanded
}: {
  subVault: SubVaultStatus
  isLite: boolean
  isExpanded: boolean
}) => {
  return (
    <table className="border-collapse table-fixed text-left">
      <thead className="text-[10px] text-subtext font-medium">
        <tr>
          <th className="w-72 pb-3">Product</th>
          <th className="w-40">Amount</th>
          {!isLite || !isExpanded ? (
            <>
              <th className="w-44">Ave. Entry Price</th>
              <th className="w-40">Tradable Price</th>
              <th className="w-40">Acc.Fund.Paym.</th>
              <th className="w-40">PNL</th>
            </>
          ) : (
            <></>
          )}
          {!isLite || isExpanded ? (
            <>
              <th className="w-40">Delta</th>
              <th className="w-40">Gamma</th>
              <th className="w-40">Vega</th>
              <th className="w-40">Theta</th>
            </>
          ) : (
            <></>
          )}
        </tr>
      </thead>
      <tbody className="text-xs font-normal items-center align-center">
        {subVault.positionPerpetuals[0] !== 0 ? (
          <tr className="border-b border-third">
            <td className="py-2">{getSymbol(Product.FUTURE)}</td>
            <td>{getAmount(subVault.positionPerpetuals[0])}</td>
            {!isLite || !isExpanded ? (
              <>
                <td>${toPriceString(subVault.entryPrices[0])}</td>
                <td>${toPriceString(subVault.tradePrices[0])}</td>
                <td>${toPriceString(subVault.fundingPaids[0])}</td>
                <td>
                  $
                  {toPriceString(
                    subVault.positionValues[0] + subVault.fundingPaids[0]
                  )}
                </td>
              </>
            ) : (
              <></>
            )}
            {!isLite || isExpanded ? (
              <>
                <td>{toGreeksString(subVault.deltas[0])}</td>
                <td>{toGreeksString(subVault.gammas[0])}</td>
                <td>{toGreeksString(subVault.vegas[0])}</td>
                <td>{toGreeksString(subVault.thetas[0])}</td>
              </>
            ) : (
              <></>
            )}
          </tr>
        ) : (
          <></>
        )}
        {subVault.positionPerpetuals[1] !== 0 ? (
          <tr>
            <td className="py-2">{getSymbol(Product.SQUARED)}</td>
            <td>{getAmount(subVault.positionPerpetuals[1])}</td>

            {!isLite || !isExpanded ? (
              <>
                <td>${toPriceString(subVault.entryPrices[1])}</td>
                <td>${toPriceString(subVault.tradePrices[1])}</td>
                <td>${toPriceString(subVault.fundingPaids[1])}</td>
                <td>
                  $
                  {toPriceString(
                    subVault.positionValues[1] + subVault.fundingPaids[1]
                  )}
                </td>
              </>
            ) : (
              <></>
            )}
            {!isLite || isExpanded ? (
              <>
                <td>{toGreeksString(subVault.deltas[1])}</td>
                <td>{toGreeksString(subVault.gammas[1])}</td>
                <td>{toGreeksString(subVault.vegas[1])}</td>
                <td>{toGreeksString(subVault.thetas[1])}</td>
              </>
            ) : (
              <></>
            )}
          </tr>
        ) : (
          <></>
        )}
      </tbody>
    </table>
  )
}

const VaultPositions = ({ asset, vaultId, isLite }: Props) => {
  const [isExpanded, setExpanded] = useState(false)
  const vaultStatus = useVaultStatus(asset, vaultId)
  const { subVaultIndex } = vaultStore()

  if (!vaultStatus.data) {
    return <div />
  }

  const displaySubVaults = vaultStatus.data.subVaults.map(subVault => ({
    ...subVault
  }))

  if (!isLite) {
    // if pro mode
    displaySubVaults.sort((a, b) => b.cardType - a.cardType)
  }

  return (
    <div className="flex">
      <div>
        {!isLite || subVaultIndex === -1 ? (
          <SubVaultPositions
            subVault={vaultStatus.data.netStatus}
            isLite={isLite}
            isExpanded={isExpanded}
          />
        ) : (
          displaySubVaults
            .filter((_, i) => subVaultIndex === i)
            .map((subVault, j) => {
              return (
                <SubVaultPositions
                  key={j}
                  subVault={subVault}
                  isLite={isLite}
                  isExpanded={isExpanded}
                />
              )
            })
        )}
      </div>
      {isLite ? (
        <div
          className="cursor-pointer"
          onClick={() => {
            setExpanded(!isExpanded)
          }}
        >
          {isExpanded ? <p>&lt;</p> : <p>&gt;</p>}
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}
export default VaultPositions
