import React from 'react'
import { useRoute } from 'wouter'
import TradeResult from '../components/trade/TradeResult'
import VaultInfo from '../components/vault/VaultInfo'
import { getAssetIndex } from '../utils/asset'
import modalStore from '../store/modal'
import vaultStore from '../store/vault'
import TradeHistory from '../components/TradeHistory'
import PriceChart from '../components/chart/PriceChart'
import SpotPnLChart from '../components/chart/SpotPnLChart'
import { useLatestVaultId } from '../hooks/query/useVaultIds'

const ProView = () => {
  const params = useRoute('/home/:asset')[1]
  const { vaultId } = vaultStore()
  const latestVaultId = useLatestVaultId()
  const { isOpen, setOpen } = modalStore()

  return (
    <>
      <div className="grid grid-cols-pro gap-4 grid-rows-pro max-w-7xl mx-auto content-start">
        <div className="relative col-span-2 row-span-2">
          {isOpen ? (
            <div className="absolute z-10 w-full h-full">
              <div className="h-full flex items-center justify-center">
                <div
                  className="fixed backdrop-blur-sm bg-black/70 left-0 top-0 w-full h-full"
                  onClick={() => {
                    setOpen(false)
                  }}
                ></div>
                <div className="absolute z-20 bg-secondaly top-20 w-[666px] rounded-lg pointer-events-auto">
                  <SpotPnLChart
                    asset={params?.asset || 'eth'}
                    vaultId={vaultId}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
          <div className="grid grid-cols-proleft grid-rows-pro gap-4 content-start">
            <div className="col-span-1 row-span-1">
              <PriceChart asset={params?.asset || 'eth'}></PriceChart>
            </div>
            <div className="col-span-1 row-span-1">
              <TradeHistory></TradeHistory>
            </div>
            <div className="col-span-2 row-span-1">
              <VaultInfo
                asset={getAssetIndex(params?.asset)}
                vaultId={vaultId}
              ></VaultInfo>
            </div>
          </div>
        </div>
        <div className="col-span-1 row-span-2 z-20">
          <TradeResult
            asset={getAssetIndex(params?.asset)}
            isLoading={latestVaultId.isLoading}
            vaultId={vaultId}
          ></TradeResult>
        </div>
      </div>
    </>
  )
}

export default ProView
