import { CardType } from '../../constants/enum'
import { VaultStatus } from '../../hooks/query/useVaultStatus'

/**
 * Gets index of empty subVault or new subVault
 * @param vault VaultStatus object
 */
export function getNextSubVaultId(vault: VaultStatus) {
  const emptySubVault = vault.subVaults.filter(
    subVault =>
      subVault.positionPerpetuals[0] === 0 &&
      subVault.positionPerpetuals[1] === 0
  )

  if (emptySubVault.length > 0) {
    return emptySubVault[0].subVaultIndex
  }

  return vault.subVaults.length
}

/**
 * Gets index of subVault by provided card type
 * @param vault VaultStatus object
 */
export function getSubVaultIdWithCardType(
  vault: VaultStatus,
  cartType: CardType
) {
  const subVaults = vault.subVaults.filter(
    subVault =>
      subVault.cardType === cartType &&
      (subVault.positionPerpetuals[0] !== 0 ||
        subVault.positionPerpetuals[1] !== 0)
  )

  if (subVaults.length > 0) {
    return subVaults[0].subVaultIndex
  }

  return getNextSubVaultId(vault)
}
