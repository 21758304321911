import React, { useState, useEffect } from 'react'
import { useLocation } from 'wouter'
import localforage from 'localforage'
import { Switch } from '../components/common/Switch'
import Logo from '../assets/logo.svg'
import LoadingIndicator from '../components/common/LoadingIndicator'
import mixpanel from '../mixpanel'

const AgreementView = () => {
  const setLocation = useLocation()[1]
  const [loaded, setLoaded] = useState(false)
  const [analytics, setAnalytics] = useState(false)
  const [residence, setResidence] = useState(false)

  useEffect(() => {
    ;(async () => {
      // check residence once. if value is set to true, proceed
      const value = await localforage.getItem('residence')
      if (value) {
        setLocation('/lite/trade/eth')
      }
      setLoaded(true)
    })()
    // eslint-disable-next-line
  }, [])

  const onAccept = async () => {
    // setAccept data in localstorage
    await localforage.setItem('analytics', analytics)
    await localforage.setItem('residence', residence)
    if (!analytics) {
      mixpanel.opt_out_tracking()
    }

    setLocation('/lite/trade/eth')
  }

  if (!loaded) return <LoadingIndicator />

  return (
    <div className="flex items-center justify-center">
      <div className="mt-10 w-[480px] bg-secondaly rounded-lg p-6">
        <div className="flex items-center justify-center border-solid border border-secondaly border-b-white pb-6">
          <img src={Logo} alt="Logo" width="148px" />
        </div>
        <h2 className="text-md mt-2">Welcome To Demo Release</h2>
        <p className="text-xs mt-1">
          Thank you for trying Predy. Here you can trade options and provide
          liquidity up to the published TVL limit. For this first service, we
          have chosen the upper limit to provide a better and safer measurement
          for the next series of options. The current live version of the
          contract has been audited, but we would like to make sure it is safe
          little by little.
        </p>
        <div className="my-10">
          <div className="mt-2 flex space-x-2 text-xs">
            <div>
              <Switch onChange={v => setAnalytics(v)} />
            </div>
            <div>
              (Optional) You agree to share anonymous analytics and crashalytics
            </div>
          </div>
          <div className="mt-2 flex space-x-2 text-xs">
            <div>
              <Switch onChange={v => setResidence(v)} />
            </div>
            <div>
              (Mandatory) You are not a person or company who is a resident of,
              or is located, incorporated or has a registered agent in,
              theUnited States or any prohibited locations where you use of the
              service would be illegal or otherwise violate any applicable law
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <button
            className="w-40 p-2 final-gradient rounded-md text-xs font-semibold"
            onClick={onAccept}
            disabled={!residence}
          >
            Accept & Join
          </button>
        </div>
      </div>
    </div>
  )
}

export default AgreementView
