import { gql } from '@apollo/client'

export const TRADE_HISTORY_QUERY = gql`
  query (
    $first: Int
    $orderBy: BigInt
    $orderDirection: String
    $action: String
  ) {
    tradeHistoryEntities(
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { action: $action }
    ) {
      id
      txid
      action
      trader
      productId
      tradeAmount
      tradePrice
      fundingFeePerPosition
      timestamp
    }
  }
`

export type TradeHistoryEntities = {
  tradeHistoryEntities: {
    id: string
    txid: string
    action: string
    trader: string
    productId: string
    tradeAmount: string
    tradePrice: string
    fundingFeePerPosition: string
    timestamp: string
  }[]
}
