import { useQuery } from 'react-query'

type VaultSnapshot = {
  date: string
  vaults: {
    owner: string
    positionUsdc: number
    positionValue: number
    vaultId: number
  }[]
}

export function useVaultSnapshot(year: number, month: number, date: number) {
  return useQuery(
    ['snapshot', year, month, date],
    async () => {
      const data = await fetch(
        `https://predy-vaults.s3.ca-central-1.amazonaws.com/${year}/${month}/${date}/10`,
        { method: 'GET' }
      )

      return (await data.json()) as VaultSnapshot
    },
    {
      enabled: true
    }
  )
}
