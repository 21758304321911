import { useQuery } from 'react-query'
import { AggregatorV3Interface__factory } from '../typechain'
import { useAddresses } from './useAddress'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { toUnscaled } from '../utils/bn'
import { useIsSupportedChain } from './network'

export function useCurrentEthPrice() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses()

  return useQuery<number>(
    ['eth_price'],
    async () => {
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('Address not loaded')

      const aggregator = AggregatorV3Interface__factory.connect(
        addresses.Perpetuals[0].PriceFeed,
        library
      )
      const res = await aggregator.latestRoundData()
      return toUnscaled(res.answer, 8)
    },
    {
      refetchInterval: 15000,
      enabled: !!library && supportedChain && !!addresses
    }
  )
}
