import React from 'react'
import iconUSDC from '../../../assets/usdc.svg'
import { toPriceString } from '../../../utils/number'
import { AmountForm } from '../AmountForm'

type Props = {
  marginAmount: number
  liquidationPrices: number[]
  minCollateral: number
  requiredMargin: number
  marginAmountInVault: number
  onChange: (amount: number) => void
}

export const MarginForm = ({
  marginAmount,
  liquidationPrices,
  minCollateral,
  requiredMargin,
  marginAmountInVault,
  onChange
}: Props) => {
  return (
    <div className="space-y-4">
      <div className="flex justify-start items-center space-x-2">
        <img src={iconUSDC} className="h-[50px]"></img>
        <div>
          <p>Collateral USDC</p>
          <p className="text-xs text-subtext">
            ${toPriceString(marginAmountInVault)}
          </p>
        </div>
      </div>
      <div>
        <AmountForm
          amount={marginAmount}
          onChange={onChange}
          step={100}
          name={'marginAmount'}
          domainLow={Math.ceil(requiredMargin)}
        />
      </div>
      <div className="space-y-2 text-xs">
        <div className="flex justify-between">
          <p>Min Collateral:</p>
          <p>${toPriceString(minCollateral)}</p>
        </div>
        <div className="flex justify-between">
          <p>Liquidation Price:</p>
          {liquidationPrices.length === 0 ? (
            <div>No LiquidationPrices</div>
          ) : liquidationPrices.length === 1 ? (
            <div>$ {toPriceString(liquidationPrices[0])}</div>
          ) : (
            <div>
              ${toPriceString(liquidationPrices[0])}, $
              {toPriceString(liquidationPrices[1])}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
