import { BigNumber } from 'ethers'
import {
  arrayify,
  hexlify,
  hexConcat,
  hexDataSlice,
  hexDataLength
} from 'ethers/lib/utils'
import { CardType } from '../constants/enum'

export function encodeMetadata(card: CardType, tokenId?: number) {
  return hexConcat([arrayify(hexlify(card)), arrayify(hexlify(tokenId || 0))])
}

export function decodeMetadata(metadata: string): {
  card: CardType
  tokenId?: number
} {
  const length = hexDataLength(arrayify(metadata))
  const card = BigNumber.from(hexDataSlice(arrayify(metadata), 0, 1))
  const tokenId = BigNumber.from(hexDataSlice(arrayify(metadata), 1, length))

  return {
    card: <CardType>card.toNumber(),
    tokenId: tokenId.toNumber()
  }
}
