import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from '../../network'
import { toUnscaled } from '../../../utils/bn'
import { useAddresses } from '../../useAddress'
import { PerpetualMarketCore__factory } from '../../../typechain'

export function useVariance(assetIndex: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()

  return useQuery(
    ['variance', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = PerpetualMarketCore__factory.connect(
        addresses.Perpetuals[assetIndex].LPToken,
        library
      )

      const poolSnapshot = await contract.poolSnapshot()

      return toUnscaled(BigNumber.from(poolSnapshot.ethVariance), 8)
    },
    {
      enabled: supportedChain && !!library && !!addresses
    }
  )
}
