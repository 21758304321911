import { gql } from '@apollo/client'

export const INDEX_PRICES_QUERY = gql`
  query (
    $first: Int
    $orderBy: BigInt
    $orderDirection: String
    $assetPair: String
    $startTime: BigInt
  ) {
    prices(
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { assetPair: $assetPair, timestamp_gt: $startTime }
    ) {
      id
      assetPair
      price
      timestamp
    }
  }
`

export const CANDLES_QUERY = gql`
  query (
    $first: Int
    $orderBy: BigInt
    $orderDirection: String
    $assetPair: String
    $interval: String
    $startTime: BigInt
  ) {
    candles(
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: {
        assetPair: $assetPair
        interval: $interval
        closeTimestamp_gt: $startTime
      }
    ) {
      id
      assetPair
      interval
      openTimestamp
      closeTimestamp
      averagePrice
    }
  }
`

export type PricesEntities = {
  prices: {
    id: string
    assetPair: string
    price: string
    timestamp: string
  }[]
}

export type CandleEntities = {
  candles: {
    id: string
    assetPair: string
    interval: string
    averagePrice: string
    openTimestamp: string
    closeTimestamp: string
  }[]
}
