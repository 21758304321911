import { gql } from '@apollo/client'

export const VAULTS_QUERY = gql`
  query ($account: String) {
    vaultEntities(where: { account: $account }, orderBy: vaultId) {
      id
      vaultId
      account
    }
  }
`

export type VaultEntities = {
  vaultEntities: {
    vaultId: string
    account: string
  }[]
}

export const VAULT_QUERY = gql`
  query ($id: String, $assetId: String) {
    vaultEntities(where: { id: $id, asset: $assetId }) {
      id
      vaultId
      account
      history {
        txid
        productId
        action
        subVaultIndex
        tradeAmount
        tradePrice
        fundingFeePerPosition
        marginAmount
        timestamp
      }
      subVaults {
        subVaultId
        metadata
      }
    }
  }
`

export type VaultEntity = {
  id: string
  vaultId: string
  account: string
  history: {
    txid: string
    productId: string
    action: string
    subVaultIndex: string
    tradeAmount: string
    tradePrice: string
    fundingFeePerPosition: string
    marginAmount: string
    timestamp: string
  }[]
  subVaults: {
    subVaultId: string
    metadata: string
  }[]
}
