export type Addresses = {
  Perpetuals: PerpetualAddresses[]
  Multicall2: string
  QuoteToken: string
  Hedger: string
  FeePool: string
}

export type PerpetualAddresses = {
  PriceFeed: string
  PerpetualMarket: string
  LPToken: string
  UnderlyingToken: string
  VaultNFT: string
}

export const rinkebyArbitrum = {
  Perpetuals: [
    {
      PriceFeed: '0x5f0423B1a6935dc5596e7A24d98532b67A0AeFd8',
      PerpetualMarket: '0xdF8d64A556a60f7177A3FFc41AEde15524a218F3',
      LPToken: '0x4f59992D6f16870705054DDa3A6d597C3897b525',
      UnderlyingToken: '0x7d43FeaE50F702d1586D5EF14A7b946dbA658869',
      VaultNFT: '0x3ba2482e99Da498e6EeA5f218123d48FcF3786B9'
    }
  ],
  Multicall2: '0x1C3db5F3d04d7e7CbB8F2f255452098e46E6109B',
  QuoteToken: '0xF61Cffd6071a8DB7cD5E8DF1D3A5450D9903cF1c',
  Hedger: '0x0000000000000000000000000000000000000000',
  FeePool: '0xF4C21eA5fa73Ffae3D0d896c983EF761f3970caf'
}

export const arbitrum = {
  Perpetuals: [
    {
      PriceFeed: '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612',
      PerpetualMarket: '0xAdBAeE9665C101413EbFF07e20520bdB67C71AB6',
      LPToken: '0x8B98991EEd7C25280BE5532C3b23a095654868c0',
      UnderlyingToken: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
      VaultNFT: '0x175FB4B866070Ab10F20057050Eb84491ff7Fd0c'
    }
  ],
  Multicall2: '0x842ec2c7d803033edf55e478f461fc547bc54eb2',
  QuoteToken: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  Hedger: '0xc622fd7adfe9aafa97d9bc6f269c186f07b59f0f',
  FeePool: '0xE17B52958aa4a3cEa4202B9893A0C7ae5bDaCEa5'
}

export const NETWORKS: { [key: number]: Addresses } = {
  [421611]: rinkebyArbitrum,
  [42161]: arbitrum
}

export const UNI_ETH_USDC_POOL_ADDRESS = [
  '0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8',
  '0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640'
]
