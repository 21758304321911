import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { ERC20__factory } from '../../typechain'
import { useChainId, useIsSupportedChain } from '../network'
import { useBlockNumber } from '../blockNumber'
import { useEffect, useState } from 'react'
import { useAddresses } from '../useAddress'

export function useAllowanceQuery(spender: string, address: string) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const blockNumber = useBlockNumber()

  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0))

  const alloqanceQuery = useQuery(
    ['allowance', account, spender, address, chainId, blockNumber],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')

      const contract = ERC20__factory.connect(address, library)
      return await contract.allowance(account, spender)
    },
    {
      enabled: !!account && supportedChain && !!addresses && !!spender
    }
  )

  useEffect(() => {
    if (alloqanceQuery.isSuccess) {
      setAllowance(alloqanceQuery.data)
    }
  }, [alloqanceQuery.isSuccess, alloqanceQuery.data])

  return allowance
}
