/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import { Provider } from '@ethersproject/providers'
import type {
  BaseLiquidityPool,
  BaseLiquidityPoolInterface
} from '../BaseLiquidityPool'

const _abi = [
  {
    inputs: [],
    name: 'quoteAsset',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'underlyingAsset',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
]

export class BaseLiquidityPool__factory {
  static readonly abi = _abi
  static createInterface(): BaseLiquidityPoolInterface {
    return new utils.Interface(_abi) as BaseLiquidityPoolInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): BaseLiquidityPool {
    return new Contract(address, _abi, signerOrProvider) as BaseLiquidityPool
  }
}
