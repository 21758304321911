import { BigNumber } from 'ethers'
import { useChainId, useIsSupportedChain } from '../../network'
import { toUnscaled } from '../../../utils/bn'
import { Action } from '../../../constants/enum'
import { PredyClient } from '../../../utils/apollo-client'
import { DEFAULT_CHAIN } from '../../../constants'
import { useQuery } from '@apollo/client'
import {
  MarginHistoryEntities,
  MARGIN_HISTORY_QUERY
} from '../../../queries/marginHistoryQuery'
import { useVaultSnapshot } from './snapshot'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export function useRanking(competition: number) {
  const start = dayjs.utc('2022/4/15 10:00:00').add(competition, 'week')
  const end = start.add(1, 'week')

  const depositMarginHistory = useMarginHistory(
    'DEPOSIT_MARGIN',
    start.unix(),
    end.unix()
  )
  const withdrawMarginHistory = useMarginHistory(
    'WITHDRAW_MARGIN',
    start.unix(),
    end.unix()
  )
  const startSnapshot = useVaultSnapshot(2022, start.month() + 1, start.date())
  const endSnapshot = useVaultSnapshot(2022, end.month() + 1, end.date())

  const pnlMap = new Map<string, number>()
  const depositMap = new Map<string, number>()
  const withdrawMap = new Map<string, number>()

  depositMarginHistory.forEach(i => {
    const trader = i.trader.toLowerCase()
    const newMarginAmount = depositMap.get(trader)
    depositMap.set(
      trader,
      newMarginAmount ? newMarginAmount + i.marginAmount : i.marginAmount
    )
  })

  withdrawMarginHistory.forEach(i => {
    const trader = i.trader.toLowerCase()
    const newMarginAmount = withdrawMap.get(trader)
    withdrawMap.set(
      trader,
      newMarginAmount ? newMarginAmount + i.marginAmount : i.marginAmount
    )
  })

  if (startSnapshot.isSuccess && endSnapshot.isSuccess) {
    for (const key in endSnapshot.data.vaults) {
      const owner = endSnapshot.data.vaults[key].owner.toLowerCase()

      const startValue =
        (startSnapshot.data.vaults[key]
          ? toUnscaled(
              BigNumber.from(startSnapshot.data.vaults[key].positionValue),
              8
            )
          : 0) + (depositMap.get(owner) || 0)
      const endValue =
        (endSnapshot.data.vaults[key]
          ? toUnscaled(
              BigNumber.from(endSnapshot.data.vaults[key].positionValue),
              8
            )
          : 0) + (withdrawMap.get(owner) || 0)

      pnlMap.set(owner, endValue - startValue)
    }
  }

  const ranking = Array.from(pnlMap)

  return {
    start,
    end,
    data: ranking.sort((a, b) => b[1] - a[1])
  }
}

export function useMarginHistory(action: string, start: number, end: number) {
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()

  const client = supportedChain
    ? PredyClient[chainId]
    : PredyClient[DEFAULT_CHAIN]

  const { data } = useQuery<MarginHistoryEntities>(MARGIN_HISTORY_QUERY, {
    fetchPolicy: 'cache-and-network',
    client,
    variables: {
      startTime: start,
      endTime: end,
      action
    },
    pollInterval: 10000
  })

  if (!data) {
    return []
  }

  return data.tradeHistoryEntities.map(historyItem => {
    const marginAmount = BigNumber.from(historyItem.marginAmount)

    return {
      action: <Action>historyItem.action,
      vaultId: historyItem.vault.vaultId,
      trader: historyItem.trader,
      marginAmount: toUnscaled(marginAmount.abs(), 6),
      timestamp: Number(historyItem.timestamp)
    }
  })
}
