import { DocumentNode, useQuery } from '@apollo/client'
import {
  LPPRICE,
  LPPRICESTARTER,
  LpPriceHistoryEntity
} from '../../queries/lpPriceQuery'
import { ChartRange } from '../../constants/enum'
import getStartTimestamp from '../../utils/chartTimestamp'
import { useEffect, useState } from 'react'

function LpPrice(range: ChartRange) {
  let startTimestamp: number = getStartTimestamp(range)
  const [timestampFilter, setTimestampFilter] = useState(2000000000)
  const [historicalData, setHistoricalData] = useState<LpPriceHistoryEntity[]>(
    []
  )
  const [noData, setNoData] = useState(false)
  let queryName: DocumentNode = LPPRICE

  if (noData) {
    queryName = LPPRICESTARTER
    startTimestamp = 1
  } else {
    queryName = LPPRICE
    startTimestamp = getStartTimestamp(range)
  }

  const { loading, error, data } = useQuery<{
    lpTokenPriceEntities: LpPriceHistoryEntity[]
  }>(queryName, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ts: startTimestamp,
      tsFilter: timestampFilter
    }
  })

  useEffect(() => {
    setHistoricalData(<LpPriceHistoryEntity[]>[])
    setTimestampFilter(2000000000)
  }, [range])

  useEffect(() => {
    if (!loading) {
      if (data && data.lpTokenPriceEntities.length > 0) {
        const fixedData = data.lpTokenPriceEntities.map(e => {
          return { ...e, price: e.price / 1e16 }
        })
        setHistoricalData(
          historicalData
            .concat(fixedData)
            .filter(
              (val, ind, self) =>
                ind === self.findIndex(item => item.id === val.id)
            )
            .sort((a, b) => a.timestamp - b.timestamp)
        )
        const returnedData = [...data.lpTokenPriceEntities]
        const tsFilter = returnedData.sort(
          (a, b) => a.timestamp - b.timestamp
        )[0].timestamp
        setTimestampFilter(tsFilter)
        setNoData(false)
      } else if (historicalData.length == 0) {
        setNoData(true)
        console.log('Data length zero')
      }
    } else {
      console.log('Loading data')
    }
  }, [loading])

  return historicalData
}

export default LpPrice
