import { useQuery } from 'react-query'
import { BigNumber } from 'ethers'
import { useChainId } from '../../network'
import {
  CandleEntities,
  CANDLES_QUERY,
  INDEX_PRICES_QUERY,
  PricesEntities
} from '../../../queries/indexPricesQuery'
import { toUnscaled } from '../../../utils/bn'
import { getAssetPair } from '../../../utils/assetPair'
import dayjs from 'dayjs'
import { ChainlinkClient } from '../../../utils/apollo-client'
import { toTimeString } from '../../../utils/string'
import { getHighAndLow } from '../../../utils/helpers/chartHelper'
import { ChartRange, Product } from '../../../constants/enum'
import { useEffect, useState } from 'react'

type Price = {
  id: string
  price: number
  timestamp: number
  time: string
}

export type IndexPriceChartInfo = {
  prices: Price[]
  change: number
  low: number
  high: number
  domainLow: number
  domainHigh: number
  range: ChartRange
}

async function getPrices(
  chainId: number,
  assetPair: string,
  range: ChartRange,
  priceType: Product,
  limit: number
) {
  const client = ChainlinkClient[chainId]

  const startTime = dayjs()
    .subtract(
      1,
      range === ChartRange.Day
        ? 'day'
        : range === ChartRange.Week
        ? 'week'
        : range === ChartRange.Month
        ? 'month'
        : 'year'
    )
    .unix()

  if (range === ChartRange.Hour) {
    const response: { data: PricesEntities } = await client.query({
      query: INDEX_PRICES_QUERY,
      variables: {
        first: limit,
        orderBy: 'timestamp',
        orderDirection: 'desc',
        assetPair,
        startTime
      }
    })

    return response.data.prices
      .map(priceInfo => {
        const price = toUnscaled(BigNumber.from(priceInfo.price), 8)
        return {
          id: priceInfo.id,
          price: applyPower(price, priceType),
          timestamp: Number(priceInfo.timestamp),
          time: toTimeString(Number(priceInfo.timestamp))
        }
      })
      .filter(price => {
        return price.timestamp >= dayjs().subtract(1, 'hour').unix()
      })
  } else {
    const response: { data: CandleEntities } = await client.query({
      query: CANDLES_QUERY,
      variables: {
        first: limit,
        orderBy: 'openTimestamp',
        orderDirection: 'desc',
        assetPair,
        interval:
          range === ChartRange.Day
            ? 'HOURLY'
            : range === ChartRange.Week
            ? 'HOURLY'
            : 'DAILY',
        startTime
      }
    })

    return response.data.candles
      .map(priceInfo => {
        const price = toUnscaled(BigNumber.from(priceInfo.averagePrice), 8)
        return {
          id: priceInfo.id,
          price: applyPower(price, priceType),
          timestamp: Number(priceInfo.openTimestamp),
          time: toTimeString(Number(priceInfo.openTimestamp))
        }
      })
      .filter(price => {
        return price.timestamp >= startTime
      })
  }
}

function applyPower(price: number, priceType: Product): number {
  if (priceType === Product.FUTURE) {
    return price
  } else {
    return (price * price) / 10000
  }
}

function getOffset(range: ChartRange) {
  switch (range) {
    case ChartRange.Hour:
      return 50
    case ChartRange.Day:
      return 50
    default:
      return 100
  }
}

/**
 * Gets index prices for price chart
 * @param asset asset string such as 'eth'
 * @param priceType price type
 * @param range ChartRange such as hour or day etc
 * @param limit max number of data to fetch
 * @returns
 */
export function useIndexPrices(
  asset: string,
  priceType: Product,
  range: ChartRange,
  limit: number
) {
  const chainId = useChainId()
  const [chartData, setChartData] = useState<IndexPriceChartInfo | null>(null)

  const indexPrices = useQuery(
    ['index_prices', chainId, asset, priceType, range],
    async () => {
      const prices: Price[] = await getPrices(
        chainId,
        getAssetPair(asset),
        range,
        priceType,
        limit
      )

      const highAndLow = getHighAndLow(
        prices.map(price => price.price),
        getOffset(range)
      )

      return {
        range,
        prices: prices.sort((a, b) => a.timestamp - b.timestamp),
        ...highAndLow
      }
    },
    {
      enabled: true
    }
  )

  useEffect(() => {
    if (indexPrices.isSuccess) {
      setChartData(indexPrices.data)
    }
  }, [indexPrices.isSuccess, indexPrices.data])

  return {
    isLoading: chartData === null,
    data: chartData
  } as
    | { isLoading: true; data: null }
    | { isLoading: false; data: IndexPriceChartInfo }
}
