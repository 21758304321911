import { gql } from '@apollo/client'

export const POOL_QUERY = gql`
  query pool($poolAddress: String!) {
    pool(id: $poolAddress) {
      id
      sqrtPrice
      token0Price
      token1Price
    }
  }
`

export type POOL_ENTITY = {
  data: {
    pool: {
      id: string
      sqrtPrice: string
      token0Price: string
      token1Price: string
    }
  }
}
