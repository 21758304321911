import React from 'react'
import { useRoute } from 'wouter'
import { getAssetIndex } from '../utils/asset'
import PoolStatus from '../components/pool/PoolStatus'
import Liquidity from '../components/pool/Liquidity'

const PoolView = () => {
  const params = useRoute('/pool/:asset')[1]

  return (
    <>
      <div className="grid grid-cols-2 gap-4 max-w-5xl mx-auto">
        <div className="col-span-1">
          <PoolStatus asset={getAssetIndex(params?.asset)} />
        </div>
        <div className="col-span-1">
          <Liquidity asset={getAssetIndex(params?.asset)} />
        </div>
      </div>
    </>
  )
}

export default PoolView
