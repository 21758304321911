import { BigNumber } from 'ethers'
import { useChainId, useIsSupportedChain } from '../network'
import { toUnscaled } from '../../utils/bn'
import {
  TradeHistoryEntities,
  TRADE_HISTORY_QUERY
} from '../../queries/tradeHistoryQuery'
import { Action, Product } from '../../constants/enum'
import { PredyClient } from '../../utils/apollo-client'
import { productToString } from '../../utils'
import { DEFAULT_CHAIN } from '../../constants'
import { useQuery } from '@apollo/client'

export type Trade = {
  action: Action
  productId: number
  product: Product
  productName: string
  isLong: boolean
  tradeAmount: number
  tradePrice: number
  timestamp: number
}

export function useTradeHistory() {
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()

  const client = supportedChain
    ? PredyClient[chainId]
    : PredyClient[DEFAULT_CHAIN]

  const { data } = useQuery<TradeHistoryEntities>(TRADE_HISTORY_QUERY, {
    fetchPolicy: 'cache-and-network',
    client,
    variables: {
      first: 13,
      orderBy: 'timestamp',
      orderDirection: 'desc',
      action: 'TRADE'
    },
    pollInterval: 10000
  })

  if (!data) {
    return []
  }

  const trades: Trade[] = data.tradeHistoryEntities.map(historyItem => {
    const tradeAmount = BigNumber.from(historyItem.tradeAmount)
    const productId = Number(historyItem.productId)
    const product = <Product>productId

    return {
      action: <Action>historyItem.action,
      productId,
      product,
      productName: productToString(product),
      isLong: tradeAmount.gte(0),
      tradeAmount: toUnscaled(tradeAmount.abs(), 8),
      tradePrice: toUnscaled(BigNumber.from(historyItem.tradePrice), 8),
      timestamp: Number(historyItem.timestamp)
    }
  })

  return trades
}
