import create from 'zustand'

interface State {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
}

const useStore = create<State>(set => ({
  isOpen: false,
  setOpen: (isOpen: boolean) => set({ isOpen })
}))

export default useStore
